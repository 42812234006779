import {
  FETCH_APP_LIST,
  FETCH_APP_LIST_ERROR,
  SET_APP_LIST,
  FETCH_ORGANISATION_LIST,
  FETCH_ORGANISATION_LIST_ERROR,
  SET_ORGANISATION_LIST,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_ERROR,
  SET_CATEGORIES,
  FETCH_SUBCATEGORY_ORGANISATION,
  FETCH_SUBCATEGORY_ORGANISATION_ERROR,
  SET_SUBCATEGORY_ORGANISATION,
  ADD_ORGANISATION_IN_APP_SUCCESS,
  DELETE_ORGANISATION_FROM_APP_SUCCESS
} from "../constants";

const initialState = {
  appListDataLoaded: false,
  appListData: [],
  organisationListDataLoaded: true,
  digitalOrganisationArray:[],
  physicalOrganisationArray:[],
  categoriesDataLoaded: false,
  categoriesData: [],
  subcategoryOrganisationDataLoaded: false,
  subcategoryOrganisationData: []
};

const footfallConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_LIST:
      return {
        ...state,
        appListDataLoaded: false
      };
    case FETCH_APP_LIST_ERROR:
      return {
        ...state,
        appListDataLoaded: false
      };
    case SET_APP_LIST:
      let preWorkAppListData = [];
      action.data.map(item => {
        preWorkAppListData.push({
          appId: item._id,
          label: item.name,
          name: item.name
        });
      });
      return {
        ...state,
        appListDataLoaded: true,
        appListData: preWorkAppListData
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categoriesDataLoaded: false
      };
    case FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        categoriesDataLoaded: false
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categoriesDataLoaded: true,
        categoriesData: action.data.map((category, categoryIndex) => ({
          label: category.name,
          labelWithCount: `${category.name} (${category.subcategories.length})`,
          value: categoryIndex,
          options: category.subcategories.map(subcategory => ({
            label: subcategory.name,
            value: subcategory.name,
            parentCategory: category.name
          }))
        }))
      };

    case FETCH_SUBCATEGORY_ORGANISATION:
      return {
        ...state,
        subcategoryOrganisationDataLoaded: false
      };

    case FETCH_SUBCATEGORY_ORGANISATION_ERROR:
      return {
        ...state,
        subcategoryOrganisationDataLoaded: false
      };

    case SET_SUBCATEGORY_ORGANISATION:
      return {
        ...state,
        subcategoryOrganisationDataLoaded: true,
        subcategoryOrganisationData: action.data
      };

    case FETCH_ORGANISATION_LIST:
      return {
        ...state,
        organisationListDataLoaded: false
      };
    case FETCH_ORGANISATION_LIST_ERROR:
      return {
        ...state,
        organisationListDataLoaded: false
      };
    case SET_ORGANISATION_LIST:
      return {
        ...state,
        organisationListDataLoaded: true,
        physicalOrganisationArray: action.data.physicalOrganisationArray,
        digitalOrganisationArray: action.data.digitalOrganisationArray,
      };
    case ADD_ORGANISATION_IN_APP_SUCCESS:
      return {
        ...state
      };
    case DELETE_ORGANISATION_FROM_APP_SUCCESS:
      return {
        ...state
      };

    default:
      return state;
  }
};

export default footfallConfiguration;
