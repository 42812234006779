import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, switchMap, concatMap, catchError } from "rxjs/operators";

import {
  FETCH_CLIENT_LIST,
  FETCH_CLIENT_LIST_ERROR,
  SET_CLIENT_LIST,
  FETCH_USER_LIST_AFTER_CLIENT_API_CALL,
  RETURN_JUST_CLIENT_LIST
} from "../../constants";

const clientList = (action$, state$) => {

  return action$.pipe(
    ofType(FETCH_CLIENT_LIST),
    concatMap(action =>{

      return  ajax({
        url: `${state$.value.auth.poiServer}/client_list`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`
        },
        responseType: "json",
        crossDomain: true
      }).pipe(
        map(response => {
          console.log("client list response", response);
          if (action.pageType === "user") {
            return {
              type: SET_CLIENT_LIST,
              data: response.response,
              pageType: action.pageType
            };
          } else {
            return {
              type: SET_CLIENT_LIST,
              data: response.response,
              pageType: action.pageType
            };
          }
        }),
        catchError(error =>
          of({
            type: FETCH_CLIENT_LIST_ERROR,
            error
          })
        )
      )
    }

    ),
  //   concatMap(result => {
  //     console.log(result);
  //     if (result.pageType === "user") {
  //       return ajax({
  //         url: `${state$.value.auth.poiServer}/user_accounts/${result.data[0].clientId}`,
  //         headers: {
  //           Authorization: `Bearer ${state$.value.auth.token}`
  //         },
  //         responseType: "json",
  //         crossDomain: true,
  //         method: "POST"
  //       }).pipe(
  //         map(response => {
  //           console.log("call sdklist for first client", response);
  //           return {
  //             type: SET_CLIENT_LIST,
  //             data: response.response
  //           };
  //         }),
  //         catchError(error =>
  //           of({
  //             type: FETCH_CLIENT_LIST_ERROR,
  //             error
  //           })
  //         )
  //       );
  //     } else {
  //       console.log(1234)

  //       of({
  //         type: SET_CLIENT_LIST,
  //         data: result.data,
  //         pageType: result.pageType
  //       })
  //      return map(response => {
  //         console.log(response)
  //         return {

  //         };
  //       });
  //     }
  //   })
   );
};

export default clientList;
