export const setCredentials = credentials => {
  if(credentials.token) {
    localStorage.setItem('token', credentials.token);
  }
  if(credentials.LIPToken) {
    localStorage.setItem('LIPToken', credentials.LIPToken);
    localStorage.setItem('expiresAt', credentials.expiresAt);
  }


};

export const deleteCredentials = () => {
    localStorage.removeItem('token',);
    localStorage.removeItem('firstName');
    localStorage.removeItem('currentAccountIndex');
    localStorage.removeItem('LIPToken');
    localStorage.removeItem('expiresAt');
};

export const getCredentials = () => {
  const {token, firstName , currentAccountIndex, LIPToken,expiresAt} = localStorage;

  if (
    token !== undefined &&
    LIPToken !== undefined
  ) {
    return {
      token,
      firstName,
      currentAccountIndex,
      LIPToken,
      credentialsPresent: true,
      expiresAt
    }
  }
  return {
    credentialsPresent: false,
  }
};
