import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { updatePersonalDetails } from "../store/actions/account-actions";
import { checkEmailAddress, isEmptyObject } from "../utilities/helpers";
import PersonalDetailsInput from "./PersonalDetailsInput";
import LoadingContainer from "./LoadingContainer";
import Button from "./Button";
import SelectionNotAvailable from './SelectionNotAvailable';

const mapStateToProps = ({ account }) => {
  return {
    accountOverviewData: account.accountOverviewData,
    accountOverviewLoaded: account.accountOverviewLoaded,
    lastName: account.accountOverviewLoaded
      ? Object.keys(account.accountOverviewData.ownerAccount).length !== 0
        ? account.accountOverviewData.ownerAccount.user.lastName
        : ""
      : "",
    firstName: account.accountOverviewLoaded
      ? Object.keys(account.accountOverviewData.ownerAccount).length !== 0
        ? account.accountOverviewData.ownerAccount.user.firstName
        : ""
      : "",
    email: account.accountOverviewLoaded
      ? Object.keys(account.accountOverviewData.ownerAccount).length !== 0
        ? account.accountOverviewData.ownerAccount.username
        : ""
      : "",
    accountId: account.accountOverviewLoaded
      ? Object.keys(account.accountOverviewData.ownerAccount).length !== 0
        ? account.accountOverviewData.ownerAccount.Id
        : ""
      : "",
    loading: !account.accountOverviewLoaded
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePersonalDetails
    },
    dispatch
  );

class PersonalDetails extends React.Component {
  state = {
    editDetails: false,
    editPassword: false,
    firstName: "",
    lastName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };

  handleEditDetailsButton = () =>
    this.setState(prevState => ({
      editDetails: !prevState.editDetails,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      emailAddressValid: true,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }));

  handleChangePasswordButton = () =>
    this.setState(() => ({
      editPassword: true,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }));

  handleCancelChangingPasswordButton = () =>
    this.setState(() => ({
      editPassword: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }));

  handleFirstNameChange = e => {
    const firstName = e.target.value;
    this.setState(() => ({ firstName }));
  };

  handleLastNameChange = e => {
    const lastName = e.target.value;
    this.setState(() => ({ lastName }));
  };

  handleEmailChange = e => {
    const email = e.target.value;
    this.setState(() => ({
      email,
      emailAddressValid: checkEmailAddress(email)
    }));
  };

  handleCurrentPasswordChange = e => {
    const currentPassword = e.target.value;
    this.setState(() => ({ currentPassword }));
  };

  handleNewPasswordChange = e => {
    const newPassword = e.target.value;
    this.setState(() => ({ newPassword }));
  };

  handleConfirmNewPasswordChange = e => {
    const confirmNewPassword = e.target.value;
    this.setState(() => ({ confirmNewPassword }));
  };

  handleCancelButton = () =>
    this.setState(() => ({
      editDetails: false,
      editPassword: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }));

  handleSaveButton = () => {
    this.setState(() => ({
      editDetails: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }));
    if (this.editedDetailsValid() === true) {
      const updatedDetails = {
        username: this.state.email,
        user: {
          firstName: this.state.firstName,
          lastName: this.state.lastName
        }
      };
      console.log("updatedDetails", updatedDetails);
      if (this.state.editPassword === true) {
        updatedDetails.password = this.state.currentPassword;
        updatedDetails.newPassword = this.state.newPassword;
      }
      console.log("updatedDetails", updatedDetails);
      this.props.updatePersonalDetails(this.props.accountId, updatedDetails);
    }
  };

  editedDetailsValid = () => {
    if (
      this.state.editPassword === true &&
      (this.state.newPassword !== this.state.confirmNewPassword ||
        this.state.newPassword.length < 8 ||
        this.state.currentPassword === "")
    ) {
      return false;
    }
    return (
      checkEmailAddress(this.state.email) === true &&
      this.state.editedFirstName !== "" &&
      this.state.editedLastName !== ""
    );
  };

  render() {

    return (
      <div className="PersonalDetails container">
        <div className="Account_section-title-row">
          <h2 className="Account_section-title-row_title">Owner Account</h2>
        </div>

        { isEmptyObject(this.props.accountOverviewData.ownerAccount) ? (
          <LoadingContainer className="content-card" loading={false}>
            <SelectionNotAvailable  text="Please assign an new owner account"/>
          </LoadingContainer>
        ) : (
          <LoadingContainer
            className="content-card"
            loading={this.props.loading}
          >
            <div className="PersonalDetails_inner">

              <PersonalDetailsInput
                label="First name"
                className="PersonalDetails_first-name-input"
                id="account-personal-details-first-name"
                inputType="text"
                errorMessage={
                  this.props.firstName === ""
                    ? "You must enter a first name."
                    : undefined
                }
                onChange={this.handleFirstNameChange}
                editing={this.state.editDetails}
                value={
                  this.state.editDetails
                    ? this.state.firstName
                    : this.props.firstName
                }
              />
              <PersonalDetailsInput
                label="Last name"
                className="PersonalDetails_last-name-input"
                id="account-personal-details-last-name"
                inputType="text"
                errorMessage={
                  this.props.lastName === ""
                    ? "You must enter a last name."
                    : undefined
                }
                onChange={this.handleLastNameChange}
                editing={this.state.editDetails}
                value={
                  this.state.editDetails
                    ? this.state.lastName
                    : this.props.lastName
                }
              />
              <PersonalDetailsInput
                label="Email"
                className="PersonalDetails_email-input"
                id="account-personal-details-email"
                inputType="email"
                errorMessage={
                  this.state.emailAddressValid
                    ? undefined
                    : "You must enter a valid email address"
                }
                onChange={this.handleEmailChange}
                editing={this.state.editDetails}
                value={
                  this.state.editDetails ? this.state.email : this.props.email
                }
              />

              {this.state.editDetails && (
                <React.Fragment>
                  {this.state.editPassword ? (
                    <React.Fragment>
                      <div className="PersonalDetails_change-password-button-wrapper">
                        <Button
                          label="Cancel Changing Password"
                          color="link"
                          onClick={this.handleCancelChangingPasswordButton}
                        />
                      </div>
                      <PersonalDetailsInput
                        label="Current password"
                        className="PersonalDetails_current-input"
                        id="account-personal-details-current"
                        inputType="password"
                        onChange={this.handleCurrentPasswordChange}
                        editing
                        value={this.state.currentPassword}
                      />
                      <PersonalDetailsInput
                        label="New password"
                        className="PersonalDetails_new-input"
                        id="account-personal-details-confirm"
                        inputType="password"
                        errorMessage={`${
                          this.state.newPassword !==
                          this.state.confirmNewPassword
                            ? "Passwords must match"
                            : ""
                        }${
                          this.state.newPassword !== "" &&
                          this.state.newPassword !==
                            this.state.confirmNewPassword &&
                          this.state.newPassword.length < 8
                            ? " - "
                            : ""
                        }${
                          this.state.newPassword !== "" &&
                          this.state.newPassword.length < 8
                            ? "Passwords must must be at least 8 characters long"
                            : ""
                        }`}
                        onChange={this.handleNewPasswordChange}
                        editing
                        value={this.state.newPassword}
                      />
                      <PersonalDetailsInput
                        label="Confirm new password"
                        className="PersonalDetails_confirm-input"
                        id="account-personal-details-new"
                        inputType="password"
                        onChange={this.handleConfirmNewPasswordChange}
                        editing
                        value={this.state.confirmNewPassword}
                      />
                    </React.Fragment>
                  ) : (
                    <div className="PersonalDetails_change-password-button-wrapper">
                      <Button
                        label="Change Password"
                        color="link"
                        onClick={this.handleChangePasswordButton}
                      />
                    </div>
                  )}
                  <div className="PersonalDetails_cancel-save-button-wrapper">
                    <Button
                      color="secondary"
                      onClick={this.handleCancelButton}
                      label="Cancel"
                    />{" "}
                    <Button
                      label="Save"
                      onClick={this.handleSaveButton}
                      disabled={!this.editedDetailsValid()}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </LoadingContainer>
        )}
      </div>
    );
  }
}

PersonalDetails.propTypes = {
  // firstName: PropTypes.string.isRequired,
  // lastName: PropTypes.string.isRequired,
  // email: PropTypes.string.isRequired,
  // accountId: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
