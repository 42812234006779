import {
  FETCH_CLIENT_LIST,
  FETCH_SDK_LIST,
  EDIT_SDK,
  CREATE_SDK,
  DELETE_SDK
} from "../constants";

export const fetchClientList = (pageType) => ({
  type: FETCH_CLIENT_LIST,
  pageType: pageType
});

export const fetchSDKList = (appIds) => ({
  type: FETCH_SDK_LIST,
  data: appIds
});

export const editSdk = (data, appId) => ({
  type: EDIT_SDK,
  data: data,
  appId: appId,
});

export const createSdk = (data, clientId) => ({
  type: CREATE_SDK,
  data: data,
  id: clientId
});

export const deleteSdk = (appId) => ({
  type: DELETE_SDK,
  data: { app_id : appId },
});
