import {
    FETCH_APP_LIST,
    FETCH_ORGANISATION_LIST,
    ADD_ORGANISATION_IN_APP,
    DELETE_ORGANISATION_FROM_APP,
    FETCH_CATEGORIES,
    FETCH_SUBCATEGORY_ORGANISATION,
    DUPLICATE_FROM_SELECTED_APP,
    DOWNLOAD_PLIST_FILE,
    DOWNLOAD_MANIFEST_FILE
  } from "../constants";
  
  export const fetchAppList =() => ({
    type: FETCH_APP_LIST
  });

  export const fetchCategories =() => ({
    type: FETCH_CATEGORIES
  });

  export const fetchSubcategoryOrganisation =(subcategoryName,countryName) => ({
    type: FETCH_SUBCATEGORY_ORGANISATION,
    data: { 
      subcategoryName,
      countryName
    }
  });
  
  export const fetchOrganisationList = (appId) => ({
    type: FETCH_ORGANISATION_LIST,
    appId: appId,
  });
  
  export const addOranisationInApp = (data) => ({
    type: ADD_ORGANISATION_IN_APP,
    data: data,
  });
  
  export const deleteOrganisaitonFromApp = (data) => ({
    type: DELETE_ORGANISATION_FROM_APP,
    data: data,
  });
  
  export const duplicateFromSelectedApp = (fromAppId, toAppId) => ({
    type: DUPLICATE_FROM_SELECTED_APP,
    data:{ fromAppId : fromAppId, toAppId: toAppId }
  });

  export const downloadPlistFile = (data) => ({
    type: DOWNLOAD_PLIST_FILE,
    data: data,
  });


  export const downloadManifestFile = (data) => ({
    type: DOWNLOAD_MANIFEST_FILE,
    data: data,
  });
  
  