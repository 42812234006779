import React from 'react';
import PropTypes from 'prop-types';

const PersonalDetailsInput = props => (
  <div
    className={`PersonalDetailsInput${
      props.className ? ` ${props.className}` : ''
    }`}
  >
    {props.editing ? (
      <React.Fragment>
        <label htmlFor={props.id} className="PersonalDetailsInput_label">
          <span className="PersonalDetailsInput_label_text">{props.label}</span>
          <input
            className="PersonalDetailsInput_input"
            type={props.inputType}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
          />
        </label>
        {props.errorMessage && (
          <p className="PersonalDetailsInput_error-message">{props.errorMessage}</p>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span className="PersonalDetailsInput_label_text">{props.label}</span>
        <span className="PersonalDetailsInput_value">{props.value}</span>
      </React.Fragment>
    )}
  </div>
);

PersonalDetailsInput.propTypes = {
  // id: PropTypes.string.isRequired,
  // label: PropTypes.string.isRequired,
  // editing: PropTypes.bool.isRequired,
  // inputType: PropTypes.string,
  // onChange: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired,
  // className: PropTypes.string,
  // errorMessage: PropTypes.string,
  // disabled: PropTypes.bool,
};

PersonalDetailsInput.defaultProps = {
  inputType: 'text',
  className: undefined,
  disabled: undefined,
  errorMessage: undefined,
};
export default PersonalDetailsInput;
