import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import Popover from "@material-ui/core/Popover";
import SVGIcon from "../components/SVGIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSubcategoryOrganisation,
  addOranisationInApp,
  duplicateFromSelectedApp
} from "../store/actions/footfall-actions";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";

const ActiveOrganisation = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const subcategoryOrganisationNow = useSelector(
    state => state.footfallConfiguration.subcategoryOrganisationData
  );
  const subcategoryOrganisationDataLoaded = useSelector(
    state => state.footfallConfiguration.subcategoryOrganisationDataLoaded
  );

  const clientListData = useSelector(
    state => state.sdkConfiguration.clientListData
  );

  const userAccountName = useSelector(state => state.auth.tokenPayload.user);

  useEffect(() => {
    dispatch(
      fetchSubcategoryOrganisation(
        "Landmarks ID",
        props.clientListData[props.clientIndexNow].country
      )
    );
  }, []);

  const [subcategoryNow, setSubcategoryNow] = useState({
    label: "Landmarks ID",
    value: "Landmarks ID"
  });

  const [clientNow, setClientNow] = useState(clientListData[0]);
  const [checkedItems, setCheckedItems] = useState({});
  const [tabValue, setTabValue] = React.useState(0);
  const [activateType, setActivateType] = React.useState("one");

  const [selectedApp, setRadioValue] = React.useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubcategoryChange = event => {
    setSubcategoryNow(event);
    dispatch(
      fetchSubcategoryOrganisation(
        event.label,
        props.clientListData[props.clientIndexNow].country
      )
    );
  };

  const handleTab2ClientChange = event => {
    setClientNow(event);
  };

  const handleChange = event => {
    setRadioValue(event.target.value);
  };

  const sendDuplicateAction = () => {
    dispatch(duplicateFromSelectedApp(selectedApp, props.appIdNow));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const headCells = [
    { id: "checkbox", label: "" },
    { id: "name", label: "Oraganisation Name" },
    { id: "country", label: "Country" },
    { id: "type", label: "Type" },
    { id: "isActive", label: "Status" }
  ];
  const cellWidth = idx => {
    switch (idx) {
      case 0:
        return { width: "5%" };
      case 1:
        return { width: "40%" };
      default:
        return { width: "18%" };
    }
  };

  let activeNumber = 0;
  const createCountRows = data => {
    if (props.ActiveOrganisationData.length === 0) {
      return data;
    } else {
      data.forEach(item => {
        if (
          props.ActiveOrganisationData.some(
            findItem => findItem._id === item._id
          )
        ) {
          item.isActive = true;
          activeNumber = activeNumber + 1;
        }
      });
    }
    return data;
  };

  const rowsPerPage = 10;
  let rows = createCountRows(subcategoryOrganisationNow.concat());

  const [currentIndex, setIndex] = useState(0);
  const [currentSubcategoryIndex, setSubcategoryIndex] = useState(0);
  const [isOpen, toggleModal] = useState(false);
  const [category, setCategory] = useState({
    label: "All category",
    value: 0
  });

  const handleLeftButtonClick = () => {
    setIndex(prevIndex => Math.max(prevIndex - rowsPerPage, 0));
  };

  const handleRightButtonClick = () => {
    if (currentIndex + rowsPerPage < rows.length) {
      setIndex(prevIndex =>
        Math.min(
          prevIndex + rowsPerPage,
          Math.floor(rows.length / rowsPerPage) * rowsPerPage
        )
      );
    }
  };

  const statusRender = isActive => {
    if (isActive === true) {
      return (
        <div style={{ color: "#4caf50" }}>
          {" "}
          <strong>Active</strong>
        </div>
      );
    } else {
      return (
        <div style={{ color: "#f44336" }}>
          {" "}
          <strong>Inactive</strong>
        </div>
      );
    }
  };

  const handleCheck = e =>
    setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked });

  const handleAppActivation = async isUpdateRoportConfiguration => {
    let insertDataArray = [];
    Object.keys(checkedItems).length > 0 &&
      Object.keys(checkedItems).map((item, idx) => {
        checkedItems[item] !== false && insertDataArray.push(item);
      });

    if (activateType === "one") {
      await dispatch(
        addOranisationInApp({
          appIds: [props.appIdNow],
          organIds: insertDataArray,
          isUpdateRoportConfiguration,
          subcategory: subcategoryNow.label,
          category: subcategoryNow.parentCategory,
          clientId: props.clientId,
          osType: props.currentAppOsType,
          userAccountName,
          country: props.clientListData[props.clientIndexNow].country
        })
      );
    } else {
      await dispatch(
        addOranisationInApp({
          appIds: props.clientAppsNow,
          organIds: insertDataArray,
          isUpdateRoportConfiguration,
          subcategory: subcategoryNow.label,
          category: subcategoryNow.parentCategory,
          clientId: props.clientId,
          osType: 'all',
          userAccountName,
          country: props.clientListData[props.clientIndexNow].country
        })
      );
    }
    props.toggleModal(false);
    reGettingtheData();
  };

  const reGettingtheData = () => {
    setTimeout(() => {
      dispatch(props.fetchOrganisationList(props.appIdNow));
    }, 1500);
  };

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActiveButton = (event, type) => {
    setActivateType(type);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log(props);
  return (
    <div className="SDKItem_modal" style={{ display: "block" }}>
      <h4>Active Organisation</h4>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Active By Subcategories" />
        {/* <Tab label="Active By Clients" /> */}
      </Tabs>

      {tabValue === 0 ? (
        <React.Fragment>
          <label className="CriteriaModal_label">Select subcategory:</label>
          <Select
            inputId="criteria-modal-select-organisation"
            className="CriteriaModal_search-organisation-input"
            style={{ width: "5rem" }}
            classNamePrefix="CriteriaModal_search-organisation-input_"
            placeholder="Type to search"
            isDisabled={false}
            isRtl={false}
            isSearchable
            name="subcategories"
            options={props.categoriesData}
            onChange={event => handleSubcategoryChange(event)}
            value={subcategoryNow}
          />

          <div
            className="Organisations_tableArea"
            style={{ marginTop: "2rem" }}
          >
            <div>
              {subcategoryOrganisationDataLoaded ? (
                <table style={{ width: "100%" }}>
                  <tr className="Organisations_tableRowHead">
                    {headCells.map((headCell, idx) => (
                      <td
                        key={headCell.id}
                        className={
                          idx === 0
                            ? "Organisations_tableRowHead_firstCell"
                            : "Organisations_tableRowHead_nthCell"
                        }
                        style={cellWidth(idx)}
                      >
                        {headCell.label}
                      </td>
                    ))}
                  </tr>
                  <tbody>
                    {rows
                      .sort((a, b) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                      )
                      .slice(currentIndex, currentIndex + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <tr
                            key={index}
                            className={"Organisations_tableRowBody"}
                          >
                            {headCells.map((headCell, idx) => (
                              <td
                                key={headCell.id}
                                className={
                                  idx === 0
                                    ? "Organisations_tableRowBody_firstCell"
                                    : "Organisations_tableRowBody_nthCell"
                                }
                                style={cellWidth(idx)}
                              >
                                {headCell.id === "name" ? (
                                  row.name
                                ) : headCell.id === "country" ? (
                                  row.country
                                ) : headCell.id === "type" ? (
                                  row.organisationType === "digital" ? (
                                    "Digital"
                                  ) : (
                                    "Physical"
                                  )
                                ) : headCell.id === "isActive" ? (
                                  statusRender(row.isActive)
                                ) : (
                                  <input
                                    checked={checkedItems[row._id]}
                                    name={row._id}
                                    type="checkbox"
                                    onChange={handleCheck}
                                    disabled={row.isActive === true}
                                  />
                                )}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div>
                  <LinearProgress />
                </div>
              )}
            </div>

            <div className={classes.CountArea}>
              <div className={classes.CountItem}>
                <div className={classes.Active}>
                  {" "}
                  <strong>{activeNumber}</strong> Active{" "}
                </div>
              </div>
              <div className={classes.CountItem}>
                <strong>{rows.length - activeNumber}</strong> Inactive
              </div>
              <div className={classes.CountItem}>
                <strong>{rows.length}</strong> Total
              </div>

              <div
                style={{
                  padding: 10,
                  textAlign: "right",
                  display: "inline-block",
                  float: "right"
                }}
              >
                <span className="WhereYourCustomersHaveBeenGraph_top-row_showing">
                  Showing {currentIndex + 1} -{" "}
                  {Math.min(currentIndex + rowsPerPage, rows.length)} of{" "}
                  {rows.length}
                </span>
                <div className="WhereYourCustomersHaveBeenGraph_top-row_button-group">
                  <button type="button" onClick={handleLeftButtonClick}>
                    <SVGIcon icon="left-arrow" />
                  </button>
                  <button type="button" onClick={handleRightButtonClick}>
                    <SVGIcon icon="right-arrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Button
            variant="contained"
            style={{
              color: "white",
              float: "right",
              marginLeft: "1rem",
              backgroundColor: "#2196F3"
            }}
            name="one"
            value="one"
            onClick={e => handleActiveButton(e, "one")}
          >
            Active In this App
          </Button>
          <Button
            variant="contained"
            style={{
              color: "white",
              float: "right",
              backgroundColor: "#2196F3"
            }}
            name="all"
            value="all"
            onClick={e => handleActiveButton(e, "all")}
          >
            Active In All Apps
          </Button>
        </React.Fragment>
      ) : (
        <div>
          <label className="CriteriaModal_label">Select client:</label>
          <Select
            inputId="criteria-modal-select-organisation"
            className="CriteriaModal_search-organisation-input"
            style={{ width: "5rem" }}
            classNamePrefix="CriteriaModal_search-organisation-input_"
            placeholder="Type to search"
            isDisabled={false}
            isRtl={false}
            isSearchable
            name="cleints"
            options={clientListData.filter(function(value, index) {
              return value.clientId !== props.clientId;
            })}
            onChange={event => handleTab2ClientChange(event)}
            value={clientNow}
          />

          <FormControl component="fieldset">
            <label className="CriteriaModal_label">Select app:</label>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={selectedApp}
              onChange={handleChange}
            >
              {clientNow.value.map(item => {
                return (
                  <FormControlLabel
                    key={item._id}
                    value={item._id}
                    control={<Radio />}
                    label={item.name}
                  />
                );
              })}
            </RadioGroup>
            <Button
              variant="contained"
              style={{
                color: "white",
                float: "right",
                backgroundColor: "#2196F3",
                marginTop: "30px"
              }}
              onClick={() => sendDuplicateAction()}
              disabled={selectedApp === null}
            >
              Duplicate From This App
            </Button>
          </FormControl>
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div style={{ padding: "1rem" }}>
          <h2 style={{ fontSize: "1.25rem" }}>
            {" "}
            Would you also like to reflect the same update in the Report
            Configuration?
          </h2>
          <p style={{ fontSize: "1rem" }}>
            It will shows the updates on the LIP.
          </p>
          <Button
            variant="text"
            color="primary"
            onClick={() => handleAppActivation(false)}
          >
            No
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => handleAppActivation(true)}
          >
            Yes
          </Button>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  CountArea: {
    padding: "1rem",
    marginBottom: "2rem"
  },
  CountItem: {
    display: "inline-block",
    padding: "1rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  Active: {},
  Inactive: {},
  Total: {}
}));

export default ActiveOrganisation;
