import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import Button from "@material-ui/core/Button";
import LoadingContainer from "./LoadingContainer";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import { Modal } from "reactstrap";

import AddNewSDK from "./AddNewSDK";
import EditSDKItem from "./EditSDKItem";
import { createSdk, deleteSdk, editSdk } from "../store/actions/sdk-actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  float: {
    float: "right",
    backgroundColor: "#2196F3",
    color: "#fff"
  },
  Popover: {
    padding: "1rem"
  }
}));

const SDKItems = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, toggleModal] = useState(false);
  const [iOSEditIdx, setiOSEditIdx] = useState(null);
  const [androidEditIdx, setAndroidEditIdx] = useState(null);
  const [deletingAppId , setDeletingAppId] = useState(null);
  

  // interactive behaviors
  const handleToggleModal = () => toggleModal(!isOpen);

  const createNewApp = async data => {
    await dispatch(createSdk(data, props.clientId));
    setTimeout(function(){  window.location.reload() }, 1000);
    toggleModal(!isOpen);
  };
  const deleteApp = async () => {

    await dispatch(deleteSdk(deletingAppId));
    setTimeout(function(){  props.fetchLatestSDKList() }, 1000);
    setAnchorEl(null)
    setAnchorEl2(null)
  };

  const handleiOSEditButton = idx => {
    setiOSEditIdx(idx);
  };
  const handleAndroidEditButton = idx => {
    setAndroidEditIdx(idx);
  };

  const finishEdit = () => {
    setiOSEditIdx(null);
    setAndroidEditIdx(null);
  };

  const sendEditContent = async (data, appId) =>{

    await dispatch(editSdk(data,appId));
    setTimeout(function(){  props.fetchLatestSDKList() }, 1000);
  }

  const cancelEdit = () =>{
    setiOSEditIdx(null);
    setAndroidEditIdx(null)
  }

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {

    setDeletingAppId(event.currentTarget.value)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // popover 2
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = event => {

    setDeletingAppId(event.currentTarget.value)
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open ? "simple-popover" : undefined;

  return (
    <LoadingContainer loading={false} className="content-card">
      <div className="SDKItem">
        <Tooltip title="Add" aria-label="add">
          <Fab className={classes.float} size="small">
            <AddIcon onClick={() => toggleModal(true)} />
          </Fab>
        </Tooltip>
        <div className="SDKItem_inner">
          <div className="SDKItem_inner_iOS-title">
            <h4>
              {" "}
              <AppleIcon fontSize="small" style={{ marginBottom: "5px" }} /> iOS
              App
            </h4>
          </div>
          <div className="SDKItem_inner_android-title">
            <h4>
              <AndroidIcon fontSize="small" /> Android App
            </h4>
          </div>
          <div className="SDKItem_inner_title-bottomline"></div>
          <div className="SDKItem_inner_iOS-content">
            {props.data.length > 0 &&
              props.data.map((item, idx) => {
                if (item.os !== undefined && item.os.toLowerCase() === 'ios') {
                  return idx === iOSEditIdx ? (
                    <React.Fragment key={idx}>
                      <EditSDKItem 
                        data={item} 
                        finishEdit={finishEdit}
                        sendEditContent={sendEditContent}
                        cancelEdit={cancelEdit}
                      />
                    </React.Fragment>
                  ) : (
                    <div className="SDKItem_inner_iOS-content_item" key={idx}>
                      {Object.keys(item).map(function(key, index) {
                        return (
                          <span className="field" key={key}>
                            <h3 className="field-name">{key}</h3>
                            <p className="field-value">
                              {key === "name" ? (
                                <strong> { item[key] ? String(item[key]) : "" }</strong>
                              ) : (
                                item[key] === "" ? "Empty Value" : String(item[key])
                              )}
                            </p>
                          </span>
                        );
                      })}
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "1rem" }}
                        onClick={() => handleiOSEditButton(idx)}
                      >
                        Edit App
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        value={item._id}
                        onClick={handleClick}
                      >
                        Delete App
                      </Button>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                      >
                        <div className={classes.Popover}>
                          <h2 style={{fontSize: '1.25rem'}}> Are you sure you wanna delete this app?</h2>
                          <p style={{fontSize: '1rem'}}>
                            It will also remove this app from every client
                          </p>
                          <Button variant="text" color="primary" onClick={handleClose}>
                            Cancel
                          </Button>
                         
                          <Button variant="text" color="primary"  onClick={() => deleteApp()}>
                            Confirm
                          </Button>
                        </div>
                      </Popover>

                    </div>
                  );
                }
              })}
          </div>

          <div className="SDKItem_inner_android-content">
            {props.data.length > 0 &&
              props.data.map((item, idx) => {
                if (item.os !== undefined && item.os.toLowerCase() === 'android') {
                  return idx === androidEditIdx ? (
                    <React.Fragment key={idx}>
                      <EditSDKItem 
                        data={item} 
                        finishEdit={finishEdit}
                        sendEditContent={sendEditContent}
                        cancelEdit={cancelEdit}
                      />
                    </React.Fragment>
                  ) : (
                    <div className="SDKItem_inner_iOS-content_item" key={idx}>
                      {Object.keys(item).map(function(key, index) {
                        return (
                          <span className="field" key={key}>
                            <h3 className="field-name">{key}</h3>
                            <p className="field-value">
                              {key === "name" ? (
                                <strong> {String(item[key])}</strong>
                              ) : (
                                item[key] === "" ? "Empty Value" : String(item[key])
                              )}
                            </p>
                          </span>
                        );
                      })}
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "1rem" }}
                        onClick={() => handleAndroidEditButton(idx)}
                      >
                        Edit App
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        value={item._id}
                        onClick={handleClick2}
                      >
                        Delete App
                      </Button>
                      <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                      >
                        <div className={classes.Popover}>
                          <h2 style={{fontSize: '1.25rem'}}> Are you sure you wanna delete this app?</h2>
                          <p style={{fontSize: '1rem'}}>
                            It will also remove this app from every client
                          </p>
                          <Button variant="text" color="primary" onClick={handleClose}>
                            Cancel
                          </Button>
               
                          <Button variant="text" color="primary"onClick={() => deleteApp()}>
                            Confirm
                          </Button>
                        </div>
                      </Popover>
                    </div>
                  );
                } 
              })}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        toggle={handleToggleModal}
        style={{ width: "80rem" }}
        centered
        contentClassName="CriteriaModal_wrapper"
        backdropClassName="CriteriaModal_background"
      >
        <AddNewSDK createNewApp={createNewApp} />
      </Modal>
    </LoadingContainer>
  );
};

export default SDKItems;
