import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
    CREATE_SDK ,
    CREATE_SDK_SUCCESS ,
    CREATE_SDK_FAIL,
} from '../../constants';

const createSDK = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_SDK),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/apps/create/${action.id}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
        body: action.data,
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {
          console.log(response)
          return {
            type: CREATE_SDK_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: CREATE_SDK_FAIL,
            error,
          })
        )
      )

    )
  );

export default createSDK;
