import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TextField from "@mui/material/TextField";
import Button from "./Button";
import SharedTable from "./sharedComponents/SharedTable";
import { fetchSubcategoryOrganisation } from "../store/actions/footfall-actions";

const ReportItem = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const subcategoryOrganisationNow = useSelector(
    state => state.footfallConfiguration.subcategoryOrganisationData
  );
  const subcategoryOrganisationDataLoaded = useSelector(
    state => state.footfallConfiguration.subcategoryOrganisationDataLoaded
  );

  const [loading, setLoading] = useState(false);

  const [currentSubcategory, setCurrentSubcategory] = useState(
    props.reportName === "brandAffinity"
      ? {
          label: "Landmarks ID",
          value: "Landmarks ID"
        }
      : {
          label: "All Subcategory",
          name: "All Subcategory"
        }
  );

  const [brandOrCategoryEditing, setBrandOrCategoryEditing] = useState("both");

  const initialGeneralDataObj = {
    region: props.data.tiers.some(element => element === "region"),
    district: props.data.tiers.some(element => element === "district"),
    city: props.data.tiers.some(element => element === "city"),
    locality: props.data.tiers.some(element => element === "locality"),
    enableGeographyTab:
      props.data.props !== undefined && props.data.props.enableGeographyTab,
    showPOIsDropdown:
      props.data.props !== undefined && props.data.props.showPOIsDropdown,
    enableReport:
      props.data.props !== undefined && props.data.props.enableReport,
    enableFrequency:
      props.data.props !== undefined && props.data.props.enableFrequency,
    showAppsDropdown:
      props.data.props !== undefined && props.data.props.showAppsDropdown,
    confidenceInterval:
      props.data.props !== undefined && props.data.props.confidenceInterval,
    customMobileApplicationsName:
      props.data.props !== undefined && props.data.props.customMobileApplicationsName,    
    months12:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined
        ? props.data.props.datePeriod[0].enable
        : null,
    days90:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined
        ? props.data.props.datePeriod[1].enable
        : null,
    days30:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined
        ? props.data.props.datePeriod[2].enable
        : null,
    category:
      props.data.category !== undefined &&
      props.data.category.map(subcategory => ({
        label: `${subcategory.label} (${subcategory.count} / ${subcategory.totalCount})`,
        value: subcategory.label
      })),
    brand: props.data.brand !== undefined && props.data.brand,
    organizations:
      props.data.organizations !== undefined && props.data.organizations
  };

  const initialdatePartDataObj = {
    months12DatePart:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined &&
      props.data.props.datePeriod[0].datePart !== undefined
        ? props.data.props.datePeriod[0].datePart
        : null,
    days90DatePart:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined &&
      props.data.props.datePeriod[1].datePart !== undefined
        ? props.data.props.datePeriod[1].datePart
        : null,
    days30DatePart:
      props.data.props !== undefined &&
      props.data.props.datePeriod !== undefined &&
      props.data.props.datePeriod[2].datePart !== undefined
        ? props.data.props.datePeriod[2].datePart
        : null
  };

  const [state, setState] = useState(initialGeneralDataObj);

  const [dateParts, setDateParts] = useState(initialdatePartDataObj);

  useEffect(() => {
    // reset tab when choose another client
    setState(initialGeneralDataObj);
    setDateParts(initialdatePartDataObj);
  }, [props.clientNow, props.data]);

  useEffect(() => {
    dispatch(fetchSubcategoryOrganisation("Landmarks ID", props.country));
  }, []);

  const reFetchSubcategoryOrganisation = event => {
    dispatch(fetchSubcategoryOrganisation(event.label, props.country));
  };

  const handleCheckboxChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleInputChange = event =>{
     setState({ ...state, [event.target.id]: event.target.value });
  }

  const handleDatePart = (event, item, idx) => {
    const targetName = event.target.name;
    const newObj = {
      label: item.label,
      value: item.value,
      enable: event.target.checked
    };
    let newArray = dateParts[targetName];
    newArray[idx] = newObj;

    setDateParts({
      ...dateParts,
      targetName: newArray
    });
  };

  const handleDataStateChange = (event, target) => {
    if (target === "brand") {
      setBrandOrCategoryEditing("brand");
      setState({ ...state, brandAffinityChangeField: target, [target]: event });
    } else {
      setState({ ...state, [target]: event });
    }
  };

  const handleCategoryAffinityChange = event => {
    const originCategoryAffinityArray = props.data.category.map(
      subcategory => ({
        label: subcategory.label,
        value: subcategory.label
      })
    );

    let categoryAffinityRemovingArray = [];
    // Find which category is removed from array
    originCategoryAffinityArray.forEach((item, idx, object) => {
      let check = event.map(item => item.value).includes(item.value);
      if (!check) {
        categoryAffinityRemovingArray.push(item);
      }
    });
    // Find which category is added in array
    let categoryAffinityAddingArray = [];
    event.forEach((item, idx, object) => {
      let check = originCategoryAffinityArray
        .map(item => item.value)
        .includes(item.value);
      if (!check) {
        categoryAffinityAddingArray.push(item);
      }
    });

    setBrandOrCategoryEditing("category");
    setState({
      ...state,
      brandAffinityChangeField: "category",
      categoryAffinityRemovingArray,
      categoryAffinityAddingArray,
      category: event
    });
  };



  const collectContentAndSend = () => {
    setLoading(true);
    setBrandOrCategoryEditing("both");
    console.log(state);
    props.sendReportContent(state, dateParts, props.reportIndex);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", padding: 20 }}>
      <div className={classes.rowContainerThree}>
        <span className="PersonalDetailsInput_label_text">Tiers</span>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.region}
                onChange={handleCheckboxChange}
                name="region"
              />
            }
            label={<Typography style={{ fontSize: 12 }}>Region</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.district}
                onChange={handleCheckboxChange}
                name="district"
              />
            }
            label={<Typography style={{ fontSize: 12 }}>District</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.city}
                onChange={handleCheckboxChange}
                name="city"
              />
            }
            label={<Typography style={{ fontSize: 12 }}>City</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.locality}
                onChange={handleCheckboxChange}
                name="locality"
              />
            }
            label={<Typography style={{ fontSize: 12 }}>Locality</Typography>}
          />
        </FormGroup>
      </div>
      <div className={classes.rowContainerThree}>
        <span className="PersonalDetailsInput_label_text">Props</span>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={state.enableReport}
                onChange={handleCheckboxChange}
                name="enableReport"
                color="primary"
              />
            }
            label={
              <Typography style={{ fontSize: 12 }}>
                Enable This Report
              </Typography>
            }
          />

          {props.data.name !== "footfall" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.enableGeographyTab}
                  onChange={handleCheckboxChange}
                  name="enableGeographyTab"
                />
              }
              label={
                <Typography style={{ fontSize: 12 }}>
                  Enable Geography Tab
                </Typography>
              }
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={state.showPOIsDropdown}
                onChange={handleCheckboxChange}
                name="showPOIsDropdown"
              />
            }
            label={
              <Typography style={{ fontSize: 12 }}>
                Show POI Dropdown
              </Typography>
            }
          />

          {props.data.name === "brandAffinity" && (
            <React.Fragment>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.enableFrequency}
                    onChange={handleCheckboxChange}
                    name="enableFrequency"
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>
                    Enable Frequency
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.showAppsDropdown}
                    onChange={handleCheckboxChange}
                    name="showAppsDropdown"
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>
                    Show Apps Dropdown
                  </Typography>
                }
              />

              <TextField
                id="confidenceInterval"
                label="Confidence Interval"
                type="number"
                variant="standard"
                value={state.confidenceInterval}
                placeholder="Insert Interval Value"
                onChange={e => handleInputChange(e)}
                size="small"
              />
              <TextField
                id="customMobileApplicationsName"
                label="Custom Mobile Applications Name"
                variant="standard"
                value={state.customMobileApplicationsName}
                onChange={e => handleInputChange(e)}
                placeholder="Insert Name"
                size="small"
              />
            </React.Fragment>
          )}
        </FormGroup>
      </div>

      <div className={classes.rowContainerThree}>
        {props.data.name === "brandAffinity" ||  (props.data.name === "footfall" && props.accountType ==='intelligence')   && (
          <React.Fragment>
            <span className="PersonalDetailsInput_label_text">Date Period</span>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.months12}
                    onChange={handleCheckboxChange}
                    name="months12"
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>
                    Last 12 months
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.days90}
                    onChange={handleCheckboxChange}
                    name="days90"
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>Last 90 days</Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.days30}
                    onChange={handleCheckboxChange}
                    name="days30"
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>Last 30 days</Typography>
                }
              />
            </FormGroup>
          </React.Fragment>
        )}

        {props.data.name === "travelPast" && (
          <React.Fragment>
            <span className="PersonalDetailsInput_label_text">
              Date Period and Part
            </span>
            <TreeView
              className={classes.root}
              defaultExpanded={["root"]}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem
                nodeId={`1`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.months12}
                        onChange={handleCheckboxChange}
                        name="months12"
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>
                        Last 12 months
                      </Typography>
                    }
                  />
                }
              >
                {dateParts.months12DatePart !== null &&
                  dateParts.months12DatePart.map((item, idx) => {
                    return (
                      <TreeItem
                        key={item.value}
                        nodeId={item.value}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.enable}
                                onChange={e => handleDatePart(e, item, idx)}
                                name="months12DatePart"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                {item.label}
                              </Typography>
                            }
                          />
                        }
                      ></TreeItem>
                    );
                  })}
              </TreeItem>

              <TreeItem
                nodeId={`2`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.days90}
                        onChange={handleCheckboxChange}
                        name="days90"
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>
                        Last 90 days
                      </Typography>
                    }
                  />
                }
              >
                {dateParts.days90DatePart !== null &&
                  dateParts.days90DatePart.map((item, idx) => {
                    return (
                      <TreeItem
                        key={item.value}
                        nodeId={item.value}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.enable}
                                onChange={e => handleDatePart(e, item, idx)}
                                name="days90DatePart"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                {item.label}
                              </Typography>
                            }
                          />
                        }
                      ></TreeItem>
                    );
                  })}
              </TreeItem>

              <TreeItem
                nodeId={`3`}
                label={
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.days30}
                        onChange={handleCheckboxChange}
                        name="days30"
                      />
                    }
                    label={
                      <Typography style={{ fontSize: 12 }}>
                        Last 30 days
                      </Typography>
                    }
                  />
                }
              >
                {dateParts.days30DatePart !== null &&
                  dateParts.days30DatePart.map((item, idx) => {
                    return (
                      <TreeItem
                        key={item.value}
                        nodeId={item.value}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.enable}
                                onChange={e => handleDatePart(e, item, idx)}
                                name="days30DatePart"
                              />
                            }
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                {item.label}
                              </Typography>
                            }
                          />
                        }
                      ></TreeItem>
                    );
                  })}
              </TreeItem>
            </TreeView>
          </React.Fragment>
        )}
      </div>

      {props.data.name === "brandAffinity" && (
        <React.Fragment>
          <div className={classes.rowContainerSelect}>
            <span className="PersonalDetailsInput_label_text">
              Activate Category Affinities
            </span>
            <label className="CriteriaModal_label">
              Category Affinities showing on LIP:
            </label>
            <Select
              inputId="criteria-modal-select-organisation"
              className="CriteriaModal_search-organisation-input"
              style={{ width: "5rem" }}
              classNamePrefix="CriteriaModal_search-organisation-input_"
              placeholder="Type to search"
              isDisabled={brandOrCategoryEditing === "brand"}
              isRtl={false}
              isSearchable
              isMulti
              name="category"
              options={props.categoriesData}
              onChange={event => handleCategoryAffinityChange(event)}
              value={state.category}
            />
          </div>

          <div className={classes.rowContainer}>
            <span className="PersonalDetailsInput_label_text">
              Activate Brand Affinities
            </span>
            <label className="CriteriaModal_label">
              Brand Affinities showing on LIP:
            </label>
            <SharedTable
              wholeData={subcategoryOrganisationNow}
              comparedData={state.brand}
              handleDataStateChange={handleDataStateChange}
              headCells={[
                { id: "checkbox", label: "" },
                { id: "name", label: "Brand Name" },
                { id: "subcategory", label: "Subcategory" },
                { id: "isActive", label: "Status" }
              ]}
              statusString={{ positive: "Showing", negative: "Not Showing" }}
              currentSubcategory={currentSubcategory}
              setCurrentSubcategory={setCurrentSubcategory}
              categoriesData={props.categoriesData}
              subcategorySelectType="taxonomy"
              reFetchSubcategoryOrganisation={reFetchSubcategoryOrganisation}
              LinearProgress={subcategoryOrganisationDataLoaded}
              isDisabled={brandOrCategoryEditing === "category"}
            />
          </div>
        </React.Fragment>
      )}

      {(props.data.name === "footfall" ||
        props.data.name === "mobileDevices") && (
        <div className={classes.rowContainer}>
          <span className="PersonalDetailsInput_label_text">
            Showing organisations
          </span>

          <SharedTable
            wholeData={
              props.data.name === "footfall"
                ? props.activatingOrgsData.physicalOrgsArray
                : props.activatingOrgsData.digitalOrgsArray
            }
            comparedData={state.organizations}
            handleDataStateChange={handleDataStateChange}
            headCells={[
              { id: "checkbox", label: "" },
              { id: "name", label: "Oraganisation Name" },
              { id: "category", label: "Category" },
              { id: "subcategory", label: "Subcategory" },
              { id: "country", label: "Country" },
              { id: "isActive", label: "Status" }
            ]}
            statusString={{ positive: "Showing", negative: "Not Showing" }}
            currentSubcategory={currentSubcategory}
            setCurrentSubcategory={setCurrentSubcategory}
            subcategorySelectType="fromData"
            LinearProgress={true}
          />
        </div>
      )}

      <div className={classes.saveButtonContainer}>
        <Button
          label="Save"
          loading={loading}
          onClick={() => collectContentAndSend()}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  rowContainerThree: {
    flex: "0 0 33.333333%",
    paddingBottom: 30
  },
  rowContainerSelect: {
    flex: "0 0 70%",
    paddingBottom: 30
  },
  rowContainer: {
    flex: "0 0 100%",
    paddingBottom: 30,
    borderTop: "1px #d6dee9 solid",
    paddingTop: "2rem"
  },
  saveButtonContainer: {
    flex: "0 0 100%"
  }
}));

export default ReportItem;
