import { push } from 'connected-react-router'

import { SET_SCREEN_PROPERTIES } from '../store/constants';

import store from '../store/store';

export const setScreenProperties = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  let screenOrienatation = 'not set';
  if (screenWidth < screenHeight) {
    screenOrienatation = 'portrait';
  } else if (screenWidth > screenHeight) {
    screenOrienatation = 'landscape';
  } else if (screenWidth === screenHeight) {
    screenOrienatation = 'square';
  }
  // Get CSS breakpoint from body:before pseudo-class
  // assumes it's been set by the CSS media queries, defaults to 'not set' if nothing found
  let cssBreakpoint =
    window.getComputedStyle(document.body, ':before').content || 'not set';
  // Trim quotation marks on browser that include them (i.e. Chrome)
  if (
    cssBreakpoint.substring(0, 1) === '"' &&
    cssBreakpoint.substring(cssBreakpoint.length - 1) === '"'
  ) {
    cssBreakpoint = cssBreakpoint.substring(1, cssBreakpoint.length - 1);
  }
  store.dispatch({
    type: SET_SCREEN_PROPERTIES,
    screenWidth,
    screenHeight,
    screenOrienatation,
    cssBreakpoint,
  });
};

export const pushRoute = route => {
  store.dispatch(push(route));
};

export const formatNumber = number => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1])
    parts[1] = parts[1].substring(0, 2);
  return parts.join('.');
};

export const checkEmailAddress = emailAddress => {
  const pattern = new RegExp(
    // eslint-disable-next-line
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
  );
  return pattern.test(emailAddress);
};

export const decodeToken = token => JSON.parse(atob(token.split('.')[1]));

export const noop = () => { };

export const isIE = () =>
  navigator.appName === 'Microsoft Internet Explorer' ||
  !!(
    navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)
  );

export const randomString = () => Math.round(Math.random() * 1e8).toString(36);

export const uniqueId = () =>
  `${new Date()
    .getTime()
    .toString(
      36
    )}-${randomString()}-${randomString()}-${randomString()}`.toUpperCase();

export const boundariesSet = boundaries =>
  Array.isArray(boundaries) && boundaries.length > 0;

export const objectNotEmpty = baseline =>
  typeof (baseline) === 'object' && Object.keys(baseline).length > 0

export const concatenateBoundaries = boundaries =>
  boundariesSet(boundaries)
    ? encodeURIComponent(boundaries.map(item => item.value).join(','))
    : '';


export const concatenateBoundariesByname = boundaries => {
  let newArray = boundaries.map(item => {
    return item.label.indexOf(',') > 0
    ? item.label.replace(/\s+/g, "").substring(0, item.label.replace(/\s+/g, "").indexOf(','))
    : item.label
  })
  return newArray.join('_');
}



export const sendToGTMDataLayer = (dataObj) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataObj);
};


export const isSelectedMultipleAdm = boundaryArray => {

  let isMultiple = false;
  if (boundaryArray !== null && Array.isArray(boundaryArray)) {
    for (let i = 0; i < boundaryArray.length; i++) {
      if (boundaryArray[i] !== null) {
        isMultiple = boundaryArray[i].length > 1
        break;
      }
    }

  }

  return isMultiple

}


export const isEmptyObject = (obj) => {
  return  obj.constructor === Object && Object.keys(obj).length === 0
}

export const isEmptyArray = (array) => {
  return Array.isArray(array) && array.length === 0
}


export const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const stableSort = (array, cmp)  => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const getSorting = (order, orderBy) =>{
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export const isDataAvailable = (data) =>{
  if(data === undefined || data === null){
    return false;
  }
  return true
}

export const escapeRegexCharacters = (str) =>{
 return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


export const includes =(container, value) =>{ // replace the js original func String.prototype.includes
	var returnValue = false;
	var pos = container.indexOf(value);
	if (pos >= 0) {
		returnValue = true;
	}
	return returnValue;
}

export const formatSelection = (data) =>{
  let resultArray = [];

  if(data === undefined || data.length === 0){
    return resultArray;
  }else{

    data.map((item)=>{
      resultArray.push({
        label: item,
        value: item
      })

    })

    return resultArray
  }

}


export const searchStringInObjectArray = (nameKey, myArray, searchKey) =>{
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i][searchKey] === nameKey) {
          return  {
            idx: i,
            value: myArray[i],
          }
      }
  }
}


export const sortLabelAlphabetically = (data, sortField) =>{
 return  data.sort(function(a, b) {
    // list sort by alphabetically
    return a[sortField].localeCompare(b[sortField]);
  });
}


export const getUniqueSubcategoryOptions = (rows) => { // local method
  let subcategoryOptions =
    rows !== undefined && rows.length > 0
      ? rows.map(item => {
          return {
            label: `${item.subcategory}`,
            name: item.subcategory
          };
        })
      : [];

  subcategoryOptions = Array.from(
    new Set(subcategoryOptions.map(s => s.label))
  ).map(label => {
    return {
      label: label,
      name: subcategoryOptions.find(s => s.label === label).name
    };
  });

  subcategoryOptions = sortLabelAlphabetically(subcategoryOptions, "label");

  subcategoryOptions.unshift({
    label: "All Subcategory",
    name: "All Subcategory"
  });

  return subcategoryOptions;
};

export const arrayEquals = (a, b) =>{
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}
