import {
  AUTHENTICATE_USER_SUCCESS,
  SIGN_IN_USER,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCSESS,
  RESET_ACCOUNT_PASSWORD_SUCCESS,
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  SET_POI_NETWORK_AUTH,
  LOG_OUT,
} from '../constants';

import { getCredentials } from '../../utilities/localStorage';
import { decodeToken } from '../../utilities/helpers';
import { CONFIG } from '../../config';

const storedCredentials = getCredentials();
const hostURL = () => {
  switch (true) {
    case window.location.href.indexOf('localhost') > -1:
      return CONFIG.API_DEV_DOMAIN;
    case window.location.href.indexOf('-dev') > -1:
      return CONFIG.API_DEV_DOMAIN;
    case window.location.href.indexOf('-staging') > -1 || window.location.href.indexOf('-demo') > -1:
      return CONFIG.API_STAGE_DOMAIN;
    default:
      return CONFIG.API_PROD_DOMAIM;
  }
};

const poiServerURL = () =>{
  switch (true) {
    case window.location.href.indexOf('localhost') > -1:
      return CONFIG.POI_API_DOMAIN_LOCAL;
    case window.location.href.indexOf('-dev') > -1:
      return CONFIG.POI_API_DOMAIN_DEV;
    case window.location.href.indexOf('-staging') > -1 || window.location.href.indexOf('-demo') > -1:
      return CONFIG.POI_API_DOMAIN_STAGE;
    default:
      return CONFIG.POI_API_DOMAIN_PROD;
  }
}



const initialState = {
  authenticationInProgress: false,
  resetPasswordInProgress: false,
  resetPasswordSuccess: false,
  isAuthenticated: storedCredentials.credentialsPresent,
  authenticationFailed: false,
  server: hostURL(),
  token: storedCredentials.token || null,
  firstName: storedCredentials.firstName,
  tokenPayload: storedCredentials.token ? decodeToken(storedCredentials.token) : null,
  currentAccountIndex :  Number(storedCredentials.currentAccountIndex) || 0,
  poiServer: poiServerURL(),
  LIPToken: storedCredentials.LIPToken || null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        tokenPayload: decodeToken(action.LIPToken),
        isAuthenticated: true,
        authenticationInProgress: false,
        LIPToken: action.LIPToken,
        resetPasswordSuccess: false,
        currentAccountIndex: action.selectedIdx,
        firstName: action.firstName,
      };
    case RESET_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      };
    case UPDATE_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
      };
    case SIGN_IN_USER:
      return {
        ...state,
        authenticationInProgress: true,
      }
    case SIGN_IN_SUCCSESS:
      return {
        ...state,
      }
    case SIGN_IN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        authenticationInProgress: false,
        resetPasswordSuccess: false,
      };
    case SET_POI_NETWORK_AUTH:
      return{
        ...state,
        isAuthenticated: true,
        authenticationInProgress: false,
        token:  action.token
      };
    case LOG_OUT:
      return {
        ...state,
        isAuthenticated: false,
        authenticationInProgress: false,
        token: null,
        firstName: null,
        tokenPayload: null,
      };
    default:
      return state;
  }
};

export default auth;
