import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    FETCH_SUBCATEGORY_ORGANISATION,
    FETCH_SUBCATEGORY_ORGANISATION_ERROR,
    SET_SUBCATEGORY_ORGANISATION
} from '../../constants';



const fetchSubcategoryOrganisation = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_SUBCATEGORY_ORGANISATION),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/organisation-list-by-subcategories`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        crossDomain: true,
        body: action.data,
        method: 'POST',
      }).pipe(
        map(response => {
          return {
            type: SET_SUBCATEGORY_ORGANISATION,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_SUBCATEGORY_ORGANISATION_ERROR,
            error,
          })
        )
      )
    )
  );

export default fetchSubcategoryOrganisation;
