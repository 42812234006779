import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import LoadingContainer from "../components/LoadingContainer";
import { fetchClientList, fetchSDKList} from "../store/actions/sdk-actions";
import SDKItems from "../components/SDKItems";
import ListSidebar from "./ListSidebar";

const SDKConfiguration = () => {
  const dispatch = useDispatch();

  const clientListData = useSelector(
    state => state.sdkConfiguration.clientListData
  );

  const sdkList = useSelector(
    state => state.sdkConfiguration.sdkList
  );


  useEffect(() => {
    dispatch(fetchClientList("sdk"));
  }, []);

  useEffect(() => {

    if(clientListData.length > 0){
      dispatch(fetchSDKList({"appIds": clientListData[0].appIds}));
    }

  }, [clientListData]);



  const [clientNow, setClient] = useState(0);

  const handleClientChange = (e) => { 
    setClient(e)  
    dispatch(fetchSDKList({"appIds": clientListData[e].appIds}));
  }

  const fetchLatestSDKList = () =>{
    dispatch(fetchSDKList({"appIds": clientListData[clientNow].appIds}));
  }

  return (
    <div className="FootfallInsights">
      <Header />
      <ListSidebar 
        title="Collects Clients" 
        data={clientListData}
        clientNow={clientNow}
        handleClientChange={handleClientChange}
        page="SDK"
      />
      <main className="FootfallInsights_main">

        <div>
          <LoadingContainer loading={false}>
            <div style={{ width: "100%", minHeight: "400px" }}>
              <SDKItems
                data={ sdkList }
                clientId= {clientListData.length > 0 ?  clientListData[clientNow].clientId  : null }
                fetchLatestSDKList= { fetchLatestSDKList }
              />

            </div>
          </LoadingContainer>
        </div>
      </main>
    </div>
  );
};
export default SDKConfiguration;
