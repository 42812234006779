import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import {
  UPDATE_CATEGORY_LIST,
  UPDATE_CATEGORY_LIST_SUCCESS,
  UPDATE_CATEGORY_LIST_FAIL
} from "../../constants";

import {
  toastSuccess,
  toastpending,
  toastFail
} from "../../../utilities/epic-helpers";

let toastId = "";

const updateCategoryList = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_CATEGORY_LIST),
    switchMap(action => {
      toastId = toastpending("Updating Category list...", "updateCategorylist");

      return ajax({
        url: `${state$.value.auth.poiServer}/report-permissions/update-client-categories-array`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          "Content-Type": "application/json"
        },
        body: action.data,
        responseType: "json",
        crossDomain: true,
        method: "POST"
      }).pipe(
        map(response => {
          
          toastSuccess("Updated Success", toastId);
          return {
            type: UPDATE_CATEGORY_LIST_SUCCESS,
            data: response.response
          };
        }),
        catchError(error => {
            toastFail("Updated Failed", toastId);

        })
      );
    })
  );

export default updateCategoryList;
