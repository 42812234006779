import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
    FETCH_SDK_LIST,
    FETCH_SDK_LIST_ERROR,
    SET_SDK_LIST
} from '../../constants';

const sdkList = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_SDK_LIST),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/sdk_list`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        body: action.data,
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {
          console.log('SDK list response', response);
          return {
            type: SET_SDK_LIST,
            data: response.response.data,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_SDK_LIST_ERROR,
            error,
          })
        )
      )
    )
  );

export default sdkList;
