import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Select from "react-select";

const AddNewSDK = props => {
  const [generalContent, setGeneralContent] = useState({
    name: "",
    packageName: "",
    description: "",
    appUsesBluedot: false,
    nextRuleUpdateIntervalFormatted: "12:00",
    SDKtype: null
  });
  console.log(generalContent)
  const [androidSDKContent, setAndroidContent] = useState({
    androidEnabled: true,
    batchSize: 5,
    distanceIntervalMeters: 200,
    distanceFilterMeters: 50,
    discoveryMode: 2,
    enableSentry: false,
    enableMotionActivity: false,
    minSpeedKph: 40,
    maxSpeedKph: 90,
    suppressCheckConfigMins: 0,
    timeIntervalMins: 15,
  
  });

  const [iosSDKContent, setiOSContent] = useState({
    batchSize: 5,
    distanceIntervalMeters: 200,
    distanceFilterMeters: 50,
    discoveryMode: 2,
    enableSentry: false,
    enableMotionActivity: false,
    minSpeedKph: 40,
    maxSpeedKph: 90,
    suppressCheckConfigMins: 0,
    timeIntervalMins: 15,
 
  });

  const handleChange = e => {
    const value = e.target.value;
    console.log(e)
    if (e.target.name === "generalContent") {
      setGeneralContent({
        ...generalContent,
        [e.target.id]: value
      });
    } else {
      generalContent.SDKtype.value === "iOS"
        ? setiOSContent({
            ...iosSDKContent,
            [e.target.id]: value
          })
        : setAndroidContent({
            ...androidSDKContent,
            [e.target.id]: value
          });
    }
  };

  const handleCheckBoxChange = e => {
    if (e.target.name === "generalContent") {
      setGeneralContent({
        ...generalContent,
        [e.target.id]: !generalContent[e.target.id]
      });
    } else {
      generalContent.SDKtype.value === "iOS"
        ? setiOSContent({
            ...iosSDKContent,
            [e.target.id]: !iosSDKContent[e.target.id]
          })
        : setAndroidContent({
            ...androidSDKContent,
            [e.target.id]: !androidSDKContent[e.target.id]
          });
    }
  };

  const generalInput = (fieldName, contentType) => {
    switch (fieldName) {
      case "shouldCollectData":
      case "appUsesBluedot":
      case "androidEnabled":
      case "iosEnabled":
      case "enableMotionActivity":
      case "enableSentry":
        return (
          <input
            type="checkbox"
            checked={
              contentType === "generalContent"
                ? Boolean(generalContent[fieldName])
                : generalContent.SDKtype.value === "iOS"
                ? Boolean(iosSDKContent[fieldName])
                : Boolean(androidSDKContent[fieldName])
            }
            name={contentType}
            id={fieldName}
            onChange={handleCheckBoxChange}
          ></input>
        );
      case "description":
        return (
          <textarea
            style={{ width: "100%", height: "auto" }}
            name={contentType}
            id={fieldName}
            value={
              contentType === "generalContent"
                ? generalContent[fieldName]
                : generalContent.SDKtype.value === "iOS"
                ? iosSDKContent[fieldName]
                : androidSDKContent[fieldName]
            }
            onChange={handleChange}
          ></textarea>
        );
      case "relevance":
      case "locationPingIntervalMS":
      case "distanceFilterMeters":
      case "distanceFilter":
      case "discoveryMode":
      case "locationPingInterval":
      case "distanceIntervalMeters":
      case "timeIntervalMins":
      case "maxSpeedKph":
      case "minSpeedKph":
      case "batchSize":
        return (
          <input
            type="number"
            name={contentType}
            id={fieldName}
            value={
              contentType === "generalContent"
                ? generalContent[fieldName]
                : generalContent.SDKtype.value === "iOS"
                ? iosSDKContent[fieldName]
                : androidSDKContent[fieldName]
            }
            onChange={handleChange}
          ></input>
        );
      case "SDKtype":
        return (
          <Select
            inputId="criteria-modal-select-organisation"
            className="CriteriaModal_search-organisation-input"
            style={{ width: "5rem" }}
            classNamePrefix="CriteriaModal_search-organisation-input_"
            placeholder="Type to search"
            isDisabled={false}
            isRtl={false}
            isSearchable
            name={contentType}
            options={[
              {
                label: "Android",
                value: "Android"
              },
              {
                label: "iOS",
                value: "iOS"
              }
            ]}
            onChange={event =>{
              console.log(event)
              setGeneralContent({
                ...generalContent,
                SDKtype: event
              })
            }

            }
            value={generalContent.SDKtype}
          />
        );
      default:
        return (
          <input
            style={{ width: "50%", marginTop: "0.5rem" }}
            name={contentType}
            id={fieldName}
            value={
              contentType === "generalContent"
                ? generalContent[fieldName]
                : generalContent.SDKtype.value === "iOS"
                ? iosSDKContent[fieldName]
                : androidSDKContent[fieldName]
            }
            onChange={handleChange}
          ></input>
        );
    }
  };

  const inputCheck = () =>{

    return generalContent.name === "" 
    || generalContent.packageName === ""
    || generalContent.SDKtype === null
  }

  const handleSubmit = () => {
    let data;
    generalContent.SDKtype.value === "iOS"
    ? data = { ...generalContent, ...iosSDKContent }
    : data = { ...generalContent, ...androidSDKContent }

    props.createNewApp(data)
  };

  return (
    <div className="SDKItem_modal">
      <h4 className="SDKItem_modal_titleArea">Create New App</h4>

      <div style={{ borderRight: "1px #d6dee9 solid", paddingRight: "1rem" }}>
        <h5>General App Fields</h5>
        {Object.keys(generalContent).map(function(key, index) {
          return (
            <span className="field" key={key}>
              <h3 className="field-name">{key}</h3>
              {generalInput(key, "generalContent")}
            </span>
          );
        })}
      </div>

      <div>
        {generalContent.SDKtype === null ? (
          <h5>Please select SDK type</h5>
        ) : (
          <React.Fragment>
            <h5>{`Current ${generalContent.SDKtype.value === "iOS" ? `iOS`: `Android`} SDK Fields`}</h5>
            {Object.keys(generalContent.SDKtype.value === "iOS" ? iosSDKContent : androidSDKContent).map(function(key, index) {
              return (
                <span className="field" key={key}>
                  <h3 className="field-name">{key}</h3>
                  {generalInput(key, "SDKContent")}
                </span>
              );
            })}
          </React.Fragment>
        )}
      </div>

      <div className="SDKItem_modal_">
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={handleSubmit}
          disabled={inputCheck()}
        >
          Create SDK
        </Button>
      </div>
    </div>
  );
};

export default AddNewSDK;
