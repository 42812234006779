import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_TAXONOMY_SUCCESS,
  UPDATE_ORGANISATION,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAIL,
  UPDATE_MOBILE_APP,
  UPDATE_MOBILE_APP_SUCCESS,
  UPDATE_MOBILE_APP_FAIL
} from "../constants";

const initialState = {
  organizationsList: [],
  organizationsListLoaded: true,
  taxonomyList: [],
  updatedOne: {},
  isUpdating: false,
  mobileAppList: [],
  willUpdateMobileApp: {},
};

const organizationsConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizationsListLoaded: false
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizationsList: action.data,
        organizationsListLoaded: true
      };
    case FETCH_TAXONOMY_SUCCESS:
      return {
        ...state,
        taxonomyList: action.data
      };
    case UPDATE_ORGANISATION:
      return {
        ...state,
        isUpdating: true
      };
    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        updatedOne: action.data,
        isUpdating: false
      };
    case UPDATE_ORGANISATION_FAIL:
      return {
        ...state,
        isUpdating: false
      };
    case UPDATE_MOBILE_APP:
      return {
        ...state,
        isUpdating: true
      };
    case UPDATE_MOBILE_APP_SUCCESS:
      return {
        ...state,
        willUpdateMobileApp: action.data,
        isUpdating: false
      };
    case UPDATE_MOBILE_APP_FAIL:
      return {
        ...state,
        isUpdating: false
      };
    default:
      return state;
  }
};

export default organizationsConfiguration;
