import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Modal } from "reactstrap";

// imports components
import UpdateOrganisations from "../components/UpdateOrganisaitons";

import MobileAppComponent from '../components/updateOrganisation/MobileAppComponent';
import Header from "./Header";
//  actions
import {
  fetchOrganizations,
  fetchTaxonomy,
  updateOrganisation
} from "../store/actions/organizations-action";

const DigitalOrganizations = () => {
  const dispatch = useDispatch();

  const organizationsList = useSelector(
    state => state.organizationsConfiguration.organizationsList
  );

  const taxonomyList = useSelector(
    state => state.organizationsConfiguration.taxonomyList
  );

  const updatedOne = useSelector(
    state => state.organizationsConfiguration.updatedOne
  );
  const isUpdating = useSelector(
    state => state.organizationsConfiguration.isUpdating
  );

  const [currentOrganisationIndex, setCurrentOrganisationIndex] = useState(
    null
  );

  const [organizationsListState, setOrganizationsListState] = useState(
    organizationsList
  );

  const [isOpen, toggleModal] = useState(false);

  const [expandingRows, setExpandingRows] = useState([]);


  const handleToggleModal = e => {
    const currentIndex = e.currentTarget.value;
    setCurrentOrganisationIndex(currentIndex);
    toggleModal(!isOpen);
  };

  const handleUpdateButton = data => {
    dispatch(
      updateOrganisation(
        data,
        organizationsListState[currentOrganisationIndex].id
      )
    );
  };


  useEffect(() => {
    dispatch(fetchOrganizations());
    dispatch(fetchTaxonomy());
  }, []);

  useEffect(() => {
    setOrganizationsListState(organizationsList);
  }, [organizationsList]);

  useEffect(()=>{
    setExpandingRows(expandingRows);
    console.log(expandingRows)
  }, [expandingRows])

  useEffect(() => {
    let willUpdateIndex = organizationsListState.findIndex(
      p => p.id == updatedOne.id
    );
    let newOrganizationsList = organizationsListState.concat();
    newOrganizationsList[willUpdateIndex] = updatedOne;

    setOrganizationsListState(newOrganizationsList);

    if (isUpdating === false) {
      toggleModal(false);
    }
  }, [updatedOne, isUpdating]);


  //Table columns
  const columns = [
    {
      name: "id",
      label: <strong>ID</strong>,
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "name",
      label: <strong>Name</strong>,
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "country",
      label: <strong>Country</strong>,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "category",
      label: <strong>Category</strong>,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "subcategory",
      label: <strong>Subcategory</strong>,
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "",
      label: <strong></strong>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="outlined"
                color="primary"
                style={{ display: "inline-block" }}
                value={tableMeta.rowIndex}
                onClick={e => handleToggleModal(e)}
              >
                Edit
              </Button>
            </div>
          );
        }
      }
    }
  ];

  // Table Options
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    responsive: "scrollFullHeight",
    customFilterDialogFooter: () => <FilterDialogFooter />,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <MobileAppComponent
          rowData={rowData}
          rowMeta={rowMeta}
          organizationsListState={organizationsListState}
        />
      );
    },
    rowsExpanded: expandingRows,
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
      
      const indexes = allRowsExpanded.map(item => item.dataIndex);
      setExpandingRows(indexes)
    }
  };

  // Table Custom theme
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableHeadCell: {
          fixedHeader: {
            backgroundColor: "#C2DFFF"
          }
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#C2DFFF"
          }
        },
        MuiTable: {
          root: {
            marginTop: 10
          }
        }
      }
    });

  return (
    <div>
      <Header />
      <div style={{ padding: 20 }}>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Digital Organizations"}
            data={organizationsListState}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
      <Modal
        isOpen={isOpen}
        toggle={handleToggleModal}
        style={{ width: "30rem" }}
        centered
        contentClassName="CriteriaModal_wrapper"
        backdropClassName="CriteriaModal_background"
      >
        <UpdateOrganisations
          currentOrganization={organizationsListState[currentOrganisationIndex]}
          taxonomyList={taxonomyList}
          currentOrganisationIndex={currentOrganisationIndex}
          handleUpdateButton={handleUpdateButton}
          handleCancelButton={() => toggleModal(false)}
          isUpdating={isUpdating}
        />
      </Modal>

    </div>
  );
};
export default DigitalOrganizations;

export const FilterDialogFooter = () => <Box width={450} />;
