import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    ADD_ORGANISATION_IN_APP,
    ADD_ORGANISATION_IN_APP_SUCCESS,
    ADD_ORGANISATION_IN_APP_FAIL,
} from '../../constants';


const addOrganisation = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ORGANISATION_IN_APP),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/apps/add_organisations/${action.data.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
        crossDomain: true,
        body: action.data,
        method: 'POST',
      }).pipe(
        map(response => {
          return {
            type: ADD_ORGANISATION_IN_APP_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: ADD_ORGANISATION_IN_APP_FAIL,
            error,
          })
        )
      )
        
    )
  );

export default addOrganisation;
