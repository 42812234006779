import {
  FETCH_CLIENT_LIST,
  FETCH_CLIENT_LIST_ERROR,
  SET_CLIENT_LIST,
  EDIT_SDK,
  EDIT_SDK_SUCCESS,
  FETCH_SDK_LIST,
  FETCH_SDK_LIST_ERROR,
  SET_SDK_LIST
} from "../constants";

const initialState = {
  clientListDataLoaded: false,
  clientListData: [],
  sdkList: [],
  sdkListLoaded: false,
  editingSDK: false
};

const sdkConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_LIST:
      return {
        ...state,
        clientListDataLoaded: false
      };
    case FETCH_CLIENT_LIST_ERROR:
      return {
        ...state,
        clientListDataLoaded: false
      };
    case SET_CLIENT_LIST:
      let returnData = [];
      action.pageType === "sdk" || action.pageType === "footfall"
        ? action.data.map(item => {
            if (item.accountType == "collects") {
              returnData.push(item);
            }
          })
        : (returnData = action.data);

      return {
        ...state,
        clientListDataLoaded: true,
        // Only sdk or footfall shows collects account, rest shows all
        clientListData: returnData
      };
    case EDIT_SDK:
      return {
        ...state,
        editingSDK: true
      };
    case EDIT_SDK_SUCCESS:
      return {
        ...state,
        editingSDK: false
      };
    case FETCH_SDK_LIST:
      return {
        ...state,
        sdkListLoaded: false
      };
    case FETCH_SDK_LIST_ERROR:
      return {
        ...state,
        sdkListLoaded: false
      };
    case SET_SDK_LIST:
      return {
        ...state,
        sdkListLoaded: true,
        sdkList: action.data
      };
    default:
      return state;
  }
};

export default sdkConfiguration;
