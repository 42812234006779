import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
    FETCH_REPORT_PERMISSIONS,
    FETCH_REPORT_PERMISSIONS_SUCCESS,
    FETCH_REPORT_PERMISSIONS_FAIL
} from '../../constants';

const fetchReportPermission = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_REPORT_PERMISSIONS),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/report-permissions/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
        crossDomain: true,
        method: 'GET',
      }).pipe(
        map(response => {
          console.log(response)
          return {
            type: FETCH_REPORT_PERMISSIONS_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_REPORT_PERMISSIONS_FAIL,
            error,
          })
        )
      )

    )
  );

export default fetchReportPermission;
