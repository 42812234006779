import {
  createStore,
  compose,
  applyMiddleware
} from 'redux';
import {
  createEpicMiddleware
} from 'redux-observable';

import { routerMiddleware } from 'connected-react-router'

import rootEpic from './epics';
import rootReducer from './reducers';
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();


const middleware = [
  routerMiddleware(history),
  epicMiddleware,
];
const enhancers = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(...middleware), ...enhancers),
);

epicMiddleware.run(rootEpic);

export default store;
