import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    ADD_EXIST_ACCOUNT,
    ADD_EXIST_ACCOUNT_FAIL,
    ADD_EXIST_ACCOUNT_SUCCESS
} from '../../constants';


const addExistAccount = (action$, state$) =>
  action$.pipe(
    ofType(ADD_EXIST_ACCOUNT),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.server}/admin/accounts`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json',
        body: JSON.stringify(action.payload),
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {

          return {
            type: ADD_EXIST_ACCOUNT_SUCCESS,
          };
        }),
        catchError(error =>
          of({
            type: ADD_EXIST_ACCOUNT_FAIL,
            error,
          })
        )
      )
    )
  );

export default addExistAccount;
