import {
    ofType
  } from 'redux-observable';
  
  import {
    ajax
  } from "rxjs/ajax";
  
  import { of } from 'rxjs';
  
  import {
    map,
    switchMap,
    catchError,
  } from 'rxjs/operators';
  
  import {
    GET_POI_NETWORK_AUTH,
    GET_POI_NETWORK_AUTH_FAILURE,
    SET_POI_NETWORK_AUTH
  } from '../constants';


  import { setCredentials } from '../../utilities/localStorage';
  
  
  const poiAuth = (action$, state$) => action$.pipe(
    ofType(GET_POI_NETWORK_AUTH),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/auth/signin`,
        body: {
          username: action.username,
          password: action.password,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'json',
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {

          setCredentials({
            token: response.response.token,
          });
           
          return ({
            type: SET_POI_NETWORK_AUTH,
            token: response.response.token,
          })
        }),
        catchError(error => of({
          type: GET_POI_NETWORK_AUTH_FAILURE,
          error,
        }))
      ),
    )
  );
  
  export default poiAuth;
  