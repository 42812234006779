import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    DOWNLOAD_MANIFEST_FILE,
    DOWNLOAD_MANIFEST_FILE_SUCCESS
} from '../../constants';

import saveAs from 'file-saver';


const downloadManifestFile = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_MANIFEST_FILE),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/download-manifest-file`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'text',
        crossDomain: true,
        body: action.data,
        method: 'POST',
      }).pipe(
        map(response => {
            console.log(response)
            const blob = new Blob(
                [
                  `${response.response}`,
                ],
                { type: 'text/plain' }
              );
              const fileName = 'Manifest file';
              saveAs(blob, fileName);

          return {
            type: DOWNLOAD_MANIFEST_FILE_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>
            console.log(error)
        )
      )
        
    )
  );

export default downloadManifestFile;
