import {
  FETCH_REPORT_PERMISSIONS,
  FETCH_REPORT_PERMISSIONS_SUCCESS,
  FETCH_REPORT_PERMISSIONS_FAIL,
  FETCH_ACTIVATING_ORGS,
  FETCH_ACTIVATING_ORGS_SUCCESS
} from "../constants";

const initialState = {
  reportPermissionData: {},
  reportPermissionDataLoaded: false,
  activatingOrgsData: {
    physicalOrgsArray:[],
    digitalOrgsArray: []
  },
  activatingOrgsDataLoaded: false
};

const reportConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORT_PERMISSIONS:
      return {
        ...state,
        reportPermissionDataLoaded: false
      };
    case FETCH_REPORT_PERMISSIONS_FAIL:
      return {
        ...state,
        reportPermissionDataLoaded: false
      };
    case FETCH_REPORT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        reportPermissionDataLoaded: true,
        reportPermissionData: action.data.findedClient
      };
    case FETCH_ACTIVATING_ORGS:
      return {
        ...state,
        activatingOrgsDataLoaded: false
      };
    case FETCH_ACTIVATING_ORGS_SUCCESS:
      return {
        ...state,
        activatingOrgsDataLoaded: true,
        activatingOrgsData:{
          physicalOrgsArray: action.data.physicalOrgsArray,
          digitalOrgsArray: action.data.digitalOrgsArray
        } 
      };

    default:
      return state;
  }
};

export default reportConfiguration;
