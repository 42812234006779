import React from 'react';
import PropTypes from 'prop-types';

const insertIcon = icon => {
  switch (icon) {
    case 'user':
      return (
        <React.Fragment>
          <path
            d="M47,33.7l-1.3,2.5c6.2,3.3,10.1,9.6,10.2,16.6c-1.8,1.5-9.4,7.4-24,7.4c-14.6,0-22.2-5.9-24-7.4
		c0.1-7,4-13.3,10.2-16.6L17,33.7C9.6,37.6,5.1,45.1,5.2,53.4l0,0.6l0.4,0.4C6,54.7,14,63,32,63s26-8.3,26.4-8.6l0.4-0.4l0-0.6
		C58.9,45.1,54.4,37.6,47,33.7z"
          />
          <path
            d="M32,36.4c8,0,14.8-10.8,14.8-19.7C47.1,8.3,40.4,1.3,32,1c-8.4,0.3-15,7.3-14.8,15.6
		C17.2,25.5,24,36.4,32,36.4z M32,3.8C38.8,4,44.2,9.8,44,16.6v0c0,7.5-5.7,16.9-12,16.9c-6.4,0-12-9.5-12-16.9
		C19.8,9.8,25.2,4,32,3.8z"
          />
        </React.Fragment>
      );
    case 'position-marker':
      return (
        <React.Fragment>
          <path
            d="M31,35.6v17.4h2.4V35.6c10-0.6,18-8.1,18-17.3C51.4,8.8,42.8,1,32.2,1C21.6,1,13,8.8,13,18.3
		C13,27.5,21,35.1,31,35.6z M32.2,3.4c9.3,0,16.8,6.7,16.8,15c0,8.2-7.5,14.9-16.7,14.9h-0.1c-9.2,0-16.7-6.7-16.7-14.9
		C15.4,10.1,22.9,3.4,32.2,3.4z"
          />
          <path
            d="M43.3,45.7l-1.2-0.2l-0.3,2.4l1.2,0.2c9.4,1.3,14.3,3.9,14.3,5.9c0,2.8-9.5,6.6-25.1,6.6
		c-15.5,0-25.1-3.8-25.1-6.6c0-2,4.9-4.6,14.3-5.9l1.2-0.2l-0.3-2.4l-1.2,0.2C16.2,46.4,4.7,48.6,4.7,54c0,5.8,14.2,9,27.5,9
		c13.3,0,27.5-3.2,27.5-9C59.7,50.2,53.5,47.1,43.3,45.7z"
          />
        </React.Fragment>
      );
    case 'device':
      return (
        <path
          d="M57.4,15.4C57.3,8.1,51.7,2.1,44.5,1.1V1h-2.2v0C34.4,1.2,28,7.5,27.8,15.4h0v1.4H9.6
	c-1.7,0-3.1,1.4-3.1,3.1v1.6v1.7v29.1v1.7v6.2c0,1.7,1.4,3.1,3.1,3.1h28.8c1.7,0,3.1-1.4,3.1-3.1v-6.2v-1.7V30.3c0.3,0,0.5,0,0.8,0
	v0h2.2v-0.1c6.6-0.9,11.9-6.1,12.8-12.6h0.1L57.4,15.4L57.4,15.4z M8.2,19.8c0-0.8,0.6-1.4,1.4-1.4h18.5c0.2,1,0.5,2,0.9,2.9H8.2
	V19.8z M39.8,59.9c0,0.8-0.6,1.4-1.4,1.4H9.6c-0.8,0-1.4-0.6-1.4-1.4v-6.2h31.6V59.9z M39.8,52.1H8.2V23.1h21.6
	c2.1,3.6,5.7,6.2,10,7V52.1z M39.8,27.8c-3-0.7-5.5-2.4-7.3-4.7h7.3V27.8z M31.5,21.4c-0.5-0.9-0.8-1.9-1.1-2.9h7.9
	c0.4,0.6,0.9,1.1,1.5,1.5v1.4H31.5z M39.7,15.7c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9
	c-0.7,0-1.4-0.3-1.9-0.7c-0.1-0.1-0.2-0.2-0.3-0.3C40,17,39.7,16.4,39.7,15.7z M55.1,17.6c-0.8,5.3-5.1,9.5-10.5,10.4v-3h-2.2v3.2
	c-0.3,0-0.5,0-0.8-0.1v-5v-1.7v-0.7c0.4,0.1,0.7,0.1,1.1,0.1c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1c-2.8,0-5.1,2.3-5.1,5.1
	c0,0.4,0,0.7,0.1,1.1h-4.4v-1.4h-3.2c0.2-6.6,5.6-12,12.3-12.1v3.2h2.2v-3c5.9,0.9,10.5,5.9,10.7,12H52v2.2H55.1z"
        />
      );
    case 'home':
      return (
        <path
          d="M12.5,60.6c-1.1,0-2.2-0.4-3-1.2c-0.8-0.8-1.3-1.9-1.3-3V35.8l-1.5,1.3L1,30.9L32,3.4l31,27.5
	l-5.8,6.3l-1.5-1.3v20.5c0,2.4-1.9,4.2-4.3,4.2c0,0,0,0,0,0H35V46.4H29v14.2H12.5C12.6,60.6,12.6,60.6,12.5,60.6z M12.1,27.4v28.9
	c0,0.2,0.3,0.3,0.5,0.5h12.7V42.7h13.5v14.2h12.7c0.3,0,0.5-0.2,0.5-0.5V27.4l5,4.4l0.7-0.7L32,8.4L6.4,31.1l0.7,0.7L12.1,27.4z
	 M38.7,37H25.3V23.7l13.5,0V37z M29,33.2H35v-5.7l-5.9,0V33.2z"
        />
      );
    case 'pins':
      return (
        <React.Fragment>
          <path
            d="M31.1,13c-0.1,0-0.3,0-0.4,0C20.5,13,12.1,21.3,12,31.5c0,13.8,17.3,29.1,18.1,29.7l0.8,0.7l0.8-0.7
		c0.7-0.6,18.1-15.9,18.1-29.7v0C49.7,21.3,41.3,13,31.1,13z M30.9,58.6c-3.4-3.2-16.4-16-16.4-27.1c0.1-8.9,7.4-16.1,16.2-16.1
		c0.1,0,0.1,0,0.2,0c9-0.1,16.3,7.1,16.4,16C47.3,42.6,34.3,55.5,30.9,58.6z"
          />
          <path
            d="M11.1,5c4.5-1.3,9.4,0.3,12.2,4.1l0.7,1l2-1.5l-0.7-1C21.9,3,15.9,1,10.5,2.6C5,3.9,1.1,8.8,1,14.5
		c0.2,5.2,2.3,10.1,6,13.8l0.9,0.9l1.8-1.7l-0.9-0.9c-3.2-3.3-5.1-7.6-5.3-12.1C3.6,10,6.7,6.1,11.1,5z"
          />
          <path
            d="M53.6,2.6C48.1,1,42.1,3,38.7,7.5l-0.7,1l2,1.5l0.7-1c2.8-3.7,7.7-5.4,12.2-4c4.4,1.1,7.5,5,7.6,9.4
		c-0.2,4.6-2.1,8.9-5.2,12.2l-0.9,0.9l1.8,1.7l0.9-0.9c3.6-3.7,5.7-8.6,5.9-13.9C62.9,8.8,59,3.9,53.6,2.6z"
          />
          <path
            d="M29.8,24c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7c3.7,0,6.8-3,6.8-6.7C36.5,27,33.5,24,29.8,24z M29.8,35
		c-2.4,0-4.3-1.9-4.3-4.2c0-2.3,1.9-4.2,4.3-4.2c2.4,0,4.3,1.9,4.3,4.2C34.1,33.1,32.1,35,29.8,35z"
          />
        </React.Fragment>
      );
    case 'pin-search':
      return (
        <React.Fragment>
          <path
            d="M31,55.4c-1.6,1.8-2.9,3.2-3.7,4.1C23.2,55.3,6.4,36.9,6.4,24.3c0-5.5,2.1-10.7,5.9-14.7
		c3.9-3.9,9.1-6.1,14.6-6.2h0.4C38.6,3.3,48.1,12.5,48.2,24v0.4c0,0.9-0.1,1.8-0.2,2.7l-0.2,1.2l2.4,0.4l0.2-1.2
		c0.2-1,0.3-2.1,0.3-3.1v-0.4C50.5,11.2,40,0.8,27.3,1h-0.4c-6.2,0.1-12,2.5-16.3,6.9c-4.3,4.4-6.7,10.2-6.6,16.4
		c0,15.2,21.6,36.9,22.5,37.8l0.9,0.9l0.9-0.9c0.1-0.1,2-2,4.7-5.1l0.8-0.9l-1.8-1.6L31,55.4z"
          />
          <path
            d="M18.7,24.4c0,4.7,3.9,8.6,8.6,8.6c4.7,0,8.6-3.9,8.6-8.6c0-4.7-3.9-8.6-8.6-8.6C22.5,15.8,18.7,19.6,18.7,24.4
		z M33.4,24.4c0,3.4-2.8,6.1-6.1,6.1c-3.4,0-6.1-2.8-6.1-6.1c0-3.4,2.8-6.1,6.1-6.1C30.7,18.2,33.4,21,33.4,24.4z"
          />
          <path
            d="M60.1,58.8L52.3,51c2-2,3.2-4.7,3.2-7.8c0-6.1-5-11.1-11.1-11.1c-6.1,0-11.1,5-11.1,11.1
		c0,6.1,5,11.1,11.1,11.1c2.2,0,4.2-0.6,5.9-1.7l7.9,7.9L60.1,58.8z M35.9,43.2c0-4.7,3.9-8.6,8.6-8.6c4.7,0,8.6,3.9,8.6,8.6
		c0,4.7-3.9,8.6-8.6,8.6C39.7,51.8,35.9,48,35.9,43.2z"
          />
        </React.Fragment>
      );
    case 'back-arrow':
      return (
        <polygon points="63,30.7 6.1,30.7 23.5,13.3 21.6,11.4 1,32 21.6,52.6 23.5,50.7 6.1,33.3 63,33.3 " />
      );
    case 'mover-up':
      return (
        <path
          d="M61.4,11.3H45.5c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h11.3h0.2L39.5,39.9L17,31
	c-0.7-0.3-1.4,0-1.9,0.5L1.3,50.2c-0.5,0.7-0.4,1.7,0.3,2.2c0.3,0.2,0.6,0.3,0.9,0.3c0.5,0,1-0.2,1.3-0.6l13.1-17.6l22.5,8.9
	c0.7,0.3,1.5,0,1.9-0.6l18.4-26.8v12.9c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V12.9C63,12,62.3,11.3,61.4,11.3z"
        />
      );
    case 'mover-down':
      return (
        <path
          d="M61.4,33.6c-0.9,0-1.6,0.7-1.6,1.6v12.9L41.4,21.3c-0.4-0.6-1.2-0.8-1.9-0.6l-22.5,8.9
	L3.9,11.9c-0.5-0.7-1.5-0.9-2.2-0.3c-0.7,0.5-0.9,1.5-0.3,2.2l13.8,18.6c0.4,0.6,1.2,0.8,1.9,0.5l22.5-8.8l17.5,25.4H45.5
	c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6V35.2C63,34.3,62.3,33.6,61.4,33.6z"
        />
      );
    case 'mover-same':
      return (
        <path
          d="M60.6,31.3L49,19.6c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.7,1.6,0,2.2l9.2,9.1L4.9,31
	c-2.4,0-2.6,2.9-0.4,2.9l52.1,0l-8.7,8.2c-0.6,0.6-0.6,1.6,0,2.3s1.6,0.6,2.3,0l10.4-10.8C61.2,32.9,61.2,31.9,60.6,31.3z"
        />
      );
    case 'left-arrow':
      return (
        <path d="M40.9,21l-11,11l11,11l-3.4,3.4L23.1,32l14.3-14.3L40.9,21z" />
      );
    case 'right-arrow':
      return (
        <path d="M26.5,17.7L40.9,32L26.5,46.3L23.1,43l11-11l-11-11L26.5,17.7z" />
      );
    case 'download':
      return (
        <path
          d="M56.1,32v24.1H7.9V32H1v24.1C1,59.9,4.1,63,7.9,63h48.2c3.8,0,6.9-3.1,6.9-6.9V32H56.1z M35.4,34.3l8.9-8.9
	l4.9,4.9L32,47.5L14.8,30.3l4.9-4.9l8.9,8.9V1h6.9V34.3z"
        />
      );
    case 'logout':
      return (
        <path
          id="logout"
          d="M25.4,44.4l4.9,4.9L47.5,32L30.3,14.8l-4.9,4.9l8.9,8.9H1v6.9h33.3L25.4,44.4z M56.1,1H7.9C4.1,1,1,4.1,1,7.9
        v13.8h6.9V7.9h48.2v48.2H7.9V42.3H1v13.8C1,59.9,4.1,63,7.9,63h48.2c3.8,0,6.9-3.1,6.9-6.9V7.9C63,4.1,59.9,1,56.1,1z"
        />
      );
    case 'account':
      return (
        <path
          d="M10.1,10.1C16.2,4,23.5,1,32,1s15.8,3,21.9,9.1S63,23.5,63,32s-3,15.8-9.1,21.9S40.5,63,32,63
	s-15.8-3-21.9-9.1S1,40.5,1,32S4,16.2,10.1,10.1z M32,54.4c7.9,0,14.1-3.3,18.6-10c-0.1-2.7-2.3-5-6.5-6.8s-8.3-2.8-12.1-2.8
	s-7.8,0.9-12.1,2.7c-4.3,1.8-6.5,4.1-6.5,6.9C17.9,51.1,24.1,54.4,32,54.4z M38.5,13.1c-1.8-1.8-4-2.8-6.5-2.8s-4.7,0.9-6.5,2.8
	s-2.8,4-2.8,6.5s0.9,4.7,2.8,6.5s4,2.8,6.5,2.8s4.7-0.9,6.5-2.8c1.8-1.8,2.8-4,2.8-6.5S40.4,14.9,38.5,13.1z"
        />
      );
    case 'child':
      return (
        <path
          d="M56.2,44.1c2.4,2.2,3.6,4.9,3.6,8.2c0,3-1,5.6-3.1,7.6S52.1,63,49.1,63c-2.7,0-5.1-0.9-7.1-2.7
        c-2-1.8-3.3-4-3.6-6.6h-6.5c-0.4,2.6-1.6,4.8-3.6,6.6c-2,1.8-4.3,2.7-7.1,2.7c-3,0-5.6-1-7.7-3.1s-3.2-4.6-3.2-7.6
        c0-4.3,1.9-7.5,5.7-9.6L9.4,28.9H2.5v-6.3h10.8l2.9,6.3h45.3C61.5,34.5,59.7,39.5,56.2,44.1z M17.9,55.5c0.9,0.9,2,1.4,3.3,1.4
        c1.3,0,2.4-0.5,3.3-1.4s1.4-2,1.4-3.3c0-1.3-0.5-2.4-1.4-3.3s-2-1.4-3.3-1.4c-1.3,0-2.4,0.5-3.3,1.4s-1.4,2-1.4,3.3
        C16.5,53.5,17,54.6,17.9,55.5z M36.6,1c6.9,0,12.8,2.4,17.6,7.3s7.3,10.7,7.3,17.5H36.6V1z M45.8,55.5c0.9,0.9,2,1.4,3.3,1.4
        c1.3,0,2.4-0.5,3.3-1.4s1.4-2,1.4-3.3c0-1.3-0.5-2.4-1.4-3.3s-2-1.4-3.3-1.4c-1.3,0-2.4,0.5-3.3,1.4s-1.4,2-1.4,3.3
        C44.4,53.5,44.9,54.6,45.8,55.5z"
        />
      );
    case 'edit':
      return (
        <path
          d="M10.5,44.5l26.4-26.4l9,9L19.5,53.5h-9V44.5z M52.8,20.1l-4.4,4.4l-9-9l4.4-4.4c0.4-0.4,1-0.7,1.7-0.7
      c0.7,0,1.2,0.2,1.7,0.7l5.6,5.6c0.4,0.4,0.7,1,0.7,1.7C53.5,19.1,53.3,19.7,52.8,20.1z"
        />
      );
    case 'delete':
      return (
        <path
          d="M40.4,12.8h8.3v4.8H15.3v-4.8h8.3l2.5-2.4h11.9L40.4,12.8z M17.7,48.7V20h28.7v28.7c0,1.3-0.5,2.4-1.5,3.4
        c-1,1-2.1,1.5-3.4,1.5h-19c-1.3,0-2.4-0.5-3.4-1.5C18.1,51.1,17.7,50,17.7,48.7z M23.5,31.7l5.2,5.2l-5,5l3.4,3.4l5-5l5,5l3.4-3.4
        l-5-5l5-5.2L37,28.3l-5,5.2l-5-5.2L23.5,31.7z"
        />
      );
    case 'info':
      return (
        <path
          d="M15,15c4.7-4.7,10.4-7.1,17-7.1s12.3,2.3,17,7.1s7.1,10.4,7.1,17S53.8,44.3,49,49s-10.4,7.1-17,7.1
        S19.7,53.8,15,49S7.9,38.6,7.9,32S10.2,19.7,15,15z M34.4,24.8v-4.9h-4.7v4.9C29.7,24.8,34.4,24.8,34.4,24.8z M34.4,44.1V29.6h-4.7
        V44L34.4,44.1L34.4,44.1z"
        />
      );
      case 'cancel':
        return (
          <path
            d="M48.7,18.7L35.4,32l13.3,13.3l-3.4,3.4L32,35.4L18.7,48.7l-3.4-3.4L28.6,32L15.3,18.7l3.4-3.4L32,28.6
      l13.3-13.3L48.7,18.7z"
          />
        );
      case 'switch':
        return(
            <path d="M16 3h5v5M4 20L20.2 3.8M21 16v5h-5M15 15l5.1 5.1M4 4l5 5"/>
        )

    default:
      return (
        <React.Fragment>
          <path
            d="M32.3,1c-0.2,0-0.3,0-0.5,0C18.8,1,8.2,11.5,8.1,24.5C8.1,42,30,61.3,31,62.1l1,0.9l1-0.9
		C34,61.3,55.9,42,55.9,24.5v0C55.8,11.5,45.2,1,32.3,1z M32,58.8c-4.3-4-20.8-20.3-20.8-34.3c0.1-11.3,9.3-20.3,20.6-20.3
		c0.1,0,0.1,0,0.2,0c11.3-0.2,20.6,9,20.7,20.3C52.8,38.5,36.3,54.8,32,58.8z"
          />
          <path
            d="M30.6,14.9c-4.7,0-8.5,3.8-8.5,8.5c0,4.7,3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5
		C39.2,18.7,35.3,14.9,30.6,14.9z M30.6,28.8c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4
		C36,26.4,33.6,28.8,30.6,28.8z"
          />
        </React.Fragment>
      );
  }
};

const SVGIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    className={`SVGIcon${props.className ? ` ${props.className}` : ''}`}
  >
    {insertIcon(props.icon)}
  </svg>
);

SVGIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SVGIcon.defaultProps = {
  className: undefined,
};

export default SVGIcon;
