import React from 'react';
import PropTypes from 'prop-types';
// import Button from './Button';


const SelectionNotAvailable = ({ text, buttonText, wholePage = "false" }) => (
  <div className= {`SelectionNotAvailable container ${wholePage === true && 'content-card' }`}>
    <div className="SelectionNotAvailable_cell">
      <h3 className="SelectionNotAvailable_title">{text}</h3>

      <p className="SelectionNotAvailable_subtitle">{buttonText}</p>
    </div>
  </div>
);

SelectionNotAvailable.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  // openCriteriaModal: PropTypes.func.isRequired,
};

export default SelectionNotAvailable;
