import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import { fetchAppList } from "../store/actions/footfall-actions";
import { fetchClientList } from "../store/actions/sdk-actions";
import ExpandSidebar from './ExpandSidebar'
import OrganisationList from './OrganisationList'


const FootfallConfiguration = () => {

  const clientListData = useSelector(
    state => state.sdkConfiguration.clientListData
  );
  const [appIdNow, setAppId] = useState(null);
  const [clientAppsNow, setClientAppsNow] = useState(null);
  const [clientId , setClientId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
      fetchAppData();
      dispatch(fetchClientList("footfall"));
  }, []);

  const fetchAppData = async () => await dispatch(fetchAppList());

  const handleClientChange = (appid, appids, clientId) => {
    setAppId(appid);
    setClientAppsNow(appids);
    setClientId(clientId)
  }

  return (
    <div className="FootfallInsights">
      <Header />
      <ExpandSidebar
        title="Collects Clients"  
        data={clientListData}
        appIdNow={appIdNow}
        handleClientChange={handleClientChange}
      />

          <OrganisationList 
            appIdNow={appIdNow}
            clientAppsNow={clientAppsNow}
            clientId={clientId}
            clientListData={clientListData}
          />
    </div>
  );
};
export default FootfallConfiguration;
