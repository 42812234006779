import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    FETCH_EXIST_ACCOUNT_LIST,
    FETCH_EXIST_ACCOUNT_LIST_SUCCESS,
    FETCH_EXIST_ACCOUNT_LIST_FAIL
} from '../../constants';


const fetchExistAccount = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_EXIST_ACCOUNT_LIST),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/exist_account_list/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        crossDomain: true,
      }).pipe(
        map(response => {
            console.log(response)
          return {
            type: FETCH_EXIST_ACCOUNT_LIST_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_EXIST_ACCOUNT_LIST_FAIL,
            error,
          })
        )
      )
    )
  );

export default fetchExistAccount;
