import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    CREATE_CLIENT,
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_FAILED
} from '../../constants';


const createClient = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_CLIENT),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/create_client`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        body: action.payload,
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {
            console.log(response)
          return {
            type: CREATE_CLIENT_SUCCESS,
          };
        }),
        catchError(error =>
          of({
            type: CREATE_CLIENT_FAILED,
            error,
          })
        )
      )
    )
  );

export default createClient;
