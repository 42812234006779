import React from "react";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

const FileDownloadButton = props => {
  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const confirmPlistDowloading = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>

        <Button
          variant="outlined"
          color="primary"
          style={{ display: "inline-block" }}
          onClick={confirmPlistDowloading}
          disabled={props.appIdNow === null}
        >
          {props.currentAppOsType === "iOS" ? `Download Plist File` : `Download Manifest file`}
         
        </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div style={{ padding: "1rem" }}>
          <h2 style={{ fontSize: "1.25rem" }}>
            {" "}
            Are you sure you wanna create {props.currentAppOsType === "iOS" ? `Plist` : `Manifest` } file?
          </h2>
          <p style={{ fontSize: "1rem" }}>
          {props.currentAppOsType === "iOS" && `It will activate the iOS data collection too.` }
          </p>
          <Button variant="text" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() =>  props.handleFileDownload()}
          >
            Confirm
          </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default FileDownloadButton;
