import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../node_modules/leaflet/dist/leaflet.css'
import './styles/index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
import { CONFIG } from './config';

// Sentry init
const environment = () => {
  if (window.location.href.indexOf('localhost') > -1){
    return 'Local';
  }else if(window.location.href.indexOf('-dev') > -1){
    return 'Development';
  }else if(window.location.href.indexOf('-staging') > -1){
    return 'Staging';
  }else if (window.location.href.indexOf('-demo') > -1){
    return 'Demo';
  }else{
    return 'Production';
  }
};

environment() !== 'Local' && (
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    environment: environment(),
    integrations: [new Sentry.Integrations.Breadcrumbs({
        console: false
    })]
  })
);


ReactDOM.render(
  React.createElement(App, {}, null),
  document.getElementById('root')
);
registerServiceWorker();
