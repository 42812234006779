import React from 'react';
import PropTypes from 'prop-types';

import Loading from './Loading';

const LoadingContainer = props => (
  <div className={props.className}>
    {props.loading
      ? <Loading />
      :
      <div className="Component_loading">
        {props.children}
      </div>
    }
  </div>
);

LoadingContainer.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

LoadingContainer.defaultProps = {
  loading: false,
  className: undefined,
};

export default LoadingContainer;
