import {
    FETCH_ORGANIZATIONS,
    FETCH_TAXONOMY,
    UPDATE_ORGANISATION,
    UPDATE_MOBILE_APP
  } from "../constants";
  
export const fetchOrganizations =() => ({
  type: FETCH_ORGANIZATIONS
});

export const fetchTaxonomy =() => ({
  type: FETCH_TAXONOMY
});

export const updateOrganisation =(data, clientId) => ({
  type: UPDATE_ORGANISATION,
  data,
  clientId
});

export const updateMobileApp =(data, mobileAppId) => ({
  type: UPDATE_MOBILE_APP,
  data,
  mobileAppId
});



  


  