import React from 'react';

const pathData = 'M48,17.2c-12.7,0-23.1,10.3-23.1,23.1C24.9,54.9,48,79,48,79s23.2-23.5,23.1-38.8C71.1,27.5,60.7,17.2,48,17.2zM48,55.7c-8.5,0-15.4-6.9-15.4-15.4S39.5,24.8,48,24.8s15.4,6.9,15.4,15.4S56.5,55.7,48,55.7z';

const Loading = () => (
  <div className="Loading">
    <div className="Loading_inner">
      <div className="Loading_circle" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 96 96"
        className="Loading_location-pin-shadow"
      >
        <defs>
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="4,2" />
          </filter>
        </defs>
        <path filter="url(#blur)" d={pathData} />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 96 96"
        className="Loading_location-pin"
      >
        <path d={pathData} />
      </svg>
    </div>
  </div>
);

export default Loading;
