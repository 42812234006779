import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    FETCH_ACCOUNT_OVERVIEW,
    FETCH_ACCOUNT_OVERVIEW_ERROR,
    SET_ACCOUNT_OVERVIEW
} from '../../constants';


const fetchAppList = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ACCOUNT_OVERVIEW),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.poiServer}/user_accounts/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {
          return {
            type: SET_ACCOUNT_OVERVIEW,
            data: response.response.data,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_ACCOUNT_OVERVIEW_ERROR,
            error,
          })
        )
      )
    )
  );

export default fetchAppList;
