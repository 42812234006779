import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Select from "react-select";

const CreateClient = (props) => {
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [timezone, setTimezone] = useState(null);
  const [showHomePage, setShowHomePage] = useState(false);
  const [enableAudienceBuilder, setShowAudienceBuilder] = useState(false);
  const [accountType, setAccountType] = useState(null);

  return (
    <div className="SDKItem_modal">
      <h4 className="SDKItem_modal_titleArea">Create Client</h4>

      <span className="field">
        <h3 className="field-name">Name</h3>
        <input
          style={{ width: "100%" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
      </span>

      <span className="field">
        <h3 className="field-name">Country</h3>
        <Select
          inputId="criteria-modal-select-organisation"
          className="CriteriaModal_search-organisation-input"
          style={{ width: "3rem" }}
          classNamePrefix="CriteriaModal_search-organisation-input_"
          placeholder="Type to search"
          isDisabled={false}
          isRtl={false}
          isSearchable
          name="organisation"
          options={[
            {
              label: "AU",
              value: "AU",
            },
            {
              label: "NZ",
              value: "NZ",
            },
          ]}
          value={country}
          onChange={(event) => setCountry(event)}
        />
      </span>

      <span className="field">
        <h3 className="field-name">Time Zone</h3>
        <Select
          inputId="criteria-modal-select-organisation"
          className="CriteriaModal_search-organisation-input"
          style={{ width: "3rem" }}
          classNamePrefix="CriteriaModal_search-organisation-input_"
          placeholder="Type to search"
          isDisabled={false}
          isRtl={false}
          isSearchable
          name="organisation"
          options={[
            {
              label: "Pacific/Auckland",
              value: "Pacific/Auckland",
            },
            {
              label: "Australia/Sydney",
              value: "Australia/Sydney",
            },
          ]}
          value={timezone}
          onChange={(event) => setTimezone(event)}
        />
      </span>

      <span className="field">
        <h3 className="field-name">Account Type</h3>
        <Select
          inputId="criteria-modal-select-organisation"
          className="CriteriaModal_search-organisation-input"
          style={{ width: "3rem" }}
          classNamePrefix="CriteriaModal_search-organisation-input_"
          placeholder="Type to search"
          isDisabled={false}
          isRtl={false}
          isSearchable
          name="organisation"
          options={[
            {
              label: "Intelligence",
              value: "intelligence",
            },
            {
              label: "Collects",
              value: "collects",
            },
          ]}
          value={accountType}
          onChange={(event) => setAccountType(event)}
        />
      </span>

      <span className="field">
        <h3 className="field-name">Show Home Page</h3>
        <input
          type="checkbox"
          value={showHomePage}
          onChange={() => setShowHomePage(!showHomePage)}
        ></input>
      </span>

      <span className="field">
        <h3 className="field-name">Show Audience Builder Page</h3>
        <input
          type="checkbox"
          value={enableAudienceBuilder}
          onChange={() => setShowAudienceBuilder(!enableAudienceBuilder)}
        ></input>
      </span>

      <div className="SDKItem_modal_buttonArea">
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "1rem", backgroundColor: "#2196F3" }}
          onClick={() =>
            props.callLatestClientList({
              name,
              country: country.value,
              timezone: timezone.value,
              showHomePage,
              enableAudienceBuilder,
              accountType: accountType.value,
            })
          }
          disabled={
            name === "" ||
            country === "" ||
            timezone === null ||
            accountType === null
          }
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CreateClient;
