import {
  FETCH_ACCOUNT_OVERVIEW,
  FETCH_ACCOUNT_OVERVIEW_ERROR,
  SET_ACCOUNT_OVERVIEW,
  FETCH_EXIST_ACCOUNT_LIST,
  FETCH_EXIST_ACCOUNT_LIST_SUCCESS,
  FETCH_EXIST_ACCOUNT_LIST_FAIL
} from "../constants";

const initialState = {
  accountOverviewData: {
    ownerAccount:{
      Id: '',
      user:{
        lastName: '',
        firstName: '',
      },
      username: '',
    }
  },
  accountOverviewLoaded: true,
  existAccount: [],
  existAccountLoaded: true,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_OVERVIEW:
      return {
        ...state,
        accountOverviewLoaded: false
      };
    case FETCH_ACCOUNT_OVERVIEW_ERROR:
      return {
        ...state,
        accountOverviewLoaded: false
      };
    case SET_ACCOUNT_OVERVIEW:
      return {
        ...state,
        accountOverviewData: action.data,
        accountOverviewLoaded: true
    };
    case FETCH_EXIST_ACCOUNT_LIST:
      return {
        ...state,
        existAccountLoaded: false
      };
    case FETCH_EXIST_ACCOUNT_LIST_FAIL:
      return {
        ...state,
        existAccountLoaded: false
      };
    case FETCH_EXIST_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        existAccount: action.data.map((item, idx) => ({
          label: item.username,
          value: item._id
        })),
        existAccountLoaded: true
      };
    default:
      return state;
  }
};

export default account;
