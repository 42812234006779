import React, { useState } from "react";
import Button from "@material-ui/core/Button";

const EditSDKItem = props => {

  const [sdkContent, setSdkContent] = useState(props.data);

  const handleSubmit = () => {
    props.finishEdit();
    props.sendEditContent(sdkContent, props.data._id);
  };

  const handleChange = e => {
    const value = e.target.value;
    setSdkContent({
      ...sdkContent,
      [e.target.name]: value
    });
  };

  const handleCheckBoxChange = e => {
    setSdkContent({
      ...sdkContent,
      [e.target.name]: !sdkContent[e.target.name]
    });
  };

  const allKindOfInput = fieldName => {
    switch (fieldName) {
      case "createdAt":
      case "apiKey":
      case "bluedotId":
      case "appSecret":
      case "_id":
      case "updatedAt":
      case "packageName":
      case "os":
        return (
          <input
            style={{ width: "50%", marginTop: "0.5rem" }}
            name={fieldName}
            value={sdkContent[fieldName]}
            disabled
          ></input>
        );
      case "shouldCollectData":
      case "appUsesBluedot":
      case "enableSentry":
      case "enableMotionActivity":
        return (
          <input
            type="checkbox"
            checked={Boolean(sdkContent[fieldName])}
            name={fieldName}
            onChange={handleCheckBoxChange}
          ></input>
        );
      case "description":
        return (
          <textarea
            style={{ width: "100%", height: "auto" }}
            name={fieldName}
            value={sdkContent[fieldName]}
            onChange={handleChange}
          ></textarea>
        );
      case "relevance":
      case "locationPingIntervalMS":
      case "distanceFilterMeters":
      case "distanceFilter":
      case "discoveryMode":
      case "locationPingInterval":
      case "distanceIntervalMeters":
      case "timeIntervalMins":
      case "batchSize":
      case "maxSpeedKph":
      case "minSpeedKph":
        return (
          <input
            type="number"
            name={fieldName}
            value={sdkContent[fieldName]}
            onChange={handleChange}
          ></input>
        );

      default:
        return (
          <input
            style={{ width: "50%", marginTop: "0.5rem" }}
            name={fieldName}
            value={sdkContent[fieldName]}
            onChange={handleChange}
          ></input>
        );
    }
  };

  return (
    <div className="SDKItem_inner_iOS-content_item">

      {Object.keys(sdkContent).map(function(key, index) {
        return (
          <span className="field" key={key}>
            <h3 className="field-name">{key}</h3>
            {allKindOfInput(key)}
          </span>
        );
      })}

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "1rem", marginRight: "1rem" }}
        onClick={handleSubmit}
      >
        Confirm
      </Button>
      <Button
        variant="contained"
        style={{ marginTop: "1rem" }}
        onClick={() => props.cancelEdit()}
      >
        Cancel
      </Button>
    </div>
  );
};

export default EditSDKItem;
