import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import SVGIcon from './SVGIcon';
import switchImg from '../images/Switch.svg';


const ProfileDropdown = props => {

  const [dropDownOpen, setDropDown] = React.useState(false);

  const toggleDropDown = () => setDropDown(!dropDownOpen);

  const closeDropDown = () => setDropDown(false);

  const handelLogout = () => {
    closeDropDown();
    props.logOut();
  }

  return (
    <OutsideClickHandler onOutsideClick={closeDropDown}>
      <div className="ProfileDropdown">
        <button
          onClick={toggleDropDown}
          className={`ProfileDropdown_profile-button${
              dropDownOpen
              ? ' ProfileDropdown_profile-button_active'
              : ''
            }`}
        >
          <SVGIcon icon="user" />
        </button>
        {dropDownOpen && (
          <ul className="ProfileDropdown_ul">

            <li>
              <button
                onClick={handelLogout}
                className="ProfileDropdown_drop-down-button"
              >
                <span className="ProfileDropdown_drop-down-button_inner">
                  <SVGIcon icon="logout" />
                  <span>Log out</span>
                </span>
              </button>
            </li>
          </ul>
        )}
      </div>
    </OutsideClickHandler>

  );
}


export default ProfileDropdown;
