import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReportItem from "../components/ReportItem";
import { useDispatch } from "react-redux";
import {
  updateReportPermission,
  fetchReportPermission
} from "../store/actions/report-actions";

const ReportContent = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setExpanded([]);
  }, [props.clientNow]);

  const [expanded, setExpanded] = useState([]);

  const expandTreeItem = e => {
    setExpanded([e]);
  };

  const sendReportContent = (state, dateParts, reportIndex) => {
    let dataObject = {};
    let boundariesList = ["region", "district", "city", "locality"];
    let reportName = props.data.reports[reportIndex].name;

    boundariesList = boundariesList.filter(function(item) {
      return state[item] === true;
    });

    dataObject.country = props.country;
    dataObject.tiers = boundariesList;
    if (reportName !== "footfall")
      dataObject.enableGeographyTab = state["enableGeographyTab"];
    if (state.showPOIsDropdown !== undefined)
      dataObject.showPOIsDropdown = state["showPOIsDropdown"];
    if (state.enableReport !== undefined)
      dataObject.enableReport = state["enableReport"];

    switch (reportName) {
      case "footfall":
        dataObject.organizations = state.organizations;
        if (props.accountType === "intelligence") {
          dataObject.datePeriod = [
            {
              label: "Last 12 months",
              enable: state["months12"],
              value: 0
            },
            {
              label: "Last 90 days",
              enable: state["days90"],
              value: 90
            },
            {
              label: "Last 30 days",
              enable: state["days30"],
              value: 30
            }
          ];
        }
        break;

      case "mobileDevices":
        dataObject.organizations = state.organizations;
        break;

      case "brandAffinity":
        dataObject.datePeriod = [
          {
            label: "Last 12 months",
            enable: state["months12"],
            value: 0
          },
          {
            label: "Last 90 days",
            enable: state["days90"],
            value: 90
          },
          {
            label: "Last 30 days",
            enable: state["days30"],
            value: 30
          }
        ];
        dataObject.category = state["category"].map(item => item.value);
        dataObject.brand = state["brand"];
        dataObject.brandAffinityChangeField = state["brandAffinityChangeField"];
        dataObject.categoryAffinityRemovingArray =
          state["categoryAffinityRemovingArray"];
        dataObject.categoryAffinityAddingArray =
          state["categoryAffinityAddingArray"];
        if (state.enableFrequency !== undefined)
          dataObject.enableFrequency = state["enableFrequency"];
        if (state.showAppsDropdown !== undefined)
          dataObject.showAppsDropdown = state["showAppsDropdown"];
        if (state.confidenceInterval !== undefined)
          dataObject.confidenceInterval = Number(state["confidenceInterval"]);
        if (state.customMobileApplicationsName !== undefined)
          dataObject.customMobileApplicationsName =
            state["customMobileApplicationsName"];

        break;

      case "travelPast":
        dataObject.datePeriod = [
          {
            label: "Last 12 months",
            enable: state["months12"],
            value: 0,
            datePart: dateParts.months12DatePart
          },
          {
            label: "Last 90 days",
            enable: state["days90"],
            value: 90,
            datePart: dateParts.days90DatePart
          },
          {
            label: "Last 30 days",
            enable: state["days30"],
            value: 30,
            datePart: dateParts.days30DatePart
          }
        ];

        break;

      default:
        break;
    }
    console.log(dataObject);
    dispatch(updateReportPermission(props.clientId, reportName, dataObject));
    setTimeout(() => {
      dispatch(fetchReportPermission(props.clientId));
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <TreeView
        className={classes.root}
        defaultExpanded={["root"]}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
      >
        {Array.isArray(props.data.reports)
          ? props.data.reports.map((client, idx) => (
              <TreeItem
                key={idx}
                nodeId={idx}
                label={
                  <div className={classes.labelRoot}>
                    <Typography variant="body2" className={classes.labelText}>
                      {client.name}
                    </Typography>
                  </div>
                }
                className={classes.groupTitle}
                onClick={e => expandTreeItem(idx)}
              >
                <ReportItem
                  data={client}
                  sendReportContent={sendReportContent}
                  clientNow={props.clientNow}
                  reportIndex={idx}
                  categoriesData={props.categoriesData}
                  activatingOrgsData={props.activatingOrgsData}
                  country={props.country}
                  reportName={props.data.reports[idx].name}
                  accountType={props.accountType}
                />
              </TreeItem>
            ))
          : null}
      </TreeView>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    flexGrow: 1
  },
  groupTitle: {
    fontWeight: 600,
    padding: "1rem"
  },
  groupItem: {
    fontWeight: 300,
    fontSize: "2px",
    padding: "1rem 0.5rem 0.5rem 0.5rem"
  },
  groupItemSelected: {
    fontWeight: 300,
    fontSize: "15px",
    padding: "1rem 0.5rem 0.5rem 0.5rem",
    color: "#1890ff"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1
  }
}));

export default ReportContent;
