export const SET_SCREEN_PROPERTIES = "SET_SCREEN_PROPERTIES";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE";
export const SIGN_IN_USER = "SIGN_IN_USER";
export const GET_POI_NETWORK_AUTH = "GET_POI_NETWORK_AUTH";
export const GET_POI_NETWORK_AUTH_FAILURE = "GET_POI_NETWORK_AUTH_FAILURE";
export const SET_POI_NETWORK_AUTH = "SET_POI_NETWORK_AUTH";
export const SIGN_IN_SUCCSESS = "SIGN_IN_SUCCSESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const LOG_OUT = "LOG_OUT";

export const DOWNLOAD_AUDIENCE = "DOWNLOAD_AUDIENCE";
export const DOWNLOAD_AUDIENCE_SUCCESS = "DOWNLOAD_AUDIENCE_SUCCESS";
export const DOWNLOAD_AUDIENCE_FAILURE = "DOWNLOAD_AUDIENCE_FAILURE";

export const FETCH_CLIENT = "FETCH_CLIENT";
export const FETCH_CLIENT_ERROR = "FETCH_CLIENT_ERROR";
export const SET_CLIENT = "SET_CLIENT";

// Account Actions
export const FETCH_EXIST_ACCOUNT_LIST = "FETCH_EXIST_ACCOUNT_LIST";
export const FETCH_EXIST_ACCOUNT_LIST_FAIL = "FETCH_EXIST_ACCOUNT_LIST_FAIL";
export const FETCH_EXIST_ACCOUNT_LIST_SUCCESS =
  "FETCH_EXIST_ACCOUNT_LIST_SUCCESS";

export const ADD_EXIST_ACCOUNT = "ADD_EXIST_ACCOUNT";
export const ADD_EXIST_ACCOUNT_FAIL = "ADD_EXIST_ACCOUNT_FAIL";
export const ADD_EXIST_ACCOUNT_SUCCESS = "ADD_EXIST_ACCOUNT_SUCCESS";

export const CREATE_NEW_ACCOUNT = "CREATE_NEW_ACCOUNT";
export const CREATE_NEW_ACCOUNT_SUCCESS = "CREATE_NEW_ACCOUNT_SUCCESS";
export const CREATE_NEW_ACCOUNT_FAILED = "CREATE_NEW_ACCOUNT_FAILED";

export const FETCH_USER_LIST_AFTER_CLIENT_API_CALL =
  "FETCH_USER_LIST_AFTER_CLIENT_API_CALL";
export const RETURN_JUST_CLIENT_LIST = "RETURN_JUST_CLIENT_LIST";

export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";

export const FETCH_ACCOUNT_DETAILS = "FETCH_ACCOUNT_DETAILS";
export const FETCH_ACCOUNT_DETAILS_ERROR = "FETCH_ACCOUNT_DETAILS_ERROR";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";

export const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";
export const REMOVE_ACCOUNT_SUCCESS = "REMOVE_ACCOUNT_SUCCESS";
export const REMOVE_ACCOUNT_FAILED = "REMOVE_ACCOUNT_FAILED";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILED = "UPDATE_ACCOUNT_FAILED";

export const FETCH_ACCOUNT_PERMISSIONS = "FETCH_ACCOUNT_PERMISSIONS";
export const FETCH_ACCOUNT_PERMISSIONS_ERROR =
  "FETCH_ACCOUNT_PERMISSIONS_ERROR";
export const SET_ACCOUNT_PERMISSIONS = "SET_ACCOUNT_PERMISSIONS";

export const ASSOCIATE_ROLES_WITH_ACCOUNT = "ASSOCIATE_ROLES_WITH_ACCOUNT";
export const ASSOCIATE_ROLES_WITH_ACCOUNT_SUCCESS =
  "ASSOCIATE_ROLES_WITH_ACCOUNT_SUCCESS";
export const ASSOCIATE_ROLES_WITH_ACCOUNT_FAILED =
  "ASSOCIATE_ROLES_WITH_ACCOUNT_FAILED";

export const UPDATE_PERSONAL_DETAILS = "UPDATE_PERSONAL_DETAILS";
export const UPDATE_PERSONAL_DETAILS_SUCCESS =
  "UPDATE_PERSONAL_DETAILS_SUCCESS";
export const UPDATE_PERSONAL_DETAILS_FAILED = "UPDATE_PERSONAL_DETAILS_FAILED";

export const REQUEST_PASSWORD_RESET_EMAIL = "REQUEST_PASSWORD_RESET_EMAIL";
export const REQUEST_PASSWORD_RESET_EMAIL_SUCCESS =
  "REQUEST_PASSWORD_RESET_EMAIL_SUCCESS";
export const REQUEST_PASSWORD_RESET_EMAIL_FAILED =
  "REQUEST_PASSWORD_RESET_EMAIL_FAILED";

export const RESET_ACCOUNT_PASSWORD = "RESET_ACCOUNT_PASSWORD";
export const RESET_ACCOUNT_PASSWORD_SUCCESS = "RESET_ACCOUNT_PASSWORD_SUCCESS";
export const SERVER_STATUS_CHECKING = "SERVER_STATUS_CHECKING";

// SDK configuration
export const FETCH_CLIENT_LIST = "FETCH_CLIENT_LIST";
export const FETCH_CLIENT_LIST_ERROR = "FETCH_CLIENT_LIST_ERROR";
export const SET_CLIENT_LIST = "SET_CLIENT_LIST";
export const FETCH_SDK_LIST = "FETCH_SDK_LIST";
export const FETCH_SDK_LIST_ERROR = "FETCH_SDK_LIST_ERROR";
export const SET_SDK_LIST = "SET_SDK_LIST";
export const EDIT_SDK = "EDIT_SDK";
export const EDIT_SDK_SUCCESS = "EDIT_SDK_SUCCESS";
export const EDIT_SDK_FAIL = "EDIT_SDK_FAIL";
export const CREATE_SDK = "CREATE_SDK";
export const CREATE_SDK_SUCCESS = "CREATE_SDK_SUCCESS";
export const CREATE_SDK_FAIL = "CREATE_SDK_FAIL";
export const DELETE_SDK = "DELETE_SDK";
export const DELETE_SDK_SUCCESS = "DELETE_SDK_SUCCESS";
export const DELETE_SDK_FAIL = "DELETE_SDK_FAIL";

// Footfall configuration
export const FETCH_APP_LIST = "FETCH_APP_LIST";
export const FETCH_APP_LIST_ERROR = "FETCH_APP_LIST_ERROR";
export const SET_APP_LIST = "SET_APP_LIST";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const FETCH_SUBCATEGORY_ORGANISATION = "FETCH_SUBCATEGORY_ORGANISATION";
export const FETCH_SUBCATEGORY_ORGANISATION_ERROR =
  "FETCH_SUBCATEGORY_ORGANISATION_ERROR";
export const SET_SUBCATEGORY_ORGANISATION = "SET_SUBCATEGORY_ORGANISATION";
export const FETCH_ORGANISATION_LIST = "FETCH_ORGANISATION_LIST";
export const FETCH_ORGANISATION_LIST_ERROR = "FETCH_ORGANISATION_LIST_ERROR";
export const SET_ORGANISATION_LIST = "SET_ORGANISATION_LIST";
export const ADD_ORGANISATION_IN_APP = "ADD_ORGANISATION_IN_APP";
export const ADD_ORGANISATION_IN_APP_SUCCESS =
  "ADD_ORGANISATION_IN_APP_SUCCESS";
export const ADD_ORGANISATION_IN_APP_FAIL = "ADD_ORGANISATION_IN_APP_FAIL";
export const DELETE_ORGANISATION_FROM_APP = "DELETE_ORGANISATION_FROM_APP";
export const DELETE_ORGANISATION_FROM_APP_SUCCESS =
  "DELETE_ORGANISATION_FROM_APP_SUCCESS";
export const DELETE_ORGANISATION_FROM_APP_FAIL =
  "DELETE_ORGANISATION_FROM_APP_FAIL";
export const DUPLICATE_FROM_SELECTED_APP = "DUPLICATE_FROM_SELECTED_APP";
export const DUPLICATE_FROM_SELECTED_APP_SUCCESS =
  "DUPLICATE_FROM_SELECTED_APP_SUCCESS";
export const DUPLICATE_FROM_SELECTED_APP_FAIL =
  "DUPLICATE_FROM_SELECTED_APP_FAIL";
export const DOWNLOAD_PLIST_FILE = "DOWNLOAD_PLIST_FILE";
export const DOWNLOAD_PLIST_FILE_SUCCESS = "DOWNLOAD_PLIST_FILE_SUCCESS";
export const DOWNLOAD_MANIFEST_FILE = "DOWNLOAD_MANIFEST_FILE";
export const DOWNLOAD_MANIFEST_FILE_SUCCESS = "DOWNLOAD_MANIFEST_FILE_SUCCESS";

// User management

export const FETCH_ACCOUNT_OVERVIEW = "FETCH_ACCOUNT_OVERVIEW";
export const FETCH_ACCOUNT_OVERVIEW_ERROR = "FETCH_ACCOUNT_OVERVIEW_ERROR";
export const SET_ACCOUNT_OVERVIEW = "SET_ACCOUNT_OVERVIEW";

// report permissions
export const FETCH_REPORT_PERMISSIONS = "FETCH_REPORT_PERMISSIONS";
export const FETCH_REPORT_PERMISSIONS_SUCCESS =
  "FETCH_REPORT_PERMISSIONS_SUCCESS";
export const FETCH_REPORT_PERMISSIONS_FAIL = "FETCH_REPORT_PERMISSIONS_FAIL";
export const FETCH_ACTIVATING_ORGS = "FETCH_ACTIVATING_ORGS";
export const FETCH_ACTIVATING_ORGS_SUCCESS = "FETCH_ACTIVATING_ORGS_SUCCESS";
export const UPDATE_REPORT_PERMISSIONS = "UPDATE_REPORT_PERMISSIONS";
export const UPDATE_REPORT_PERMISSIONS_SUCCESS =
  "UPDATE_REPORT_PERMISSIONS_SUCCESS";
export const UPDATE_REPORT_PERMISSIONS_FAIL = "UPDATE_REPORT_PERMISSIONS_FAIL";
export const UPDATE_GENERAL_PROPS = "UPDATE_GENERAL_PROPS";
export const UPDATE_GENERAL_PROPS_SUCCESS = "UPDATE_GENERAL_PROPS_SUCCESS";
export const UPDATE_GENERAL_PROPS_FAIL = "UPDATE_GENERAL_PROPS_FAIL";
export const UPDATE_CATEGORY_LIST = "UPDATE_CATEGORY_LIST";
export const UPDATE_CATEGORY_LIST_SUCCESS = "UPDATE_CATEGORY_LIST_SUCCESS";
export const UPDATE_CATEGORY_LIST_FAIL = "UPDATE_CATEGORY_LIST_FAIL";

// organizations setting
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAIL = "FETCH_ORGANIZATIONS_FAIL";
export const FETCH_TAXONOMY = "FETCH_TAXONOMY";
export const FETCH_TAXONOMY_SUCCESS = "FETCH_TAXONOMY_SUCCESS";
export const FETCH_TAXONOMY_FAIL = "FETCH_TAXONOMY_FAIL";
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS";
export const UPDATE_ORGANISATION_FAIL = "UPDATE_ORGANISATION_FAIL";
export const UPDATE_MOBILE_APP = "UPDATE_MOBILE_APP";
export const UPDATE_MOBILE_APP_SUCCESS = "UPDATE_MOBILE_APP_SUCCESS";
export const UPDATE_MOBILE_APP_FAIL = "UPDATE_MOBILE_APP_FAIL";
