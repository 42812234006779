import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";
import SVGIcon from "../SVGIcon";


import {
  sortLabelAlphabetically,
  getUniqueSubcategoryOptions
} from "../../utilities/helpers";

const SharedTable = props => {
  const classes = useStyles();

  const [currentIndex, setIndex] = useState(0);
  const [comparedData, setComparedData] = useState(props.comparedData);

  const cellWidth = idx => {
    switch (idx) {
      case 0:
        return { width: "5%" };
      default:
        return { width: "20%" };
    }
  };

  const rowsPerPage = 10;

  let rows = sortLabelAlphabetically(props.wholeData.concat(), "name");

  const handleLeftButtonClick = () => {
    setIndex(prevIndex => Math.max(prevIndex - rowsPerPage, 0));
  };

  const filteredRow = rows => {
    // filter the rows by the sbucategory

    if (props.isDisabled) {
      return [];
    }

    if (props.subcategorySelectType === "taxonomy") {
      return rows;
    } else {
      return rows.filter(ele =>
        props.currentSubcategory.label !== "All Subcategory"
          ? ele.subcategory === props.currentSubcategory.label
          : true
      );
    }
  };

  const handleRightButtonClick = () => {
    if (currentIndex + rowsPerPage < filteredRow(rows).length) {
      setIndex(prevIndex =>
        Math.min(
          prevIndex + rowsPerPage,
          Math.floor(filteredRow(rows).length / rowsPerPage) * rowsPerPage
        )
      );
    }
  };

  const statusRender = isShowing => {
    // props

    if (isShowing) {
      return (
        <div style={{ color: "#4caf50" }}>
          {" "}
          <strong>{props.statusString.positive}</strong>
        </div>
      );
    } else {
      return (
        <div style={{ color: "#f44336" }}>
          {" "}
          <strong>{props.statusString.negative}</strong>
        </div>
      );
    }
  };

  const handleSwitchChange = e => {
    // handle select or switch change
    if (e.target.checked) {
      // add in showing array

      const newArray = comparedData;
      newArray.push(e.target.id);
      props.handleDataStateChange(
        newArray,
        props.subcategorySelectType === "taxonomy" ? "brand" : "organizations"
      );
    } else {
      // remove from showing array
      const index = comparedData.indexOf(e.target.id);
      if (index > -1) {
        comparedData.splice(index, 1);
      }
      props.handleDataStateChange(
        comparedData,
        props.subcategorySelectType === "taxonomy" ? "brand" : "organizations"
      );
    }
  };

  const handleSelectChange = event => {
    // local method
    props.setCurrentSubcategory(event);
    setIndex(0);
    if (props.subcategorySelectType === "taxonomy") {
      props.reFetchSubcategoryOrganisation(event);
    }
  };

  return (
    <div className="Organisations" style={{ padding: 0 }}>
      <div style={{ width: "20rem", marginTop: "1rem" }}>
        <Select
          inputId="criteria-modal-select-organisation"
          className="CriteriaModal_search-organisation-input"
          classNamePrefix="CriteriaModal_search-organisation-input_"
          placeholder="Type to search"
          isDisabled={props.isDisabled}
          isRtl={false}
          isSearchable
          name="subcategory"
          options={
            props.subcategorySelectType === "taxonomy"
              ? props.categoriesData
              : getUniqueSubcategoryOptions(rows)
          }
          value={props.currentSubcategory}
          onChange={event => handleSelectChange(event)}
        />
      </div>

      <div
        className="Organisations_tableArea"
        style={{ marginTop: "1rem", fontSize: "12px" }}
      >
        <div>
          {props.LinearProgress ? (
            <table style={{ width: "100%" }}>
              <tr className="Organisations_tableRowHead">
                {props.headCells.map((headCell, idx) => (
                  <td
                    key={headCell.id}
                    className={
                      idx === 0
                        ? "Organisations_tableRowHead_firstCell"
                        : "Organisations_tableRowHead_nthCell"
                    }
                    style={cellWidth(idx)}
                  >
                    {headCell.label}
                  </td>
                ))}
              </tr>
              <tbody>
                {filteredRow(rows)
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .slice(currentIndex, currentIndex + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <tr key={index} className={"Organisations_tableRowBody"}>
                        {props.headCells.map((headCell, idx) => (
                          <td
                            key={headCell.id}
                            className={
                              idx === 0
                                ? "Organisations_tableRowBody_firstCell"
                                : "Organisations_tableRowBody_nthCell"
                            }
                            style={cellWidth(idx)}
                          >
                            {headCell.id === "name" ? (
                              row.name
                            ) : headCell.id === "country" ? (
                              row.country
                            ) : headCell.id === "category" ? (
                              row.category
                            ) : headCell.id === "subcategory" ? (
                              row.subcategory
                            ) : headCell.id === "isActive" ? (
                              statusRender(comparedData.includes(row._id))
                            ) : (
                              <Switch
                                checked={comparedData.includes(row._id)}
                                onChange={handleSwitchChange}
                                id={row._id}
                                color="primary"
                              />
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <div>
              <LinearProgress />
            </div>
          )}
        </div>

        <div className={classes.CountArea}>
          <div className={classes.CountItem}>
            <div className={classes.Active}>
              {" "}
              <strong>{comparedData.length}</strong> Showing{" "}
            </div>
          </div>
          <div className={classes.CountItem}>
            <strong>{rows.length - comparedData.length}</strong> Not showing
          </div>
          <div className={classes.CountItem}>
            <strong>{rows.length}</strong> Total
          </div>

          <div
            style={{
              padding: 10,
              textAlign: "right",
              display: "inline-block",
              float: "right"
            }}
          >
            <span className="WhereYourCustomersHaveBeenGraph_top-row_showing">
              Showing {currentIndex + 1} -{" "}
              {Math.min(currentIndex + rowsPerPage, rows.length)} of{" "}
              {rows.length}
            </span>
            <div className="WhereYourCustomersHaveBeenGraph_top-row_button-group">
              <button type="button" onClick={handleLeftButtonClick}>
                <SVGIcon icon="left-arrow" />
              </button>
              <button type="button" onClick={handleRightButtonClick}>
                <SVGIcon icon="right-arrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  CountArea: {
    padding: "1rem",
    marginBottom: "2rem"
  },
  CountItem: {
    display: "inline-block",
    padding: "1rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  Active: {},
  Inactive: {},
  Total: {}
}));

SharedTable.propTypes = {
  comparedData: PropTypes.arrayOf(PropTypes.object),
  wholeData: PropTypes.arrayOf(PropTypes.object).isRequired,
  subcategoryData: PropTypes.arrayOf(PropTypes.object),
  headCells: PropTypes.arrayOf(PropTypes.object),
  statusString: PropTypes.object, // ex: { positive: "Showing"  , negetive: "Not showing"}
  subcategorySelectType: PropTypes.string
};

export default SharedTable;
