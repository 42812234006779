import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router'
import { history } from './store/store';

import PasswordReset from './containers/PasswordReset';
import ForgottenPassword from './containers/ForgottenPassword';
import Login from './containers/Login';
import SDKConfiguration from './containers/SDKConfiguration';
import FootfallConfiguration from './containers/FootfallConfiguration';
import UserManagement from './containers/UserManagement';
import ReportPermissions from './containers/ReportPermissions';
import DigitalOrganizations from './containers/DigitalOrganizations';


const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const AppRouter = props => {

 return (
  <ConnectedRouter history={history}>
    <Switch>
      <Route
        path="/password-reset/:token"
        render={({ match }) => <PasswordReset token={match.params.token} />}
      />
    
      <Route path="/forgotten-password" render={() => <ForgottenPassword />} />
      {props.isAuthenticated !== true && <Route render={() => <Login />} />}

      <Route exact path="/" render={() =>  <SDKConfiguration />} />
      <Route exact path="/enrichment-setting" render={() =>  <FootfallConfiguration />} />
      <Route exact path="/user-management" render={() =>  <UserManagement />} />
      <Route exact path="/report-permissions" render={() =>  <ReportPermissions />} />
      <Route exact path="/digital-organizations" render={() =>  <DigitalOrganizations />} />

      <Route exact path="/login" render={() => <Login />} />
      <Route
        render={() => (
          <Redirect to="/" />
        )}
      />
    </Switch>
  </ConnectedRouter>
)};

AppRouter.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AppRouter);
