import moment from "moment";
import {
  boundariesSet,
  concatenateBoundaries,
  objectNotEmpty
} from "./helpers";
import { toast } from "react-toastify";

export const dateOptionsCriteria = (state$, reportsType) => {
  const selectedPeriodValue =
    reportsType === "footfall"
      ? state$.value.insights.selectedPeriod.value
      : state$.value.travelPast.selectedPeriod.value;

  let startTime;
  let endTime;

  startTime =
    selectedPeriodValue === 90
      ? encodeURIComponent(
          moment()
            .subtract(91, "days")
            .startOf("day")
            .format()
        )
      : selectedPeriodValue === 30
      ? encodeURIComponent(
          moment()
            .subtract(31, "days")
            .startOf("day")
            .format()
        )
      : encodeURIComponent(
          moment()
            .subtract(12, "months")
            .startOf("month")
            .format()
        );

  endTime =
    selectedPeriodValue === 90
      ? encodeURIComponent(
          moment()
            .subtract(1, "days")
            .endOf("day")
            .format()
        )
      : selectedPeriodValue === 30
      ? encodeURIComponent(
          moment()
            .subtract(1, "days")
            .endOf("day")
            .format()
        )
      : encodeURIComponent(
          moment()
            .subtract(1, "months")
            .endOf("month")
            .format()
        );

  switch (
    state$.value.client.accountType // different type account with different date range picker
  ) {
    case "collects":
      return `&since=${encodeURIComponent(
        state$.value.insightsCriteria.sinceDate
      )}&until=${encodeURIComponent(state$.value.insightsCriteria.untilDate)}`;

    case "master":
    case "intelligence":
      return `&since=${startTime}&until=${endTime}`;

    default:
  }
  return "";
};

export const footfallTabCriteria = state$ => {
  switch (true) {
    case state$.value.insightsCriteria.insightType === "organisation" &&
      state$.value.insightsCriteria.organisation.value !== null:
      return `organisationId=${encodeURIComponent(
        state$.value.insightsCriteria.organisation.value
      )}`;

    case state$.value.insightsCriteria.insightType === "category" &&
      state$.value.insightsCriteria.subcategory.value !== null:
      return `customSubcategory=${encodeURIComponent(
        state$.value.insightsCriteria.subcategory.value
      )}`;

    default:
  }

  return "";
};

export const organisationTabCriteria = state$ => {
  return state$.value.insightsCriteria.insightType === "organisation" &&
    state$.value.insightsCriteria.organisation.value
    ? `&organisationId=${encodeURIComponent(
        state$.value.insightsCriteria.organisation.value
      )}`
    : "";
};

export const boundariesCriteria = state$ => {
  return `${
    boundariesSet(state$.value.insightsCriteria.adm1) === true
      ? `&adm1=${concatenateBoundaries(state$.value.insightsCriteria.adm1)}`
      : ""
  }${
    boundariesSet(state$.value.insightsCriteria.adm2) === true
      ? `&adm2=${concatenateBoundaries(state$.value.insightsCriteria.adm2)}`
      : ""
  }${
    boundariesSet(state$.value.insightsCriteria.adm3) === true
      ? `&adm3=${concatenateBoundaries(state$.value.insightsCriteria.adm3)}`
      : ""
  }${
    boundariesSet(state$.value.insightsCriteria.adm4) === true
      ? `&adm4=${concatenateBoundaries(state$.value.insightsCriteria.adm4)}`
      : ""
  }${
    boundariesSet(state$.value.insightsCriteria.poiId) === true
      ? `&poiId=${concatenateBoundaries(state$.value.insightsCriteria.poiId)}`
      : ""
  }`;
};

export const mobileAppCriteria = state$ => {
  return (state$.value.insightsCriteria.mobileApp !== null &&
    objectNotEmpty(state$.value.insightsCriteria.mobileApp)) === true
    ? `&mobileAppId=${concatenateBoundaries(
        state$.value.insightsCriteria.mobileApp
      )}`
    : "";
};

export const toastpending = (title, toastName) => {
  let toastId = toast(title, {
    id: toastName,
    autoClose: false,
    position: "top-center",
    style: { textAlign: "center" },
    closeButton: false // Remove the closeButton
  });

  return toastId;
};

export const toastSuccess = (title, toastId) => {
  toast.update(toastId, {
    id: toastId,
    type: toast.TYPE.INFO,
    render: title,
    autoClose: 3000,
    hideProgressBar: true,
    progress: undefined,
    style: {
      backgroundColor: "rgb(237, 247, 237)",
      color: "rgb(30, 70, 32)",
      textAlign: "center"
    },
    closeButton: null // The closeButton defined on ToastContainer will be used
  });
};

export const toastFail = (title, toastId) => {
  toast.update(toastId, {
    id: toastId,
    type: toast.TYPE.INFO,
    render: title,
    autoClose: 6000,
    hideProgressBar: true,
    progress: undefined,
    style: {
      backgroundColor: "rgb(253, 236, 234)",
      color: "rgb(97, 26, 21)",
      textAlign: "center"
    },
    closeButton: null // The closeButton defined on ToastContainer will be used
  });
};
