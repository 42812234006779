import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <Provider store={store}>
    <React.Fragment>
      <AppRouter />
      <ToastContainer />
    </React.Fragment>
  </Provider>
);

export default App;
