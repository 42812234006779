import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
UPDATE_REPORT_PERMISSIONS,
UPDATE_REPORT_PERMISSIONS_SUCCESS,
UPDATE_REPORT_PERMISSIONS_FAIL,
} from '../../constants';

import {
  toastSuccess,
  toastpending,
  toastFail
} from "../../../utilities/epic-helpers";
let toastId = "";


const updateReportPermissions = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_REPORT_PERMISSIONS),
    switchMap(action =>{
      toastId = toastpending("Updating Insights Configuration...", "updateInsightsProps");
   
      return ajax({
        url: `${state$.value.auth.poiServer}/report-permissions/update/${action.clientId}/${action.reportName}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          'Content-Type': 'application/json',
        },
        body: action.data,
        responseType: 'json',
        crossDomain: true,
        method: 'POST',
      }).pipe(
        map(response => {
          toastSuccess("Updated Success", toastId);
          return {
            type: UPDATE_REPORT_PERMISSIONS_SUCCESS,
            data: response.response,
          };
        }),
        catchError(error =>{
          console.log(error)
          toastFail("Updated Failed", toastId);
        }
        )
      ) 
    }
    )
  );

export default updateReportPermissions;
