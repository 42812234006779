import {
    FETCH_REPORT_PERMISSIONS,
    FETCH_ACTIVATING_ORGS,
    UPDATE_REPORT_PERMISSIONS,
    UPDATE_GENERAL_PROPS,
    UPDATE_CATEGORY_LIST,
  } from "../constants";
  
  export const fetchReportPermission =(clientId) => ({
    type: FETCH_REPORT_PERMISSIONS,
    clientId
  });

  export const fetchActivatingOrgs =(clientId) => ({
    type: FETCH_ACTIVATING_ORGS,
    clientId
  });
  
  export const updateReportPermission =(clientId, reportName, data) => ({
    type: UPDATE_REPORT_PERMISSIONS,
    clientId,
    reportName,
    data
  });

  export const updateGeneralProps =(data) =>{
    return({
      type: UPDATE_GENERAL_PROPS,
      clientId: data.clientId,
      payload: data.props
    });
  }

  export const updateCategoryList =(data) =>{
    return({
      type: UPDATE_CATEGORY_LIST,
      data
    });
  }

  


  