import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_ERROR,
    SET_CATEGORIES
} from '../../constants';


const fetchCategories = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CATEGORIES),
    switchMap(() =>
      ajax({
        url: `${state$.value.auth.poiServer}/categories`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
        },
        responseType: 'json',
        crossDomain: true,
      }).pipe(
        map(response => {
          return {
            type: SET_CATEGORIES,
            data: response.response,
          };
        }),
        catchError(error =>
          of({
            type: FETCH_CATEGORIES_ERROR,
            error,
          })
        )
      )
    )
  );

export default fetchCategories;
