import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
//import actions
import {
  updateGeneralProps,
  updateCategoryList
} from "../store/actions/report-actions";

const ReportGeneralContent = props => {
  const dispatch = useDispatch();
  const initialGeneralDataObj = {
    enableAudienceBuilder: props.data.props !== undefined && props.data.props.enableAudienceBuilder,
    enableAudienceSummary: props.data.props !== undefined && props.data.props.enableAudienceSummary,
    enableAudienceBuilderDemographic: props.data.props !== undefined && props.data.props.enableAudienceBuilderDemographic,
    homePage: props.data.props !== undefined && props.data.props.homePage,
    enableCreateAudienceSummary: props.data.props != undefined && props.data.props.enableCreateAudienceSummary,
    enableAudiencePerformance: props.data.props != undefined && props.data.props.enableAudiencePerformance,
    enableAudienceBrandAffinity: props.data.props != undefined && props.data.props.enableAudienceBrandAffinity,
    enableAudienceCategoryAffinity: props.data.props != undefined && props.data.props.enableAudienceCategoryAffinity,
    enableAudienceOptimiser: props.data.props != undefined && props.data.props.enableAudienceOptimiser,
  };

  const [states, setStates] = useState(initialGeneralDataObj);

  useEffect(() => {
    // reset tab when choose another client
    setStates(initialGeneralDataObj);
  }, [props.clientNow, props.data]);

  const handleChange = event => {
    setStates({ ...states, [event.target.name]: Boolean(event.target.checked) });
  };

  const handlePropChange = () => {
    dispatch(
      updateGeneralProps({
        clientId: props.clientId,
        props: states
      })
    );
  };

  const handleCategoryUpdate = () => {
    dispatch(
      updateCategoryList({
        clientId: props.clientId,
        country: props.clientListData[props.clientNow].country
      })
    );
  };

  const propsArray = [
    { label:'Enable Home Page',  value: "homePage"},
    { label:'Enable Audience Builder Page',  value: "enableAudienceBuilder"},
    { label:'Enable Audience Builder Type: Demographic', value: "enableAudienceBuilderDemographic"},
    { label:'Enable to use Audience Summary Features',  value: "enableAudienceSummary"},
    { label:'Enable to create Audience Summary Report',  value: "enableCreateAudienceSummary"},
    { label:'Enable to view Audience Performance Report',  value: "enableAudiencePerformance"},
    { label:'Enable to view Audience Summary - Brand Affinity Report',  value: "enableAudienceBrandAffinity"},
    { label:'Enable to view Audience Summary - Categoty Affinity Report',  value: "enableAudienceCategoryAffinity"},
    { label:'Enable to use Audience Optimiser Features',  value: "enableAudienceOptimiser"},
  ]

  return (
    <div style={styleObject.container}>
      <div style={styleObject.box}>
        <div style={styleObject.titleContainer}>
          <h3 style={styleObject.title}>Props Modification</h3>
          <div style={styleObject.description}>
            You can change the basic props for the client in this section.
          </div>
        </div>
        <div style={styleObject.contentContainer}>
          <FormGroup>
            {propsArray.map(item =>{
              return(
                <FormControlLabel
                control={
                  <Checkbox
                    checked={states[item.value]}
                    onChange={handleChange}
                    name={`${item.value}`}
                  />
                }
                label={
                  <Typography style={{ fontSize: 12 }}>
                    {item.label}
                  </Typography>
                }
              />
              )
            })}
          </FormGroup>
        </div>
        <div style={styleObject.buttonArea}>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#2196F3",
              marginTop: "30px",
              width: "100%"
            }}
            onClick={() => handlePropChange()}
            disabled={false}
          >
            Save
          </Button>
        </div>
      </div>
      <div style={styleObject.box}>
        <div style={styleObject.titleContainer}>
          <h3 style={styleObject.title}>Category Update</h3>
          <div style={styleObject.description}>
            Update the whole taxonomy category array and insert into this client
            account.
          </div>
        </div>
        <div style={styleObject.contentContainer}>
          <ul style={styleObject.infoList}>
            <li style={styleObject.infoListLi}>
              <div style={{ paddingRight: "10px" }}>
                <InfoOutlinedIcon fontSize="small" />
              </div>
              <div>
                The content resources are from Mongo database categories and
                organisations tables.
              </div>
            </li>
            <li style={styleObject.infoListLi}>
              <div style={{ paddingRight: "10px" }}>
                <InfoOutlinedIcon fontSize="small" />
              </div>
              <div>
                This is country level updating. If client is from AU, it will
                update whole AU data.
              </div>
            </li>
            <li style={styleObject.infoListLi}>
              <div style={{ paddingRight: "10px" }}>
                <InfoOutlinedIcon fontSize="small" />
              </div>
              <div>
                If client has custom category which duplicate of general one,
                the organisations should{" "}
                <strong>insert into custom category first</strong>.
              </div>
            </li>
          </ul>

          <div style={styleObject.buttonArea}>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#2196F3",
                marginTop: "30px",
                width: "100%"
              }}
              onClick={() => handleCategoryUpdate()}
              disabled={false}
            >
              Confirm Update
            </Button>
          </div>
        </div>
      </div>
      <div style={styleObject.box}>
        <div style={styleObject.titleContainer}>
          <h3 style={styleObject.title}>Comming soon</h3>
          <div style={styleObject.description}>For future usage.</div>
        </div>
        <div style={styleObject.contentContainer}>For future usage.</div>
      </div>
    </div>
  );
};

export default ReportGeneralContent;

const styleObject = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  box: {
    padding: "30px",
    width: "33%",
    display: "flex",
    flexDirection: "column"
  },
  titleContainer: {
    paddingBottom: "30px",
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)"
  },
  contentContainer: {
    paddingTop: "30px",
    flexGrow: 1
  },
  title: {
    fontSize: "20px"
  },
  description: {
    color: "rgb(145, 158, 171)",
    fontSize: "15px"
  },
  buttonArea: {
    marginTop: 30
  },
  infoList: {
    listStyleType: "none",
    paddingLeft: 0
  },
  infoListLi: {
    margin: "10px 0",
    display: "flex",
    fontSize: "12px"
  }
};
