import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'

import { resetAccountPassword } from '../store/actions/account-actions';
import { checkEmailAddress } from '../utilities/helpers';

import logo from '../images/LANDMARKS-ID-Logo.svg';

import Button from '../components/Button';

const PasswordReset = props => {
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const validateInput = () => checkEmailAddress(username) && password.length > 7;

  const handleResetPasswordButton = () => {
    if (validateInput() === true) {
      dispatch(resetAccountPassword(props.token, {
          username: username,
          password: password,
        })
      )
    }
  };

  return(
    <div className="Login">
    <div className="content-card">
      <img className="Login_logo" src={logo} alt="Landmarks ID" />
      {props.token ? (
        <React.Fragment>
          <h1 className="Login_reset-password-title">Reset Password</h1>
          <label htmlFor="username">
            Username:
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <label htmlFor="password">
            Password:
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e)=> setPassword(e.target.value)}
            />
          </label>
          <Button
            label="Reset Password"
            size="md"
            className="Login_button"
            onClick={() => handleResetPasswordButton()}
            disabled={!validateInput()}
          />
        </React.Fragment>
      ) : (
        <p>Sorry, this request could not be processed.</p>
      )}
    </div>
  </div>
  )

}

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
