import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientList } from "../store/actions/sdk-actions";
import { fetchAccountOverview } from '../store/actions/account-actions';
import ListSidebar from "./ListSidebar";
import UserAccounts from './UserAccount';
import PersonalDetails from '../components/PersonalDetails';


const UserManagement = () => {
  const clientListData = useSelector(
    state => state.sdkConfiguration.clientListData
  );

  const accountOverviewLoaded = useSelector(
    state => state.account.accountOverviewLoaded
  );

  const accountOverviewData = useSelector(
    state => state.account.accountOverviewData
  );

  const dispatch = useDispatch();

  let firstHere = true;

  useEffect(() => {
    dispatch(fetchClientList("user"));

  }, []);

  const [clientNow, setClient] = useState(0);

  useEffect(() => {

    if(clientListData.length > 0){
      dispatch(fetchAccountOverview(clientListData[clientNow].clientId))
    }
   
  }, [clientNow, clientListData]);


  const handleClientChange = (e) => { 
    setClient(e)
  }

  console.log(clientListData)
  return (
    <div className="FootfallInsights">
      <Header />
      <ListSidebar 
        title="All Clients" 
        data={clientListData}
        clientNow={clientNow}
        handleClientChange={handleClientChange}
        page="user"
      />
      <main className="FootfallInsights_main">
        <div>
            <div style={{ width: "100%", minHeight: "400px", marginTop: "2rem" }}>
              <PersonalDetails 
              accountOverviewData={accountOverviewData}
              accountOverviewLoaded={accountOverviewLoaded}
              />
              <UserAccounts 
                currentClientId = { clientListData.length > 0 ? clientListData[clientNow].clientId : ''}
              />
            </div>
        </div>
      </main>
    </div>
  );
};
export default UserManagement;
