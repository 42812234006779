import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from '@material-ui/core/Typography';

const ExpandSidebar = props => {
  const classes = useStyles();

  const data = props.data.sort(function(a, b) {
    // list sort by alphabetically
    return a.label.localeCompare(b.label);
  });

  return (
    <div
      className="Sidebar"
      style={{ backgroundColor: "#FBFBFB", overflowY: "scroll" }}
    >
      <h3 className="content-card_title" style={{ padding: "1rem" }}>
        {props.title}
      </h3>

      <TreeView
        className={classes.root}
        defaultExpanded={["root"]}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {Array.isArray(props.data)
          ? data.map(client => (
              <TreeItem
                key={client.clientId}
                nodeId={client.clientId}
                label={
                  <div className={classes.labelRoot}>
                    <Typography variant="body2" className={classes.labelText}>
                      {client.label}
           
                    </Typography>
                    <Typography variant="caption" color="inherit">
                      {client.country}
                    </Typography>
                  </div>
                }
                className={classes.groupTitle}
              >
                {Array.isArray(client.value)
                  ? client.value.map(app => (
                      <TreeItem
                        key={app._id}
                        nodeId={app._id}
                        label={app.name}
                        className={
                          props.appIdNow === app._id
                            ? classes.groupItemSelected
                            : classes.groupItem
                        }
                        onClick={() =>
                          props.handleClientChange(
                            app._id,
                            client.appIds,
                            client.clientId
                          )
                        }
                      ></TreeItem>
                    ))
                  : null}
              </TreeItem>
            ))
          : null}
      </TreeView>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  groupTitle: {
    fontWeight: 600,
    padding: "1rem"
  },
  groupItem: {
    fontWeight: 300,
    fontSize: "2px",
    padding: "1rem 0.5rem 0.5rem 0.5rem"
  },
  groupItemSelected: {
    fontWeight: 300,
    fontSize: "15px",
    padding: "1rem 0.5rem 0.5rem 0.5rem",
    color: "#1890ff"
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

export default ExpandSidebar;
