export const CONFIG = {
  // api
  API_DEV_DOMAIN :  "http://localhost:8080",
  API_STAGE_DOMAIN :  "https://api.intelligence-staging.landmarksid.dev",
  API_PROD_DOMAIM: "https://api.intelligence.landmarksid.com",

  //poi net work api endpint
  POI_API_DOMAIN_LOCAL: 'http://localhost:3001',
  POI_API_DOMAIN_STAGE: 'https://tool-application-api-service-staging-srednu6doa-as.a.run.app',
  POI_API_DOMAIN_PROD: 'https://tool-application-api-srednu6doa-as.a.run.app',

  // GTM
  GTM_ID : 'GTM-KZNTCVJ',

  // Sentry
  SENTRY_DSN : "https://d536895015ea4c7c9d363682099cfa09@sentry.landmarksid.com/15",

  // mapbox
  MAPBOX_TOKEN: 'pk.eyJ1IjoibGFuZG1hcmtzIiwiYSI6ImNqd2xyYzdoZDEzODM0OW4wMWZpbXFhMDUifQ.JertTnfIn9vKhUsoX943ZA',
  MAPBOX_STYLE_URL : 'cjwlmdzov0ao81crzsp3shho0',
};
