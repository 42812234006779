import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const UpdateOrganisations = props => {

  const [name, setName] = useState(
    props.currentOrganization ? props.currentOrganization.name : null
  );
  const [category, setCategory] = useState(
    props.currentOrganization ? props.currentOrganization.category : null
  );
  const [subcategory, setSubcategory] = useState(
    props.currentOrganization ? props.currentOrganization.subcategory : null
  );

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(
    props.currentOrganization
      ? props.taxonomyList
          .map(function(e) {
            return e.category;
          })
          .indexOf(props.currentOrganization.category)
      : 0
  );


  const validateUpdateContent = () =>{

    if(props.currentOrganization){

        if(subcategory === null || subcategory=== props.currentOrganization.subcategory){
           if(name !== props.currentOrganization.name){
               return false
           }else{
               return true
           }
        }
    }
    if(props.isUpdating){
      return true
    }

    return false

  }

  return (
    <div>
      <h4 className="SDKItem_modal_titleArea">Update Organisation</h4>

      <div style={{ marginTop: 30 }}>
        <TextField
          id="standard-basic"
          label="Name"
          error={ name === ''}
          helperText={name === '' ? 'Name should not be empty' : '' }
          variant="standard"
          value={name}
          onChange={e => setName(e.target.value)}
          size="small"
          fullWidth
          disabled={props.isUpdating}
        />
      </div>

      <div style={{ marginTop: 30 }}>
        <TextField
          id="outlined-select-category"
          select
          label="Category"
          value={category}
          onChange={e => {
            setCategory(e.target.value);
            setSubcategory(null);
            setCurrentCategoryIndex(
              props.taxonomyList
                .map(function(e) {
                  return e.category;
                })
                .indexOf(e.target.value)
            );
          }}
          helperText=""
          fullWidth
          disabled={props.isUpdating}
        >
          {props.taxonomyList.map((option, idx) => (
            <MenuItem key={option.category} value={option.category}>
              {option.category}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div style={{ marginTop: 30 }}>
        <TextField
          id="outlined-select-category"
          select
          label="Subcategory"
          value={subcategory}
          onChange={e => setSubcategory(e.target.value)}
          helperText=""
          fullWidth
          disabled={props.isUpdating}
        >
          {
            props.taxonomyList[currentCategoryIndex] && 
            props.taxonomyList[currentCategoryIndex].subcategories.map(
            (option, idx) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )
          )}
        </TextField>
      </div>

      <div className="SDKItem_modal_buttonArea">
        <Button
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={() =>
            props.handleUpdateButton({
                name,
                category,
                subcategory
            })
          }
          disabled={validateUpdateContent()}
        >
          Confirm
        </Button>
        <Button
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={() =>
            props.handleCancelButton()
          }
          disabled={props.isUpdating}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UpdateOrganisations;
