import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    UPDATE_ACCOUNT,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILED
} from '../../constants';


const updateAccount = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ACCOUNT),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.server}/admin/accounts/${action.accountId}/clients/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
        },
        responseType: 'json',
        body: JSON.stringify(action.payload),
        crossDomain: true,
        method: 'PATCH',
      }).pipe(
        map(response => {
          return {
            type: UPDATE_ACCOUNT_SUCCESS,
          };
        }),
        catchError(error =>
          of({
            type:  UPDATE_ACCOUNT_FAILED,
            error,
          })
        )
      )
    )
  );

export default updateAccount;
