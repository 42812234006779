import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  children,
  className,
  disabled,
  label,
  onClick,
  color,
  loading,
  size,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={`Button${color ? ` Button_${color}` : ''}${
      size ? ` Button_${size}` : ''
    }${loading ? ` Button_loading` : ''}${className ? ` ${className}` : ''}`}
    disabled={disabled}
  >
    {label}
    {children}
    {loading && (
      <div className="Button_loading_wrapper">
        <svg className="Button_loading_svg" viewBox="25 25 50 50">
          <circle className="Button_loading_path" cx="50" cy="50" r="20" />
        </svg>
      </div>
    )}
  </button>
);

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: undefined,
  label: undefined,
  loading: false,
  color: undefined,
  size: undefined,
};

export default Button;
