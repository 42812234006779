import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';



const NavDropdown = props => {

  const [dropDownOpen, setDropDown] = React.useState(false);
  const toggleDropDown = () => setDropDown(!dropDownOpen);
  const closeDropDown = () => setDropDown(false);

  return (
      <div >
    <OutsideClickHandler onOutsideClick={closeDropDown}>
      <div className="ProfileDropdown">
        <button
          onClick={toggleDropDown}
          className={`ProfileDropdown_profile-button${
              dropDownOpen
              ? ' ProfileDropdown_profile-button_active'
              : ''
            }`}
        >
          <span className="ProfileDropdown_nav"> {props.navName} </span>
        </button>
        {dropDownOpen && (
          <ul className="ProfileDropdown_nav-ul">

              {props.dropdownData.map((item,idx)=>{
                return(
                  <li style={{float:"left", width: "12rem"}} key={idx}>
                  <button className="ProfileDropdown_drop-down-button" >
                  <span className="ProfileDropdown_drop-down-button_inner" style={{whiteSpace: "nowrap" }}>
                   { item.icon }
                    <Link to={item.link}> <span>{item.label}</span></Link>
                  </span>
                </button>
                </li>
                )
              })}
  
          </ul>
        )}
      </div>
    </OutsideClickHandler>
    </div>
  );
}


export default NavDropdown;
