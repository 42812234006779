import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import CreateClient from "../components/CreateClient";
import { fetchClientList } from "../store/actions/sdk-actions";
import { createClient } from "../store/actions/account-actions";

const ListSidebar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOpen, toggleModal] = useState(false);
  const handleToggleModal = () => toggleModal(!isOpen);

  const callLatestClientList = (payload) => {
    dispatch(createClient(payload));
    toggleModal(false);
    setTimeout(() => {
      dispatch(fetchClientList("user"));
    }, 1000);
  };

  const data = props.data.sort(function (a, b) {
    // list sort by alphabetically
    return a.label.localeCompare(b.label);
  });

  return (
    <div
      className="Sidebar"
      style={{ backgroundColor: "#FBFBFB", padding: "0.5rem" }}
    >
      {props.page === "user" && (
        <Button
          variant="contained"
          color="primary"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            backgroundColor: "#2196F3",
            width: "100%",
          }}
          onClick={() => toggleModal(true)}
        >
          Create Client
        </Button>
      )}

      <h3 className="content-card_title" style={{ padding: "1rem" }}>
        {" "}
        {props.title}{" "}
      </h3>
      <ul>
        {data.map((item, idx) => {
          return (
            <li
              className={classes.li}
              onClick={(e) => props.handleClientChange(idx)}
              key={idx}
            >
              <span
                className={
                  Number(props.clientNow) === idx
                    ? classes.choosingSpan
                    : classes.span
                }
              >
                {item.label}
              </span>
              <span className={classes.countryBadge}>{item.country}</span>
            </li>
          );
        })}
      </ul>

      <Modal
        isOpen={isOpen}
        toggle={handleToggleModal}
        style={{ width: "45rem" }}
        centered
        contentClassName="CriteriaModal_wrapper"
        backdropClassName="CriteriaModal_background"
      >
        <CreateClient callLatestClientList={callLatestClientList} />
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  li: {
    listStyleType: "none",
    margin: "0 0 1rem 0",
  },
  span: {
    cursor: "pointer",
  },
  choosingSpan: {
    color: "#1890ff",
    cursor: "pointer",
  },
  countryBadge: {
    float: "right",
    backgroundColor: "#f3f3f3",
    fontSize: 12,
    paddingLeft: 5,
    paddingRight: 5,
    color: "#697689",
  },
}));

export default ListSidebar;
