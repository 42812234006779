import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from './auth';
import sdkConfiguration from './sdk-configuration';
import footfallConfiguration from './footfall-configuration';
import account from './account';
import reportConfiguration from './report-configuration';
import organizationsConfiguration from './organizations-configuration';


const rootReducer  = (history) =>  combineReducers({
  auth,
  router: connectRouter(history),
  sdkConfiguration,
  footfallConfiguration,
  account,
  reportConfiguration,
  organizationsConfiguration
});

export default rootReducer;
