import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import ProfileDropdown from '../components/ProfileDropdown';
import NavDropdown from '../components/NavDropdown';
import logo from '../images/LANDMARKS-ID-Logo-no-strap.svg';
import { logOut } from '../store/actions/auth-actions';

import AppsIcon from '@material-ui/icons/Apps';
import RoomIcon from '@material-ui/icons/Room';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { getCredentials } from '../utilities/localStorage';

const Header = props => {

  const dispatch = useDispatch();
  const firstName =  useSelector( state =>  state.auth.firstName );

  const checkExpireDate = () =>{
    const storedCredentials = getCredentials();
    const expiresAt = storedCredentials.expiresAt ;

    if (expiresAt < new Date().getTime()/1000) {

      dispatch(logOut())
    }

  }

  useEffect(() => {
    checkExpireDate()
  });


  return(
    <header className="Header">
      <Link to="/" className="Header_logo_link">
        <img src={logo} alt="LANDMARKS ID" className="Header_logo" />
      </Link>
      <div className="Header_rigth">

        <NavDropdown 
          navName="Clients" 
          dropdownData= {[
            {
              label: "User Management",
              link: "/user-management",
              icon: <AccountBoxIcon />,
            },
            {
              label: "SDK Configuration",
              link: "/",
              icon: <AppsIcon />,
            },
            {
              label: "Enrichment Setting",
              link: "/enrichment-setting",
              icon: <RoomIcon />,
            },
            {
              label: "Report Permissions",
              link: "/report-permissions",
              icon: <EventAvailableIcon />,
            }
           ]}
        />

        <NavDropdown 
          navName="Organisations" 
          dropdownData= {[
            {
              label: "Digital Organizations",
              link: "/digital-organizations",
              icon: <LocationCityIcon />,
            }
           ]}
        />

        <span className="Header_welcome-text">Welcome {firstName}</span>
        <ProfileDropdown logOut={()=> dispatch(logOut())} />
      </div>
    </header>
  );
}



Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header ;
