import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { map, switchMap, catchError, } from 'rxjs/operators';

import {
    REMOVE_ACCOUNT,
    REMOVE_ACCOUNT_SUCCESS,
    REMOVE_ACCOUNT_FAILED
} from '../../constants';



const removeAccount = (action$, state$) =>
  action$.pipe(
    ofType(REMOVE_ACCOUNT),
    switchMap(action =>
      ajax({
        url: `${state$.value.auth.server}/admin/accounts/${action.accountId}/clients/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
        },
        responseType: 'json',
        crossDomain: true,
        method: 'DELETE',
      }).pipe(
        map(response => {
          return {
            type: REMOVE_ACCOUNT_SUCCESS,
          };
        }),
        catchError(error =>
          of({
            type: REMOVE_ACCOUNT_FAILED,
            error,
          })
        )
      )
    )
  );

export default removeAccount;
