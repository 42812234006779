import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signInUser, getPoiNetworkAuth } from '../store/actions/auth-actions';
import logo from '../images/LANDMARKS-ID-Logo.svg';
import Button from '../components/Button';

import { pushRoute } from '../utilities/helpers';

const Login = () => {

  const resetPasswordSuccess = useSelector(state => state.auth.resetPasswordSuccess);
  const authenticationFailed = useSelector(state => state.auth.authenticationFailed);
  const authenticationInProgress = useSelector(state => state.auth.authenticationInProgress);
  const dispatch = useDispatch();

  const LIPToken = useSelector(state => state.auth.LIPToken);
  const token = useSelector(state => state.auth.token);

  const [username, setUsername]= useState( '' );
  const [password, setPassword]= useState( '' );
  const [twoTokenPass, setTwoTokenPass] =useState('checking');
  const [twoTokenWarningString, setTwoTokenWarningString] = useState('');

  const handleLogin = () => {
    dispatch(getPoiNetworkAuth(username, password));
    dispatch(signInUser(username, password));
    
  }

  useEffect(() => {
    document.body.addEventListener( 'keyup', function (e) {
      if ( e.keyCode === 13 ) {
        handleLogin()
      }
    })
  }, []);

  useEffect(() => {
    if(token && LIPToken){
      pushRoute("/");
      setTwoTokenPass('passed')
    }else if(token ===null && LIPToken !== null){
      setTwoTokenPass('Tool service');
      setTwoTokenWarningString('Tool service login is failed')
    }else if(token !==null && LIPToken == null){
      setTwoTokenPass('LIP service');
      setTwoTokenWarningString('LIP service login is failed')
    }
  }, [token,LIPToken]);



  return (
    <div className="Login">
    <div className="content-card">
      <img className="Login_logo" src={logo} alt="Landmarks ID" />
      {resetPasswordSuccess && (
        <p className="Login_password-reset-success">
          Your password has successfully be reset. Please log in to
          continue:
        </p>
      )}
      {authenticationFailed && (
        <p className="Login_warning">Username or password incorrect</p>
      )}
  
      { (twoTokenPass === 'LIP service'  || twoTokenPass === 'Tool service')
      && (
        <p className="Login_warning">{ `${twoTokenWarningString}` }</p>
      )}
      <label htmlFor="username">
        Username:
        <input
          type="text"
          id="username"
          placeholder="Enter your username"
          value={username}
          onChange={(e)=> setUsername(e.target.value)}
          disabled={authenticationInProgress}
        />
      </label>
      <label htmlFor="password">
        Password:
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e)=> setPassword(e.target.value)}
          disabled={authenticationInProgress}
        />
      </label>
      <Button
        label="Log in"
        size="md"
        onClick={()=> handleLogin()}
        disabled={authenticationInProgress}
        loading={authenticationInProgress}
      />
      <Link to="/forgotten-password" className="Login_link">
        Forgotten password
      </Link>
    </div>
  </div>
  )
}


export default Login;
