import { combineEpics } from 'redux-observable';
import auth from './auth';
import poiAuth from './poiAuth'
// sdk configuration
import clientList from './sdk-configuration/clientList';
import sdkList from './sdk-configuration/sdkList';
import editSDK from './sdk-configuration/editSDK';
import createSDK from './sdk-configuration/createSDK';
import deleteSDK from './sdk-configuration/deleteSDK';
// footfall configuration
import fetchAppList from './footfall-configuration/fetchAppList';
import fetchOrganisationList from './footfall-configuration/fetchOrganisationList';
import fetchCategories from './footfall-configuration/fetchCategories';
import fetchSubcategoryOrganisation from './footfall-configuration/fetchSubcategoryOrganisation';
import addOrganisation from './footfall-configuration/addOrganisation';
import deleteOrganisation from './footfall-configuration/deleteOrganisation';
import cloneOrganisations from './footfall-configuration/cloneOrganisations';
import downloadPlistFile from './footfall-configuration/downloadPlistFile';
import downloadManifestFile from './footfall-configuration/downloadManifestFile';

// account configuration
import fetchUserAccountList from './account-configuration/fetchUserAccountList';
import createNewAccount from './account-configuration/createNewAccount';
import removeAccount from './account-configuration/removeAccount';
import updateAccount from './account-configuration/updateAccount';
import createClient from './account-configuration/createClient';
import fetchExistAccount from './account-configuration/fetchExistAccount';
import addExistAccount from './account-configuration/addExistAccount'
// report configuration
import fetchReportPermission from './report-configuration/fetchReportPermission';
import updateReportPermissions from './report-configuration/updateReportPermissions';
import updateGeneralProps from './report-configuration/updateGeneralProps';
import updateCategoryList from './report-configuration/updateCategoryList';
import fetchActivatingOrgs from './report-configuration/fetchActivatingOrgs';
// organizations setting
import fetchOrganizations from './organizations-configuration/fetchOrganizations';
import fetchTaxonomy from './organizations-configuration/fetchTaxonomy';
import updateOrganization from './organizations-configuration/updateOrganization';
import updateMobileApp from './organizations-configuration/updateMobileApp';

export default combineEpics(
  auth,
  poiAuth,
  clientList,
  sdkList,
  editSDK,
  createSDK,
  deleteSDK,
  fetchAppList,
  fetchOrganisationList,
  fetchCategories,
  fetchSubcategoryOrganisation,
  addOrganisation,
  deleteOrganisation,
  cloneOrganisations,
  fetchUserAccountList,
  createNewAccount,
  removeAccount,
  updateAccount,
  createClient,
  fetchExistAccount,
  addExistAccount,
  fetchReportPermission,
  updateReportPermissions,
  downloadPlistFile,
  downloadManifestFile,
  updateGeneralProps,
  updateCategoryList,
  fetchActivatingOrgs,
  fetchOrganizations,
  fetchTaxonomy,
  updateOrganization,
  updateMobileApp
);
