import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingContainer from "../components/LoadingContainer";
import SVGIcon from "../components/SVGIcon";
import ActiveOrganisation from "../components/ActiveOrganisation";
import FileDownloadButton from "../components/FileDownloadButton";

import {
  fetchCategories,
  fetchOrganisationList,
  deleteOrganisaitonFromApp,
  downloadPlistFile,
  downloadManifestFile
} from "../store/actions/footfall-actions";

import { searchStringInObjectArray, sortLabelAlphabetically } from "../utilities/helpers";


const OrganisationList = props => {
  const organisationListDataShowing = useSelector(state => { // whole data
    return {
      physicalOrganisationArray:
        state.footfallConfiguration.physicalOrganisationArray,
      digitalOrganisationArray:
        state.footfallConfiguration.digitalOrganisationArray
    };
  });

  const organisationListDataLoaded = useSelector( //prop
    state => state.footfallConfiguration.organisationListDataLoaded
  );

  const categoriesData = useSelector(
    state => state.footfallConfiguration.categoriesData
  );

  const currentAppData = props.clientListData.find(
    element => element.clientId === props.clientId
  );

  const userAccountName = useSelector(state => state.auth.tokenPayload.user);

  let currentAppOsType;
  if (currentAppData !== null && currentAppData !== undefined) {
    currentAppOsType = searchStringInObjectArray(
      props.appIdNow,
      currentAppData.value,
      "_id"
    ).value.os;
  }
  console.log(currentAppOsType)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());

    if (props.appIdNow !== undefined && props.appIdNow !== null) {
      dispatch(fetchOrganisationList(props.appIdNow));
      setIndex(0);
      setCurrentSubcategory({
        label: "All Subcategory",
        name: "All Subcategory"
      });
      setCurrentOrganisationTpye({
        label: "All Organisation Types",
        value: 0
      });
    }
  }, [props.appIdNow]);

  const headCells = [
    { id: "name", label: "Oraganisation Name" },
    { id: "country", label: "Country" },
    { id: "subcategory", label: "Subcategory" },
    { id: "type", label: "Type" },
    { id: "checkbox", label: "" }
  ];
  const cellWidth = idx => {
    switch (idx) {
      case 0:
        return { width: "40%", textAlign: "Left", paddingLeft: "1rem" };
      case 4:
        return { width: "5%" };
      default:
        return { width: "18%" };
    }
  };

  const rowsPerPage = 10;

  const rows = organisationListDataShowing.physicalOrganisationArray.concat(
    organisationListDataShowing.digitalOrganisationArray
  );

  const getUniqueSubcategoryOptions = () => {
    let subcategoryOptions =
      rows !== undefined && rows.length > 0
        ? rows.map(item => {
            return {
              label: `${item.subcategory}`,
              name: item.subcategory
            };
          })
        : [];

    subcategoryOptions = Array.from(
      new Set(subcategoryOptions.map(s => s.label))
    ).map(label => {
      return {
        label: label,
        name: subcategoryOptions.find(s => s.label === label).name
      };
    });

    subcategoryOptions = sortLabelAlphabetically(subcategoryOptions, "label");

    subcategoryOptions.unshift({
      label: "All Subcategory",
      name: "All Subcategory"
    });

    return  subcategoryOptions;
  };

  let uniqueSubcategoryOptions = getUniqueSubcategoryOptions();

  const [currentIndex, setIndex] = useState(0);
  const [currentSubcategory, setCurrentSubcategory] = useState({
    label: "All Subcategory",
    name: "All Subcategory"
  });
  const [checkedItems, setCheckedItems] = useState({});
  const [isOpen, toggleModal] = useState(false);

  const [category, setCategory] = useState({
    label: "All category",
    value: 0
  });

  const [currentOrganisationTpye, setCurrentOrganisationTpye] = useState({
    label: "All Organisation Types",
    value: 0
  });

  const filteredRow = rows => {
    // filter the rows by the sbucategory
    return rows
    .filter(ele =>
      currentSubcategory.label !== "All Subcategory"
        ? ele.subcategory === currentSubcategory.label
        : true
    )
    .filter(ele => {
      switch (currentOrganisationTpye.value) {
        case 1:
         return ele.organisationType === "digital";
        case 2:
         return ele.organisationType !== "digital";
        default:
          return true;
      }
      
    });
  };

  const handleSelectChange = event => {
    setCurrentSubcategory(event);
    setIndex(0);
  };

  const handleToggleModal = () => toggleModal(!isOpen);

  const handleLeftButtonClick = () => {
    setIndex(prevIndex => Math.max(prevIndex - rowsPerPage, 0));
  };

  const handleRightButtonClick = () => {
    console.log(currentIndex)
    console.log(filteredRow(rows).length);
    console.log(Math.floor(filteredRow(rows).length / rowsPerPage) * rowsPerPage)
    if (currentIndex + rowsPerPage < filteredRow(rows).length) {
      setIndex(prevIndex =>
        Math.min(
          prevIndex + rowsPerPage,
          Math.floor(filteredRow(rows).length / rowsPerPage) * rowsPerPage
        )
      );
    }
  };

  const handleCheck = e =>
    setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked });

  const removeOrganisations = async () => {
    let deleteDataArray = [];
    Object.keys(checkedItems).length > 0 &&
      Object.keys(checkedItems).map((item, idx) => {
        checkedItems[item] !== false && deleteDataArray.push(item);
      });

    await dispatch(
      deleteOrganisaitonFromApp({
        appIds: [props.appIdNow],
        organIds: deleteDataArray,
        userName: userAccountName
      })
    );

    reGettingtheData();
    handleClose();
  };

  const handleFileDownload = () => {
    let mobileAppIdArray = [];

    for (let i = 0; i < rows.length; i++) {
      if (
        rows[i].mobileAppIds !== undefined &&
        Array.isArray(rows[i].mobileAppIds) === true
      ) {
        mobileAppIdArray = mobileAppIdArray.concat(rows[i].mobileAppIds);
      }
    }

    if(currentAppOsType ==='iOS'){
      dispatch(
        downloadPlistFile({
          appId: props.appIdNow,
          mobileAppIds: mobileAppIdArray
        })
      );
    }else{
      dispatch(
        downloadManifestFile({
          appId: props.appIdNow,
          mobileAppIds: mobileAppIdArray
        })
      );
    }


  };


  const reGettingtheData = () => {
    setTimeout(() => {
      dispatch(fetchOrganisationList(props.appIdNow));
    }, 1000);
  };

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRemoveButton = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <main className="FootfallInsights_main" style={{ marginTop: "2rem" }}>
      <div className="Organisations container">
        <LoadingContainer loading={false}>
          <div className="Organisations" style={{ padding: 0 }}>
            <div className="Organisations_inner">
              <div className="Organisations_title">
     
                <Select
                  inputId="criteria-modal-select-organisation"
                  className="CriteriaModal_search-organisation-input"
                  style={{ width: "3rem", marginRight: "1rem" }}
                  classNamePrefix="CriteriaModal_search-organisation-input_"
                  placeholder="Type to search"
                  isDisabled={props.appIdNow === null}
                  isRtl={false}
                  isSearchable
                  name="subcategory"
                  options={uniqueSubcategoryOptions}
                  value={currentSubcategory}
                  onChange={event => handleSelectChange(event)}
                />

                <div style={{width: '1rem'}}></div>
                <Select
                  inputId="criteria-modal-select-organisation"
                  className="CriteriaModal_search-organisation-input"
                  style={{ width: "1rem", marginLeft: "0.5rem" }}
                  classNamePrefix="CriteriaModal_search-organisation-input_"
                  placeholder="Type to search"
                  isDisabled={props.appIdNow === null}
                  isRtl={false}
                  isSearchable
                  name="organisationType"
                  options={[
                    {
                      label: "All Organisation Types",
                      value: 0
                    },
                    {
                      label: "Digital organisations",
                      value: 1
                    },
                    {
                      label: "Physical organisations",
                      value: 2
                    }
                  ]}
                  value={currentOrganisationTpye}
                  onChange={event => setCurrentOrganisationTpye(event)}
                />
              </div>

              <div className="Organisations_ChangeButton">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "1rem", display: "inline-block" }}
                  onClick={() => toggleModal(true)}
                  disabled={props.appIdNow === null}
                >
                  Active Organisation
                </Button>

                  <FileDownloadButton
                    handleFileDownload={handleFileDownload}
                    appIdNow={props.appIdNow}
                    currentAppOsType={currentAppOsType}
                  />

              </div>

              <div className="Organisations_tableArea">
                <div>
                  {organisationListDataLoaded ? (
                    <table>
                      <tr className="Organisations_tableRowHead">
                        {headCells.map((headCell, idx) => (
                          <td
                            key={headCell.id}
                            className={
                              idx === 0
                                ? "Organisations_tableRowHead_firstCell"
                                : "Organisations_tableRowHead_nthCell"
                            }
                            style={cellWidth(idx)}
                          >
                            {headCell.label}
                          </td>
                        ))}
                      </tr>
                      <tbody>
                        {console.log(currentOrganisationTpye)}
                        {rows
                          .sort((a, b) =>
                            a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          )
                          .filter(ele => {
                            switch (currentOrganisationTpye.value) {
                              case 1:
                               return ele.organisationType === "digital";
                              case 2:
                               return ele.organisationType !== "digital";
                              default:
                                return true;
                            }
                            
                          })
                          .filter(ele =>
                            currentSubcategory.label !== "All Subcategory"
                              ? ele.subcategory === currentSubcategory.label
                              : true
                          )
                          .slice(currentIndex, currentIndex + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <tr
                                //onClick={event => handleClick(event, row.name)}
                                key={row._id}
                                className={"Organisations_tableRowBody"}
                              >
                                {headCells.map((headCell, idx) => (
                                  <td
                                    key={headCell.id}
                                    className={
                                      idx === 0
                                        ? "Organisations_tableRowBody_firstCell"
                                        : "Organisations_tableRowBody_nthCell"
                                    }
                                    style={cellWidth(idx)}
                                  >
                                    {headCell.id === "name" ? (
                                      row.name
                                    ) : headCell.id === "country" ? (
                                      row.country
                                    ) : headCell.id === "subcategory" ? (
                                      row.subcategory
                                    ) : headCell.id === "admin" ? (
                                      <span
                                        className={`Organisations_badge_${row.admin}`}
                                      >
                                        {row.admin}{" "}
                                      </span>
                                    ) : headCell.id === "type" ? (
                                      row.organisationType === "digital" ? (
                                        "Digital"
                                      ) : (
                                        "Physical"
                                      )
                                    ) : (
                                      <input
                                        checked={checkedItems[row._id]}
                                        name={row._id}
                                        type="checkbox"
                                        onChange={handleCheck}
                                      />
                                    )}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <LinearProgress />
                    </div>
                  )}
                </div>

                <div style={{ padding: 10, textAlign: "right" }}>
                  <div style={{ marginRight: "1rem", display: "inline-block" }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleRemoveButton}
                      disabled={props.appIdNow === null}
                    >
                      Remove Organisation
                    </Button>
                  </div>
                  <span className="WhereYourCustomersHaveBeenGraph_top-row_showing">
                    Showing {currentIndex + 1} -{" "}
                    {Math.min(
                      currentIndex + rowsPerPage,
                      filteredRow(rows).length
                    )}{" "}
                    of {filteredRow(rows).length}
                  </span>
                  <div className="WhereYourCustomersHaveBeenGraph_top-row_button-group">
                    <button type="button" onClick={handleLeftButtonClick}>
                      <SVGIcon icon="left-arrow" />
                    </button>
                    <button type="button" onClick={handleRightButtonClick}>
                      <SVGIcon icon="right-arrow" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingContainer>

        <Modal
          isOpen={isOpen}
          toggle={handleToggleModal}
          style={{ width: "62rem" }}
          centered
          contentClassName="CriteriaModal_wrapper"
          backdropClassName="CriteriaModal_background"
        >
          <ActiveOrganisation
            categoriesData={categoriesData}
            ActiveOrganisationData={organisationListDataShowing.physicalOrganisationArray.concat(
              organisationListDataShowing.digitalOrganisationArray
            )}
            appIdNow={props.appIdNow}
            clientAppsNow={props.clientAppsNow}
            clientId={props.clientId}
            fetchOrganisationList={fetchOrganisationList}
            toggleModal={toggleModal}
            currentAppOsType={currentAppOsType}
            clientListData={props.clientListData}
            clientIndexNow={props.clientListData.map(item => item.clientId).indexOf(props.clientId)}
          />
        </Modal>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div style={{ padding: "1rem" }}>
            <h2 style={{ fontSize: "1.25rem" }}>
              {" "}
              Are you sure you wanna REMOVE these organisations?
            </h2>
            <p style={{ fontSize: "1rem" }}>
              It will only remove from this app
            </p>
            <Button variant="text" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => removeOrganisations()}
            >
              Confirm
            </Button>
          </div>
        </Popover>
      </div>
    </main>
  );
};

export default OrganisationList;
