import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { map, switchMap, catchError } from "rxjs/operators";

import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
} from "../../constants";

const fetchOrganizations = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_ORGANIZATIONS),
    switchMap((action) => {
      return ajax({
        url: `${state$.value.auth.server}/admin/organizations?organizationType=digital`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
        },
        responseType: "json",
        crossDomain: true,
        method: "GET",
      }).pipe(
        map((response) => {
          return {
            type: FETCH_ORGANIZATIONS_SUCCESS,
            data: response.response,
          };
        }),
      );
    }),
  );

export default fetchOrganizations;
