import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import {
  UPDATE_GENERAL_PROPS,
  UPDATE_GENERAL_PROPS_SUCCESS,
  UPDATE_GENERAL_PROPS_FAIL
} from "../../constants";
import {
  toastSuccess,
  toastpending,
  toastFail
} from "../../../utilities/epic-helpers";

let toastId = "";

const updateGeneralProps = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_GENERAL_PROPS),
    switchMap(action => {
      toastId = toastpending("Updating General Props...", "updateGeneralProps");
      return ajax({
        url: `${state$.value.auth.server}/admin/clients/${action.clientId}`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            props:action.payload
          }),
        responseType: "json",
        crossDomain: true,
        method: "PATCH"
      }).pipe(
        map(response => {
          toastSuccess("Updated Success", toastId);
          return {
            type: UPDATE_GENERAL_PROPS_SUCCESS,
            data: response.response
          };
        }),
        catchError(error => {
          toastFail("Updated Failed", toastId);
        })
      );
    })
  );

export default updateGeneralProps;
