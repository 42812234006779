import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";

import {
  DELETE_ORGANISATION_FROM_APP,
  DELETE_ORGANISATION_FROM_APP_SUCCESS,
  DELETE_ORGANISATION_FROM_APP_FAIL,
} from "../../constants";

const deleteOrganisation = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ORGANISATION_FROM_APP),
    switchMap((action) =>
      ajax({
        url: `${state$.value.auth.poiServer}/apps/delete_organisation`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.token}`,
          "Content-Type": "application/json",
        },
        responseType: "json",
        crossDomain: true,
        body: action.data,
        method: "POST",
      }).pipe(
        map((response) => {
          return {
            type: DELETE_ORGANISATION_FROM_APP_SUCCESS,
            data: response.response,
          };
        }),
        catchError((error) =>
          of({
            type: DELETE_ORGANISATION_FROM_APP_FAIL,
            error,
          }),
        ),
      ),
    ),
  );

export default deleteOrganisation;
