import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { arrayEquals,searchStringInObjectArray } from '../../utilities/helpers';

const UpdateMobileApps = props => {

  const [name, setName] = useState(
    props.currentMobileApp ? props.currentMobileApp.name : null
  );
  const [primaryIOSScheme, setPrimaryIOSScheme] = useState(
    props.currentMobileApp ? props.currentMobileApp.primaryIOSScheme : null
  );
  const [androidPackageName, setAndroidPackageName] = useState(
    props.currentMobileApp ? props.currentMobileApp.androidPackageName : null
  );
  const [appSchemes, setAppSchemes] = useState(
    props.currentMobileApp 
    ? props.currentMobileApp.appSchemes.map(item =>{
      return {
        name:item,
        newAdding: false
      }
    })
    : null
  );

  const [newAppSchemeText, setNewAppSchemeText] = useState("");

  const validateUpdateContent = () => {

    if (props.currentMobileApp) {

       const isTwoArrayEqual = arrayEquals(appSchemes.map(item=> item.name), props.currentMobileApp.appSchemes);

      if (
        name === props.currentMobileApp.name &&
        primaryIOSScheme === props.currentMobileApp.primaryIOSScheme &&
        androidPackageName === props.currentMobileApp.androidPackageName &&
        isTwoArrayEqual
      ) {
        return true;
      } else {
        return false;
      }


    }
  

    if (props.isUpdating) {
      return true;
    }

    return false;
  };

  const addnewAppScheme = (e) =>{
    const isDuplicate = searchStringInObjectArray(newAppSchemeText,appSchemes,'name');

    if(newAppSchemeText !== '' && isDuplicate === undefined){
      setAppSchemes(appSchemes.concat({
       name: newAppSchemeText,
       newAdding: true,
      }
        ));
      setNewAppSchemeText("");
    }

  }

  const handleDelete = (idx) =>{
    const newArray = appSchemes.concat();
    newArray.splice(idx,1)
    setAppSchemes(newArray);
  }

  return (
    <div>
      <h4 className="SDKItem_modal_titleArea">Update MobileApp</h4>

      <div>

          <div style={{ marginTop: 30 }}>
            <TextField
              id="standard-basic"
              label="Name"
              error={name === ""}
              helperText={name === "" ? "Name should not be empty" : ""}
              variant="standard"
              value={name}
              onChange={e => setName(e.target.value)}
              size="small"
              fullWidth
              disabled={props.isUpdating}
            />
          </div>

          <div style={{ marginTop: 30 }}>
            <TextField
              id="primaryIOSScheme"
              label="iOS Scheme"
              error={primaryIOSScheme === ""}
              helperText={
                primaryIOSScheme === ""
                  ? "primaryIOSScheme should not be empty"
                  : ""
              }
              variant="standard"
              value={primaryIOSScheme}
              onChange={e => setPrimaryIOSScheme(e.target.value)}
              size="small"
              fullWidth
              disabled={props.isUpdating}
            />
          </div>

          <div style={{ marginTop: 30 }}>
            <TextField
              id="androidPackageName"
              label="Android Scheme"
              error={androidPackageName === ""}
              helperText={
                androidPackageName === ""
                  ? "androidPackageName should not be empty"
                  : ""
              }
              variant="standard"
              value={androidPackageName}
              onChange={e => setAndroidPackageName(e.target.value)}
              size="small"
              fullWidth
              disabled={props.isUpdating}
            />
          </div>


          <div style={{ marginTop: 30 }}>
            <TextField
              id="appScheme"
              label="Add new app scheme"
              variant="standard"
              value={newAppSchemeText}
              onChange={e => setNewAppSchemeText(e.target.value)}
              size="small"
              disabled={props.isUpdating}
              style ={{width: '80%'}}
            />

            <Button
              color="primary"
              variant="contained"
              style={{ display: "inline-block" }}
              onClick={e => addnewAppScheme(e)}
              style={{margin: 10}}
            >
              <strong>Add</strong>
            </Button>

            <div style={{ paddingTop: "1rem", overflow: "scroll", height: 200 }}>
              {appSchemes.map((item, idx) => (
                <div
                  style={{
                    display: "inline-block",
                    backgroundColor: "#E0E0E0",
                    padding: "5px 15px",
                    borderRadius: "30px",
                    marginRight: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <span
                  style={{  display:"inline-block", width:'200px', wordWrap:'break-word'}}
                  >{item.name}</span>
                  { item.newAdding && (
                      <IconButton onClick={() => handleDelete(idx)} size="small">
                      <CancelIcon />
                    </IconButton>
                    )}
                </div>
              ))}

          </div>
        </div>
      </div>

      <div className="SDKItem_modal_buttonArea">
        <Button
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={() =>
            props.handleUpdateButton({
              name,
              primaryIOSScheme,
              androidPackageName,
              appSchemes:  appSchemes.map(item => item.name)
            })
          }
          disabled={validateUpdateContent()}
        >
          Confirm
        </Button>
        <Button
          color="primary"
          style={{ marginTop: "1rem" }}
          onClick={() => props.handleCancelButton()}
          disabled={props.isUpdating}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UpdateMobileApps;
