import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
    FETCH_TAXONOMY,
    FETCH_TAXONOMY_SUCCESS
} from '../../constants';

const fetchTaxonomy = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_TAXONOMY),
    switchMap(action =>{

     return ajax({
        url: `${state$.value.auth.server}/admin/taxonomy`,
        headers: {
          Authorization: `Bearer ${state$.value.auth.LIPToken}`,
        },
        responseType: 'json',
        crossDomain: true,
        method: 'GET',
      }).pipe(
        map(response => {
          return {
            type: FETCH_TAXONOMY_SUCCESS,
            data: response.response,
          };
        })
      )
    }


    )
  );

export default fetchTaxonomy;
