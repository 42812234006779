import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import UpdateMobileApps from './UpdateMobileApps';
import {  useSelector, useDispatch } from "react-redux";
import {
  TableContainer,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Paper
} from "@mui/material";
import { Modal } from "reactstrap";
import { updateMobileApp } from '../../store/actions/organizations-action';


const mobileAppComponent = props => {

  const dispatch = useDispatch();

  const mobileAppList =useSelector(
    state => state.organizationsConfiguration.mobileAppList
  );
  const willUpdateMobileApp = useSelector(
    state => state.organizationsConfiguration.willUpdateMobileApp
  );
  const isUpdating = useSelector(
    state => state.organizationsConfiguration.isUpdating
  );

  // states
  const [mobileAppListState, setMobileAppListState] = useState(
    props.organizationsListState[
      props.rowMeta.dataIndex
    ].mobileApps
  );
  const [currentmobileAppIndex, setCurrentmobileAppIndex] = useState(
    null
  );

  const [isOpen, toggleModal] = useState(false);


  useEffect(() => {


    let willUpdateIndex = mobileAppListState.findIndex(
      p => p.id == willUpdateMobileApp.id
    );

    let newMobileAppList = mobileAppListState.concat();
    newMobileAppList[willUpdateIndex] = willUpdateMobileApp;

    setMobileAppListState(newMobileAppList);

    if (isUpdating === false) {
      toggleModal(false);
    }
  }, [willUpdateMobileApp, isUpdating]);





  // triiger event
  const handleMobileAppToggle = e =>{
    const currentIndex = e.currentTarget.value;
    setCurrentmobileAppIndex(currentIndex);
    toggleModal(!isOpen);
  }

  const handleMobileAppUpdateButton = data =>{
    dispatch(
      updateMobileApp(
        data,
        mobileAppListState[currentmobileAppIndex].id
      )
    );
  }


const styleObject = {
  cellTableTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#68696a",
    paddingLeft: 15
  },
  cellTableHead: {
    color: "rgb(156, 156, 154)",
    fontWeight: "bold"
  },
  tableCotanier: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 25,
    paddingBottom: 25,
    maxHeight: 500,
    maxWidth: 1024
  }
};

  return (
    <tr style={{ backgroundColor: "rgb(234, 244, 249)" }}>
      <td colSpan={7} style={styleObject.tableCotanier}>
        <TableContainer>
          <Table
            style={{ width: "100%", padding: 17 }}
            aria-label="simple table"
          >
            <TableHead>
              <div style={styleObject.cellTableTitle}> Mobile Apps </div>
              <TableRow>
                <TableCell style={styleObject.cellTableHead}>ID</TableCell>
                <TableCell style={styleObject.cellTableHead}>Name</TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  IOS Scheme
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  Android Scheme
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  App Schemes
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  Organization
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  Category
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>
                  Subcategory
                </TableCell>
                <TableCell style={styleObject.cellTableHead}>Country</TableCell>
                <TableCell style={styleObject.cellTableHead}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mobileAppListState.map((row,index)  => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.primaryIOSScheme}</TableCell>
                  <TableCell>{row.androidPackageName}</TableCell>
                  <TableCell>
                    {row.appSchemes.map(item => (
                      <div>{item}</div>
                    ))}
                  </TableCell>
                  <TableCell>{row.organisation}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.subcategory}</TableCell>
                  <TableCell>
                    {row.country.map(item => (
                      <div>{item}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      style={{ display: "inline-block" }}
                      value={index}
                      onClick={e => handleMobileAppToggle(e)}
                    >
                      <strong>Edit</strong>
                    </Button>
                  </TableCell>
                  <Modal
                    isOpen={isOpen}
                    toggle={handleMobileAppToggle}
                    style={{ width: "35rem" }}
                    centered
                    contentClassName="CriteriaModal_wrapper"
                    backdropClassName="CriteriaModal_background"
                  >
                    <UpdateMobileApps
                      currentMobileApp={mobileAppListState[currentmobileAppIndex]}
                      currentMobileAppIndex={currentmobileAppIndex}
                      handleUpdateButton={handleMobileAppUpdateButton}
                      handleCancelButton={() => toggleModal(false)}
                      isUpdating={isUpdating}
                    />
                  </Modal>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </td>
    </tr>
  );
};

export default mobileAppComponent;
