import {
  ofType
} from 'redux-observable';

import {
  ajax
} from "rxjs/ajax";

import { of } from 'rxjs';

import {
  map,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { setCredentials } from '../../utilities/localStorage';

import {
  SIGN_IN_USER,
  SIGN_IN_SUCCSESS,
  SIGN_IN_FAILURE,
  AUTHENTICATE_USER_SUCCESS
} from '../constants';


const auth = (action$, state$) => action$.pipe(
  ofType(SIGN_IN_USER),
  switchMap(action =>
    ajax({
      url: `${state$.value.auth.server}/auth/signin?v=${process.env.REACT_APP_VERSION}`,
      body: {
        username: action.username,
        password: action.password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
      crossDomain: true,
      method: 'POST',
    }).pipe(
      map(response => {
  
        setCredentials({
          firstName: response.response.user.firstName,
          accounts: [],
          currentAccountIndex: 0,
          LIPToken: response.response.accounts[0].token,
          expiresAt: response.response.accounts[0].expiresAt
        });
       
        return ({
          type: AUTHENTICATE_USER_SUCCESS,
          LIPToken: response.response.accounts[0].token,
          selectedIdx: 0,
          firstName: response.response.user.firstName,
        })
      }),
      catchError(error => of({
        type: SIGN_IN_FAILURE,
        error,
      }))
    ),
  )
);

export default auth;
