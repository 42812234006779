import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { requestPasswordResetEmail } from '../store/actions/account-actions';
import { checkEmailAddress } from '../utilities/helpers';

import Button from '../components/Button';

import logo from '../images/LANDMARKS-ID-Logo.svg';

const ForgottenPassword = props => {

    const dispatch = useDispatch();
    const [username, setUsername]= useState( '' );
    const [requestSent, setRequestSent] = useState(false);

    const validateInput = () => checkEmailAddress(username);

    const handleRequestPasswordResetEmailButton = () => {
      if (validateInput() === true) {
        setRequestSent(true);
        dispatch(requestPasswordResetEmail(username));
      }
    };

  return (
    <div className="Login">
        <div className="content-card">
          <img className="Login_logo" src={logo} alt="Landmarks ID" />
          {!requestSent ? (
            <React.Fragment>
              <h1 className="Login_reset-password-title">Forgotten Password</h1>
              <label htmlFor="username">
                Username:
                <input
                  type="text"
                  id="username"
                  placeholder="Enter your username"
                  value={username}
                  onChange={(e)=> setUsername(e.target.value)}
                />
              </label>
              <Button
                label="Request Password Reset"
                size="md"
                onClick={handleRequestPasswordResetEmailButton()}
                disabled={!validateInput()}
              />
              <Link to="/" className="Login_link">
                Cancel
              </Link>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>A password reset email will be sent shortly if account exists.</p>
            </React.Fragment>
          )}
        </div>
      </div>
  );
};
export default ForgottenPassword;
