import React, { useState, useEffect } from "react";
import Header from "./Header";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientList } from "../store/actions/sdk-actions";
import { fetchReportPermission, fetchActivatingOrgs} from "../store/actions/report-actions";
import { fetchCategories } from "../store/actions/footfall-actions";
import ListSidebar from "./ListSidebar";
import ReportContent from "../components/ReportContent";
import ReportGeneralContent from "../components/ReportGeneralContent";

const ReportPermissions = () => {
  const dispatch = useDispatch();

  const clientListData = useSelector(
    state => state.sdkConfiguration.clientListData
  );
  const ReportPermissionsList = useSelector(
    state => state.reportConfiguration.reportPermissionData
  );
  const activatingOrgsData = useSelector(
    state => state.reportConfiguration.activatingOrgsData
  );
  const categoriesData = useSelector(
    state => state.footfallConfiguration.categoriesData
  );

  const [clientNow, setClient] = useState(0);

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    dispatch(fetchClientList("user"));
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (clientListData.length > 0) {
      dispatch(fetchReportPermission(clientListData[clientNow].clientId));
      dispatch(fetchActivatingOrgs(clientListData[clientNow].clientId));
    }

    setTabValue(0);
  }, [clientListData, clientNow]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClientChange = e => {
    setClient(e);
  };

  return (
    <div className="FootfallInsights">
      <Header />
      <ListSidebar
        title="All Clients"
        data={clientListData}
        clientNow={clientNow}
        handleClientChange={handleClientChange}
        page="report"
      />
      <main className="FootfallInsights_main">
        <div>
          <div style={{ width: "100%", minHeight: "700px", padding: "2rem" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab key={0} label={`Insights Configuration`} />;
              <Tab key={1} label={`General Configuration`} />;
            </Tabs>
            <TabPanel key={0} value={tabValue} index={0}>
              <ReportContent
                data={ReportPermissionsList}
                clientNow={clientNow}
                clientId={
                  clientListData.length > 0
                    ? clientListData[clientNow].clientId
                    : null
                }
                country={
                  clientListData.length > 0
                    ? clientListData[clientNow].country
                    : null
                }
                accountType={
                  clientListData.length > 0
                    ? clientListData[clientNow].accountType
                    : null                 
                }
                categoriesData={categoriesData}
                activatingOrgsData={activatingOrgsData}
              />
            </TabPanel>

            <TabPanel key={1} value={tabValue} index={1}>
              <ReportGeneralContent
                data={ReportPermissionsList}
                clientId={
                  clientListData.length > 0
                    ? clientListData[clientNow].clientId
                    : null
                }
                clientListData={clientListData}
                clientNow={clientNow}
              />
            </TabPanel>
          </div>
        </div>
      </main>
    </div>
  );
};
export default ReportPermissions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
