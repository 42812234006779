import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { of } from 'rxjs';
import { map, switchMap, catchError } from "rxjs/operators";

import {
    UPDATE_MOBILE_APP,
    UPDATE_MOBILE_APP_SUCCESS,
    UPDATE_MOBILE_APP_FAIL
} from "../../constants";

import {
  toastSuccess,
  toastpending,
  toastFail
} from "../../../utilities/epic-helpers";
let toastId = "";

const updateMobileApp = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_MOBILE_APP),
    switchMap(action => {
      toastId = toastpending(
        "Updating digital organisation...",
        "updateDigitalOrganisation"
      );

      return (
        ajax({
          url: `${state$.value.auth.server}/admin/mobileapps/${action.mobileAppId}`,
          headers: {
            Authorization: `Bearer ${state$.value.auth.LIPToken}`,
            "Content-Type": "application/json"
          },
          responseType: "json",
          crossDomain: true,
          body: action.data,
          method: "PATCH"
        }).pipe(
          map(response => {
            toastSuccess("Updated Success", toastId);
            return {
              type: UPDATE_MOBILE_APP_SUCCESS,
              data: response.response
            };
          }),
          catchError(error =>{
            toastFail(error.response.message, toastId);
            return of({
              type: UPDATE_MOBILE_APP_FAIL,
              message: error.response.message
            })
          })
        )
      );
    })
  );

export default updateMobileApp;
