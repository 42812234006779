import {
  SIGN_IN_USER,
  GET_POI_NETWORK_AUTH,
  LOG_OUT,
  AUTHENTICATE_USER_SUCCESS,
} from '../constants';
import {deleteCredentials} from '../../utilities/localStorage';
import {pushRoute} from '../../utilities/helpers';

export const signInUser = (username, password) => ({
    type: SIGN_IN_USER,
    username,
    password,
  });

export const getPoiNetworkAuth = (username, password) =>({
  type: GET_POI_NETWORK_AUTH,
  username,
  password,
})

export const authenticateUser = (token,selectedIdx) =>({
  type: AUTHENTICATE_USER_SUCCESS,
  token,
  selectedIdx
})

export const logOut = () => {
  deleteCredentials();
  pushRoute('/login')
  return {type: LOG_OUT}
};
